import React, { useEffect, useState } from "react";
import { Table, Modal, Input, Menu, Button, Dropdown, DatePicker, Popover } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import '../../globalLess/global.css'
import '../../components/Layout/index.less';
import { useNavigate } from "react-router-dom";
import { toastErrorMessage, totalNames } from "../../utils";
import { getCouponList, getCouponDetail } from "../../service";
import moment from "moment";
import './index.less'
const { RangePicker } = DatePicker;
const ExportJsonExcel = require('js-export-excel')

const items = [
    {
        label: '全部',
        key: '-1',
    },
    {
        label: '优惠券码',
        key: '0',
    },
    {
        label: '门票兑换码',
        key: '1',
    }
]
const CodeManager = (): React.ReactElement => {
    const columns: ColumnsType<any> = [
        {
            title: '序号',
            dataIndex: 'key',
            width: 100,
            align: 'center',
            ellipsis: true,
            render: (key: number) => <div>{key + 1}</div>
        },
        {
            title: '兑换码类型',
            dataIndex: 'type',
            width: 100,
            align: 'center',
            ellipsis: true,
            render: (type: number) => <div>{type === 0 ? '优惠券码' : '门票兑换码'}</div>
        },
        {
            title: '适用门店',
            dataIndex: 'shops',
            width: 100,
            align: 'center',
            ellipsis: true,
            render: (shops: any) => <Popover style={{ width: 500 }}
                content={totalNames(shops)}><div>{totalNames(shops).length < 10 ? totalNames(shops) : totalNames(shops).substring(0, 9) + '...'}</div></Popover>
        },
        {
            title: '生成时间',
            dataIndex: 'create_time',
            width: 100,
            align: 'center',
            ellipsis: true,
            render: (time: string) => <div>{time ? moment(time).format('YYYY-MM-DD HH:mm:ss') : ''}</div>
        },
        {
            title: '发放对象姓名',
            dataIndex: 'specify_name',
            width: 100,
            align: 'center',
            ellipsis: true,
        },

        {
            title: '生成数量（个）',
            dataIndex: 'quantity',
            width: 100,
            align: 'center',
            ellipsis: true,
        },
        {
            title: '有效期（天）',
            dataIndex: 'validity_period_days',
            width: 100,
            align: 'center',
            ellipsis: true,
            render: (validity_period_days: number, data: any) => <div>{data.validity_period_type === 0 ? '永久有效' : validity_period_days}</div>
        },
        {
            title: '操作',
            width: 100,
            align: 'center',
            dataIndex: 'coupon_record_id',
            render: (coupon_record_id: string, data: any) =>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <div style={{ flex: 1, display: 'flex' }} />
                    <div
                        style={{
                            color: '#1890FF',
                            cursor: "pointer",
                            marginLeft: 10,
                            marginRight: 10,
                        }}
                        onClick={() => {
                            console.log('data', data);
                            setQueryItem(data)
                            setQueryModel(true)
                        }}
                    >
                        详情
                    </div>
                    <div
                        style={{
                            color: '#1890FF',
                            cursor: "pointer",
                            marginLeft: 10,
                            marginRight: 10,
                        }}
                        onClick={() => {
                            downLoadCoupon(coupon_record_id)
                        }}
                    >
                        下载
                    </div>
                    <div style={{ flex: 1, display: 'flex' }} />
                </div>
        }
    ];
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [total, setTotal] = useState<number>(0);
    const [isLoading, setIsLoading] = useState(false);
    const [dataSource, setDataSource] = useState<any>([]);
    const [keyword, setKeyword] = useState(undefined);
    const navigate: any = useNavigate();
    const [menuData] = useState<any>([{ key: '0', label: '休息中' }, { key: '1', label: '营业中' }])
    const [shopState, setShopState] = useState('全部')
    const [queryModel, setQueryModel] = useState(false);
    const [current, setCurrent] = useState('-1');
    const [RangePickerString, setRangePickerString] = useState<any>([null, null])
    const [start_time, setStart_time] = useState<any>(null);
    const [end_time, setEnd_time] = useState<any>(null)
    const [queryItem, setQueryItem] = useState<any>({
        shops: [{
            name: ''
        },],
        type: 0,
        discount_type: 0,
        discount_ratio: 9,
        validity_period_type: 0,
        quantity: 1,
        specify_name: '',
        specify_phone: 1,
        specify_company: '',
        create_time: '2024-01-01'
    })


    const handleMenuClick = (e: any) => {
        menuData.map((item: any) => {
            if (e.key === item.key) {
                setShopState(item.label)
            }
        })
    }

    const menu = (
        <Menu onClick={handleMenuClick}>
            {menuData.map((item: any) => {
                return <Menu.Item key={`${item.key}`}>
                    {item.label}
                </Menu.Item>
            })}
        </Menu>
    );


    const downLoadCoupon = (id: string) => {
        getCouponDetail({ id }).then(res => {
            if (res.code === 200) {
                if (res.data.coupons.length > 0) {
                    let option: { fileName?: string, datas?: any[] } = {};
                    let dataTable: any = [];
                    let names = ''
                    res.data.shops.map((item: any, index: number) => {
                        if (names === '') {
                            names = item.name
                        } else {
                            names += ',' + item.name
                        }

                    })
                    let type = 0

                    res.data.coupons.forEach((item: any) => {
                        type = res.data.type
                        if (res.data.type === 1) {
                            dataTable.push({
                                shop: names,
                                type: '门票兑换码',
                                time: `${moment(item.validity_period_start_time).format('YYYY/MM/DD')}-${moment(item.validity_period_end_time).format('YYYY/MM/DD')}`,
                                coupon_id: item.coupon_id,
                                code: item.code,
                            })
                        } else {
                            dataTable.push({
                                shop: names,
                                type: '优惠券码',
                                discount_type: item.discount_type === 0 ? `${((1-item.discount_ratio) * 10).toFixed(1)}折` : `减${item.deduction_amount}`,
                                time: `${moment(item.validity_period_start_time).format('YYYY/MM/DD')}-${moment(item.validity_period_end_time).format('YYYY/MM/DD')}`,
                                coupon_id: item.coupon_id,
                                code: item.code,
                            })
                        }
                    })

                    option.fileName = '兑换码' + moment(res.data.create_time).format('YYYYMMDD')
                    if (type === 1) {
                        option.datas = [
                            {
                                sheetData: dataTable,
                                sheetName: 'sheet',
                                sheetFilter: ['shop', 'type', 'time', 'coupon_id', 'code'],
                                sheetHeader: ['适用门店', '兑换码类型', '兑换码有效期', '兑换码Id', '兑换码代码'],
                            }
                        ];
                    } else {
                        option.datas = [
                            {
                                sheetData: dataTable,
                                sheetName: 'sheet',
                                sheetFilter: ['shop', 'type', 'discount_type', 'time', 'coupon_id', 'code'],
                                sheetHeader: ['适用门店', '兑换码类型', '优惠方式', '兑换码有效期', '兑换码Id', '兑换码代码'],
                            }
                        ];
                    }

                    let toExcel = new ExportJsonExcel(option);
                    toExcel.saveExcel();

                }
            }
        }).catch(err => {

        })
    }

    const onFinish = (page_num: number, page_size: number,clear?: boolean, data?: { coupon_type?: number }) => {
        let dict: any = { page_num, page_size }
        if (!clear) {
            if (keyword) {
                dict.name = keyword
            }
            if (data) {
                if(data.coupon_type !==-1){
                    dict.coupon_type = data.coupon_type
                    setCurrent(`${data.coupon_type}`)
                }
            }else{
                if(current !=='-1'){
                    dict.coupon_type = parseInt(current)
                }
            }

            if (start_time) {
                dict.start_date = start_time
                dict.end_date = end_time
            }
            if (shopState !== '全部') {
                if (shopState === '休息中') {
                    dict.is_use = 0
                } else if (shopState === '营业中') {
                    dict.is_use = 1
                }
            }
        }

        setCurrentPage(page_num);
        setPageSize(page_size)
        setIsLoading(true)
        getCouponList(dict).then(response => {
            if (response.code === 200) {
                setTotal(response.data.totalItems)
                let dataCopy = []
                for (let index = 0; index < response.data.items.length; index++) {
                    let item = response.data.items[index]
                    item.key = index + (page_num - 1) * page_size
                    dataCopy.push(item)
                }
                setDataSource(dataCopy)
            } else {
                toastErrorMessage(response.msg)
            }
            if(clear){
                isClear()
            }
            setIsLoading(false)
        }).catch(err => {
            setIsLoading(false)
        })
    }


    const isClear= ()=>{
        setKeyword(undefined)
        setStart_time(null)
        setEnd_time(null)
        setShopState('全部')
        setRangePickerString([null, null])
    }

    useEffect(() => {
        onFinish(1, 10)
    }, [])

    const handleShowTotal = (total: number, range: number[]) => {
        const totalPage = Math.ceil(total / pageSize);
        return (
            <div style={{ flex: 1, display: "flex", color: '#999999', }}>
                {`共${total}条记录，第${currentPage}/${totalPage}页`}
            </div>
        )
    }

    return (
        <div style={{ height: '100%', width: '100%',}}>
            <div className="ProductionTableStyle">
                <div style={{ paddingTop:10,flex: 1, flexDirection: 'row', display: 'flex', backgroundColor: '#FFF', }}>
                    <div style={{ width: 24, height: 1 }} />
                    <div style={{ fontSize: 14, marginTop: 18 }}> 适用门店：</div>
                    <Dropdown overlay={menu} >
                        <Button style={{ width: 197, marginTop: 12, flexDirection: 'row', display: 'flex', }}>
                            {shopState}
                            <div style={{ flex: 1, display: 'flex' }} />
                            <img style={{ marginTop: -3, marginRight: 0, width: 30, height: 30 }}
                                src={require('../../assets/icon_expanded.png')}
                                alt=""
                            />
                        </Button>
                    </Dropdown>
                    <div style={{ width: 20 }}></div>
                    <div style={{ fontSize: 14, marginTop: 18, marginLeft: 0, marginRight: 0 }}>选择日期：</div>
                    <RangePicker style={{ height: 30, marginTop: 13 }} value={RangePickerString} onChange={(e) => {
                        console.log('e', e);
                        if (e) {
                            setStart_time(moment(e[0]).format('YYYY-MM-DD'))
                            setEnd_time(moment(e[1]).format('YYYY-MM-DD'))
                            setRangePickerString([moment(e[0]), moment(e[1])])

                        } else {
                            setRangePickerString([null, null])
                            setStart_time(null)
                            setEnd_time(null)
                        }

                    }} />
                    <div style={{ fontSize: 14, marginTop: 18, marginLeft: 30, marginRight: -20 }}>姓名：</div>
                    <Input style={{ width: 200, height: 32, marginRight: 20, marginLeft: 20, marginTop: 12 }} value={keyword} placeholder='请输入发放对象姓名' onChange={(e: any) => {
                        setKeyword(e.target.value)
                    }}
                    />
                    <div className='button' style={{ backgroundColor: '#FB186A', marginTop: 12 }} onClick={(e) => {
                        onFinish(1, pageSize)

                    }}>
                        <div className='buttonTitle' style={{ color: '#FFF' }}>
                            查询
                        </div>
                    </div>
                    <div className='button' style={{ borderStyle: 'solid', borderWidth: 1, borderColor: '#FB186A', marginLeft: 20, marginTop: 12 }} onClick={() => {
                     
                        onFinish(1, pageSize,true)

                    }}>
                        <div className='buttonTitle'>
                            重置
                        </div>
                    </div>
                    <div style={{ flex: 1 }} />


                    <div className='button' style={{ width: 112, backgroundColor: '#FB186A', marginLeft: 20, marginTop: 12, marginRight: 20 }} onClick={(e) => {
                        navigate('/creatCode')
                    }}>
                        <img
                            alt=''
                            style={{ width: 22, height: 22, marginRight: 5 }}
                            src={require('../../assets/icon_creatCode.png')}
                        />
                        <div className='buttonTitle' style={{ color: '#FFF', fontSize: 14, paddingTop: 2, }}>
                            生成兑换码
                        </div>
                    </div>
                </div>
            </div>

            <div style={{ backgroundColor: '#FFF', display: 'flex', flexDirection: 'row', paddingTop: 20 }}>
                {
                    items.map((item) => {
                        return <div key={item.key} style={{ marginLeft: (item.key === '-1') ? 5 : 20, alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }} onClick={() => {
                          
                            onFinish(currentPage, pageSize, false, {coupon_type:parseInt(item.key)})
                        }}>
                            <div style={{ width: 70, fontSize: 14, color: (current === item.key) ? '#FB186A' : '#000000', textAlign: 'center' }}> {item.label}</div>
                            <div style={{ marginTop: 10, height: 1, marginLeft: (item.key === '1') ? 20 : 10, width: (item.key === '1') ? 30 : 50, backgroundColor: (current === item.key) ? '#FB186A' : '#FFF' }} />
                        </div>

                    })
                }

            </div>

            <div style={{ backgroundColor: '#FFF' }}>
                <div style={{ padding: 20 }}>
                    <Table
                        className={'commonTable'}
                        columns={columns}
                        dataSource={dataSource}
                        loading={isLoading}
                        scroll={{ x: 1000 }}
                        pagination={{
                            position: ['bottomRight'],
                            defaultCurrent: 1,
                            defaultPageSize: 10,
                            current: currentPage,
                            pageSize: pageSize,
                            showSizeChanger: true,
                            total: total,
                            showQuickJumper: true,
                            onChange: onFinish,
                            showTotal: handleShowTotal
                        }}
                    />

                </div>
            </div>
            <Modal
                width={530}
                style={{ marginTop: 50 }}
                title={
                    <div className="alert-title-bkg">
                        <div className="alert-title">
                            <div className="alert-title-icon" />
                            <div className="alert-title-text">兑换码详情</div>
                        </div>
                        <div className="alert-line"></div>
                    </div>
                }
                open={queryModel}
                onOk={() => {
                    setQueryModel(false)
                }}
                onCancel={() => {
                    setQueryModel(false)
                }}
                footer={null}
            >
                <div style={{ height: 420, marginLeft: 8 }}>
                    <div className="cell"
                    >
                        <div className="titleCell">适用门店</div>
                        <div className="ContentCell"> {totalNames(queryItem.shops).length < 20 ? totalNames(queryItem.shops) : totalNames(queryItem.shops).substring(0, 19) + '...'}   </div>
                    </div>
                    <div className="cell"
                    >
                        <div className="titleCell">兑换码类型</div>
                        <div className="ContentCell">{queryItem.type === 0 ? '优惠券码' : '门票兑换码'}</div>
                    </div>
                    {
                        (queryItem.type === 0) && <div className="cell"
                        >
                            <div className="titleCell">优惠方式</div>
                            <div className="ContentCell">{(queryItem.discount_type === 0) ? `${((1-queryItem.discount_ratio) * 10).toFixed(1)}折` : `减${queryItem.deduction_amount
                                }元`}</div>
                        </div>
                    }
                    <div className="cell"
                    >
                        <div className="titleCell">兑换码有效期</div>
                        <div className="ContentCell">{queryItem.validity_period_type === 0 ? '永久有效' : queryItem.validity_period_days+'天'}</div>
                    </div>
                    <div className="cell"
                    >
                        <div className="titleCell">请输入生成数量</div>
                        <div className="ContentCell">{queryItem.quantity+'个'}</div>
                    </div>
                    <div className="cell"
                    >
                        <div className="titleCell">发放对象姓名</div>
                        <div className="ContentCell">{queryItem.specify_name}</div>
                    </div>
                    <div className="cell"
                    >
                        <div className="titleCell">手机号</div>
                        <div className="ContentCell">{queryItem.specify_phone}</div>
                    </div>
                    <div className="cell"
                    >
                        <div className="titleCell">所属公司</div>
                        <div className="ContentCell">{queryItem.specify_company}</div>
                    </div>

                    <div className="cell"
                    >
                        <div className="titleCell bottom_border" >生成时间</div>
                        <div className="ContentCell bottom_border">{moment(queryItem.create_time).format('YYYY-MM-DD HH:mm:ss')}</div>
                    </div>



                    <div style={{ flexDirection: 'row', display: 'flex', marginTop: 25 }}>
                        <div className='btn' style={{ marginLeft: 150, width: 163, backgroundColor: '#FFF', borderColor: '#D9D9D9', borderWidth: 0.5, borderStyle: 'solid' }} onClick={(e) => {
                            setQueryModel(false)
                        }}>
                            <div className='buttonTitle' style={{
                                color: '#4E5969',
                            }}>
                                确定
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    )
}
export default CodeManager
