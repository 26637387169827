import { message } from "antd";
import { uploadVideo } from "../service";

export const tabMenu = [
  {
    label: "基本信息",
    key: "1",
  },
  {
    label: "增值服务",
    key: "2",
  },
  {
    label: "分账规则",
    key: "3",
  },
];

export const filterData = (data: object) => {
  const params: any = data;
  for (let [key, value] of Object.entries(data)) {
    if (value === null || value === undefined || value === "") {
      delete params[key];
    }
    if (typeof value === "object" && key !== "date") {
      params[key] = value ? value.toString() : "";
    }
  }
  return params;
};

export const upload_file = (url: string, data: any, file: any) => {
  const formData: any = new FormData();
  Object.keys(data).forEach((key) => {
    formData.append(key, data[key]);
  });

  formData.append("file", file);
  uploadVideo(url, formData)
    .then((res) => {
      console.log("resresresres", res);
    })
    .catch((err) => {
      console.log("errerrerrerrerr", err);
    });
};

export const toastMessage = (msg: string) => {
  message.config({ top: window.innerHeight / 2 });
  message.warn(msg);
};

export const toastErrorMessage = (msg: string) => {
  message.config({ top: window.innerHeight / 2 });
  message.error(msg);
};

export const toastSuccessMessage = (msg: string) => {
  message.config({ top: window.innerHeight / 2 });
  message.success(msg);
};

export const getFormData = (values: object) => {
  const formData = new FormData();
  Object.entries(values).map((e) => {
    formData.append(e[0], e[1]);
  });
  return formData;
};

export const getStatus = (type: string) => {
  if (type === "query") {
    return [
      {
        name: "未派工",
        value: "20",
      },
      {
        name: "已派工",
        value: "30",
      },
      {
        name: "处理中",
        value: "40",
      },
      {
        name: "已完成",
        value: "50",
      },
    ];
  }
  if (type === "report") {
    return [
      {
        name: "已派工",
        value: "30",
      },
      {
        name: "处理中",
        value: "40",
      },
      {
        name: "已完成",
        value: "50",
      },
    ];
  }
  return [];
};

//封装当前用户所拥有的菜单
export const getMenus = (values: any) => {
  const menus: any[] = [];
  values.map((e: any) => {
    if (!e.parent_id) {
      e.children = [];
    }
  });
  values.map((e: any) => {
    if (e.is_have) {
      if (e.parent_id) {
        const parent = values.find(
          (item: any) => item.object_id === e.parent_id
        );
        parent.children = parent.children || [];
        parent.children.push({
          ...e,
          is_valid: e.is_valid.toString(),
        });
      } else {
        menus.push({
          ...e,
          is_valid: e.is_valid.toString(),
        });
      }
    }
  });
  const getSort = (array: any) => {
    array.sort((a: any, b: any) => {
      if (a.object_id < b.object_id) {
        return -1;
      } else {
        return 1;
      }
    });
  };
  getSort(menus);
  menus.map((e) => {
    if (e.children) {
      getSort(e.children);
    }
  });
  return menus;
};

//封装所有的菜单
export const getAllMenus = (values: any) => {
  const menus: any[] = [];
  values.map((e: any) => {
    if (!e.parent_id) {
      e.children = [];
    }
  });
  values.map((e: any) => {
    if (e.parent_id) {
      const parent = values.find((item: any) => item.object_id === e.parent_id);
      parent.children = parent.children || [];
      parent.children.push({
        ...e,
        key: e.object_id.toString(),
        is_valid: e.is_valid.toString(),
      });
    } else {
      menus.push({
        ...e,
        key: e.object_id.toString(),
        is_valid: e.is_valid.toString(),
      });
    }
  });
  const getSort = (array: any) => {
    array.sort((a: any, b: any) => {
      if (a.object_id < b.object_id) {
        return -1;
      } else {
        return 1;
      }
    });
  };
  getSort(menus);
  menus.map((e) => {
    if (e.children) {
      getSort(e.children);
    }
  });
  return menus;
};

export const totalNames = (values: any[]) => {
  let name = "";
  values.map((e) => {
    if (name === "") {
      name = e.name;
    } else {
      name += "," + e.name;
    }
  });
  return name;
};
//封装组织机构树结构
export const getTreeData = (values: any[]) => {
  const getArray = (array: any[]) => {
    let temp: any[] = [];
    array.map((e) => {
      if (e.children) {
        temp.push({
          title: e.organization_name,
          value: e.object_id,
          key: e.object_id,
          children: getArray(e.children),
        });
      } else {
        temp.push({
          title: e.organization_name,
          value: e.object_id,
          key: e.object_id,
        });
      }
    });
    return temp;
  };
  return getArray(values);
};

export interface indexType {
  [key: number]: string;
}

export const OrderStatus: indexType = {
  0: "待支付",
  1: "待核销",
  2: "待选片",
  3: "剪辑中",
  4: "已完成",
  5: "已退款",
  6: "已取消",
};

export const OrderManagerStatus: indexType = {
  0: "待支付",
  1: "待核销",
  2: "待选片",
  3: "剪辑中",
  4: "已完成",
  5: "已退款",
  6: "已取消",
  7: "全部",
};

export const OrderStatusColor: indexType = {
  0: "#F8261A",
  1: "#2190FF",
  2: "#FB9D25",
  3: "#E4CE1D",
  4: "#52C41A",
  5: "#D9D9D9",
  6: "#D9D9D9",
};

export const showError = (text: string) => {
  message.error(text);
};
