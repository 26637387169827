import React, { useContext, useState,useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from "../../contexts/userContext";
import './index.less';
import { Dropdown, Button, Menu, Form, Input, message, Modal } from 'antd';
import { toastErrorMessage, toastMessage, toastSuccessMessage } from '../../utils';
import {editName,editPassword} from'../../service'

const HeaderBar = () => {
    const { useInfo, setUserInfo } = useContext(UserContext);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [isSettingOpen, setIsSettingOpen] = useState<boolean>(false);
    const [newName, setNewName] = useState('');
    const [old_password, setOld_password] = useState('');
    const [new_password, setNew_password] = useState('');
    const [new_password2, setNew_password2] = useState('');
    const navigate = useNavigate();
    const overlay = [
        {
            key: '/settings',
            label: '账号信息',
        },
        {
            key: '/changePassword',
            label: '密码修改',
        },
        {
            key: '/logout',
            label: '退出登录',
        },
    ]

    const clickOverlayItem = (e: any) => {
        if (e.key === '/settings') {
            setIsSettingOpen(true)
        } else if (e.key === '/changePassword') {
            setIsModalOpen(true);
        } else if (e.key === '/logout') {
            Modal.confirm({
                width: 320,
                style: { height: 300, marginTop: 200 },
                title: '提示',
                content: [<div style={{ marginLeft: 50, marginBottom: 20 }}> 确认退出登录?</div>],
                onOk: () => {
                    navigate('/')
                    window.sessionStorage.setItem('isLogin', 'null')
                    window.sessionStorage.setItem('token', 'null')
                    setUserInfo({})
                    message.success('您已安全退出！')
                    Modal.destroyAll()
                },
                onCancel: () => { },
                okButtonProps: { style: { backgroundColor: '#FB186A', marginLeft: 20, marginRight: 50 ,borderColor:'#FB186A',borderWidth:1,borderStyle:'solid'} },
              });
        }
    }
    

    const EditPassword = ()=>{
        if(!old_password){
            toastMessage('请输入原密码')
            return
        }
        if(!new_password){
            toastMessage('请输入新的登录密码')
            return
        }
        if(!new_password2){
            toastMessage('请输入新的登录密码')
            return
        }
        let reg =/^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])[a-zA-Z0-9]{6,14}$/
        let reg2 =/^(?=.*[a-z])(?=.*[0-9])[a-z0-9]{6,14}$/
        let reg3 =/^(?=.*[A-Z])(?=.*[0-9])[A-Z0-9]{6,14}$/

   
        let isOK = false
        if(reg.test(new_password)){
            isOK=true
        }else if(reg2.test(new_password)){
            isOK=true
        }else if(reg3.test(new_password)){
            isOK=true
        }
 
        if(!isOK){
            toastMessage('请确保新密码是由6-14位大写字母、小写字母和数组组合')
            return
        }
        
        if(new_password!==new_password2){
            toastMessage('请确保新密码是否一致')
            return
        }
        let dict={
            id:useInfo.id,
            old_password: old_password,
            new_password
        }
        editPassword(dict).then((res:any)=>{
            if(res.code===200){
                setIsModalOpen(false);
                toastSuccessMessage('修改成功，请重新登录')
                setTimeout(()=>{
                    navigate('/')
                    window.sessionStorage.setItem('isLogin', 'null')
                    window.sessionStorage.setItem('token', 'null')
                    setUserInfo({})
                },1000)
            }else{
                toastMessage(res.msg)
            }
        }).catch((err:any)=>{
            toastErrorMessage(err.message)

        })
    }

    const editUserAccount = ()=>{
        let dict={
            id:useInfo.id,
            name: newName
        }
        if(!newName){
            toastMessage('请输入用户名称')
            return
        }
        editName(dict).then((res:any)=>{
            if(res.code===200){
                setIsSettingOpen(false);
                toastSuccessMessage('修改成功')
                setUserInfo({
                    id: useInfo.id,
                    account: useInfo.account,
                   name: newName,
                   phone:useInfo.phone,
                  duty: useInfo.duty,
                  password:useInfo.password,
                  permission: useInfo.permission,
                  is_delete:useInfo.is_delete,
                  shop_preference:useInfo.shop_preference,
                })
            }else{
                toastMessage(res.msg)
            }
        }).catch((err:any)=>{
            toastErrorMessage(err.message)

        })
    }


    useEffect(() => {
        if(useInfo.name){
            setNewName(useInfo.name)
        }
    }, [useInfo])

    return (
        <div className={'mes-header'} style={{height:50}}>
            <div style={{ flex: 1 }}></div>

            {/* <div className='button' style={{ borderStyle:'solid'}} >
            <div className='buttonTitle'>
           {useInfo.permission === 1? '管理员' : '普通用户'}
           </div>
            </div>
            <div className='button' style={{backgroundColor: '#FB186A', marginLeft:20}} onClick={(e) => {
                    navigate('/model')
                    window.sessionStorage.setItem('isLogin', 'null')
                    window.sessionStorage.setItem('token', 'null')
                    setUserInfo({})
                    message.success('您已安全退出！')
            }}>
            <div className='buttonTitle' style={{color:'#FFF'}}>
           退出登录
           </div>
            </div> */}

            <div>
                <Dropdown
                    overlay={
                        <Menu items={overlay} onClick={(e) => clickOverlayItem(e)}
                            style={{ textAlign: 'center' }} />}
                    placement="bottom"
                    arrow={{ pointAtCenter: true }}>
                    <Button type={'text'} style={{ height: '100%', paddingRight: 6 }}>
                        <>
                            <span className={'mes-avatar'} style={{ marginRight: 10 }}>
                                <img
                                    src={require('../../assets/avatar.png')}
                                    alt=""
                                    style={{
                                        width: 25,
                                        height: 25,
                                        borderRadius: '50%'
                                    }} />
                            </span>
                            <span>{useInfo.name}</span>
                            <span style={{ marginRight: 0, marginLeft: 5 }}>
                                <img
                                    src={require('../../assets/icon_select.png')}
                                    alt=""
                                    style={{
                                        width: 16,
                                        height: 16
                                    }} />
                            </span>
                        </>
                    </Button>
                </Dropdown>
            </div>
            <Modal
                width={415}
                style={{ marginTop: 200, }}
                title={
                    <div className="alert-title-bkg">
                        <div className="alert-title">
                            <div className="alert-title-icon" />
                            <div className="alert-title-text">密码修改</div>
                        </div>
                        <div className="alert-line"></div>
                    </div>
                }
                open={isModalOpen}
                onOk={() => {
                    setIsModalOpen(false);
                }}
                onCancel={() => {
                    setIsModalOpen(false);
                }}
                footer={null}
            >
                <div style={{ height: 190 }}>
                    <div className='inputStyle' style={{ marginTop: 0 }}
                    >
                        <div
                            className='inputTitle' style={{ fontSize: 14, marginRight: 10, marginLeft: 0 }}
                        >原密码:</div>
                        <Input
                            className='textInputStr'
                            maxLength={14}
                            style={{ width: 260, marginRight: 0, }}
                            placeholder='请输入原密码'
                            value={old_password}
                            type='password'
                            onChange={(e) => {
                                setOld_password(e.target.value)
                            }}
                        />
                    </div>
                    <div className='inputStyle' style={{ marginTop: 20 }}
                    >
                        <div
                            className='inputTitle' style={{ fontSize: 14, marginRight: 10, marginLeft: 0 }}
                        >新密码:</div>
                        <Input
                            className='textInputStr'
                            maxLength={14}
                            value={new_password}
                            type='password'
                            style={{ width: 260, marginRight: 0, }}
                            placeholder='请输入新的登录密码'
                            onChange={(e) => {
                                setNew_password(e.target.value)
                            }}
                        />
                    </div>

                    <div className='inputStyle' style={{ marginTop: 20 }}
                    >
                        <div
                            className='inputTitle' style={{ fontSize: 14, marginRight: 15, marginLeft: -5 }}
                        >确认密码:</div>
                        <Input
                            className='textInputStr'
                            maxLength={14}
                            type='password'
                            style={{ width: 260, marginRight: 0, }}
                            placeholder='再次输入登录密码'
                            value={new_password2}
                            onChange={(e) => {
                                setNew_password2(e.target.value)
                            }}
                        />
                    </div>
                    <div style={{ flexDirection: 'row', display: 'flex',marginTop:25 }}>
                        <div className='btn' style={{ marginLeft: 100, backgroundColor: '#FFF', borderColor: '#D9D9D9', borderWidth: 0.5, borderStyle: 'solid' }} onClick={(e) => {
                            setIsModalOpen(false);
                        }}>
                            <div className='buttonTitle' style={{
                                color: '#4E5969',
                            }}>
                                取消
                            </div>
                        </div>
                        <div className='btn' style={{ marginLeft: 10, }} onClick={(e) => {
                     
                            EditPassword()
                        }}>
                            <div className='buttonTitle' style={{
                                color: '#FFF',
                            }}>
                                保存
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>

            <Modal
                width={492}
                style={{ marginTop: 200, }}
                title={
                    <div className="alert-title-bkg">
                        <div className="alert-title">
                            <div className="alert-title-icon" />
                            <div className="alert-title-text">用户信息</div>
                        </div>
                        <div className="alert-line"></div>
                    </div>
                }
                open={isSettingOpen}
                onOk={() => {
                    setIsSettingOpen(false);
                }}
                onCancel={() => {
                    setIsSettingOpen(false);
                }}
                footer={null}
            >
                <div style={{ height: 130 }}>
                    
                    
                    <div className='inputStyle' style={{ marginTop: 0 }}
                    >
                        <div
                            className='inputTitle' style={{ fontSize: 14, marginRight: 0, marginLeft: 15 }}
                        >账号:</div>
                        <div
                            className='textInputStr'
                            style={{ width: 260, marginRight: 0, }}
                        > {useInfo.account}</div>
                    </div>

                    <div className='inputStyle' style={{ marginTop: 20 }}
                    >
                        <div
                            className='inputTitle' style={{ fontSize: 14, marginRight: 10, marginLeft: 3 }}
                        >用户名称:</div>
                        <Input
                            className='textInputStr'
                            maxLength={10}
                            style={{ width: 350, marginRight: 0, }}
                            placeholder='请输入'
                            value={newName}
                            onChange={(e) => {
                                setNewName(e.target.value)
                            }}
                        />
                    </div>
                    <div style={{ flexDirection: 'row', display: 'flex',marginTop:25 }}>
                        <div className='btn' style={{ marginLeft: 140, backgroundColor: '#FFF', borderColor: '#D9D9D9', borderWidth: 0.5, borderStyle: 'solid' }} onClick={(e) => {
                            setIsSettingOpen(false);
                        }}>
                            <div className='buttonTitle' style={{
                                color: '#4E5969',
                            }}>
                                取消
                            </div>
                        </div>
                        <div className='btn' style={{ marginLeft: 10, }} onClick={(e) => {
                            editUserAccount()
                        }}>
                            <div className='buttonTitle' style={{
                                color: '#FFF',
                            }}>
                                保存
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </div >
    )
}

export default React.memo(HeaderBar);
