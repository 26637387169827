import React from "react";

// 用户信息的存储与修改
export interface UserInfo {
    id?: string,
    //账号
    account?: string,
     //姓名
   name?: string,
   //手机号
   phone?: string,
  //岗位
  duty?: string,
  //密码
  password?: string
  //权限
  permission?: number,
  is_delete? : string,
  shop_preference?: string,
}

interface UserContextType {
    useInfo: UserInfo,
    setUserInfo: (useInfo: any) => void
}

export const UserContext = React.createContext<UserContextType>({
    useInfo: {},
    setUserInfo: () => {
    }
});
UserContext.displayName = 'UserContext'
export const Provider = UserContext.Provider
export const Consumer = UserContext.Consumer
