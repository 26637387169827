import React, { useContext, useEffect, useState } from "react";
import { Table,  Switch, Modal,Input,Menu,Button, Dropdown,  } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import '../../globalLess/global.css'
import '../../components/Layout/index.less';
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../contexts/userContext";
import { toastErrorMessage,OrderManagerStatus } from "../../utils";
import { getModelList, editModelEnable, removeModel,getShopList } from "../../service";
const { confirm } = Modal;

type ModelListType = {
    //模版id
    id?: string,
    //模版标题
    title?: string,
    //模版描述
    desc?: string,
    //模版价格
    price?: string,
    //
    is_use?: number,
    //
    key_point?: string,
    //
    cover_id?: string,
    video_id?: string,
    cover?: {
        id?: string,
        filename?: string,
        mimetype?: string,
        path?: string,
        url?: string
    }
}

const ModelManager = (): React.ReactElement => {
    const columns: ColumnsType<ModelListType> = [
        {
            title: '序号',
            dataIndex: 'key',
            width: 100,
            align:'center',
            ellipsis: true,
            render: (key: number) => <div>{key + 1}</div>
        },
        {
            title: '模版标题',
            dataIndex: 'title',
            width: 100,
            align:'center',
            ellipsis: true,
        },
        {
            title: '封面',
            dataIndex: 'cover',
            width: 100,
            align:'center',
            ellipsis: true,
            render: (cover: {
                id?: string,
                filename?: string,
                mimetype?: string,
                path?: string,
                url?: string
                // eslint-disable-next-line jsx-a11y/alt-text
            }, data: ModelListType) => <img style={{ width: 30, height: 30 }} src={cover.url} />
        },
        {
            title: '模版简介',
            dataIndex: 'desc',
            width: 100,
            align:'center',
            ellipsis: true,
        },
        {
            title: '适用门店',
            dataIndex: ['shop','name'],
            width: 100,
            align:'center',
            ellipsis: true,
        },
        {
            title: '是否启用',
            dataIndex: 'is_use',
            width: 100,
            align:'center',
            ellipsis: true,
            render: (is_use: number, data: ModelListType) => <Switch  checked={is_use === 1 ? true : false} onChange={() => {
                let enableNum = is_use
                if (is_use === 1) {
                    confirm({
                        title: '提示',
                        content: '是否禁用该模版?',
                        onOk() {
                            enableNum = 0
                            clickModelEnable(data.id, enableNum)
                        },
                        onCancel() {

                        },
                    });
                } else {
                    confirm({
                        title: '提示',
                        content: '是否启用该模版?',
                        onOk() {
                            enableNum = 1
                            clickModelEnable(data.id, enableNum)
                        },
                        onCancel() {

                        },
                    });
                }
            }} />
        },
        {
            title: '操作',
            width: 120,
            align:'center',
            dataIndex: 'id',
            render: (id: string, data: ModelListType) =>
                <div style={{ display: 'flex', flexDirection: 'row',alignItems:'center' }}>
                    <div style={{flex:1,display:'flex'}}/>
                   <div
                        style={{
                            color: '#1890FF',
                            cursor: "pointer",
                            marginLeft:10,
                            marginRight: 10,
                        }}
                        onClick={() => {
                            navigate('/queryModel', { state: { id } })
                        }}
                    >
                        详情
                    </div>
                    <div
                        style={{
                            color: '#1890FF',
                            cursor: "pointer",
                            marginLeft:10,
                            marginRight: 10,
                        }}
                        onClick={() => {
                            navigate('/editModel', { state: { id } })
                        }}
                    >
                        编辑
                    </div>
                    <div
                        style={{
                            color: '#FB186A',
                            cursor: "pointer",
                            marginLeft:10,
                            marginRight: 10,
                        }}
                        onClick={() => {
                            confirm({
                                title: '提示',
                                content: '删除后不可恢复，是否确认删除？',
                                onOk() {
                                    deleteModel(id)
                                },
                                onCancel() {

                                },
                            });
                        }}
                    >
                        删除
                    </div>
                    <div style={{flex:1,display:'flex'}}/>
                </div>
        }
    ];

    const userInfo = useContext(UserContext)
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [total, setTotal] = useState<number>(0);
    const [isLoading, setIsLoading] = useState(false);
    const [dataSource, setDataSource] = useState<ModelListType[]>([]);
    const [keyword, setKeyword] = useState('');
    const navigate: any = useNavigate();
    const [shop,setShop] = useState('请选择')
    const [shopId,setShopId] = useState('')
    const [menuData, setMenuData] = useState<any>([])

    const clickModelEnable = (id?: string, enable?: number) => {
        let dict: any = { id, is_use: enable }
        editModelEnable(dict).then(response => {
            if (response.code === 200) {
                onFinish(currentPage, pageSize)
            }
        }).catch(err => {
        })
    }
    const deleteModel = (id?: string) => {
        let dict: any = { id }
        removeModel(dict).then(response => {
            if (response.code === 200) {
                onFinish(currentPage, pageSize)
            }
        }).catch(err => {
        })
    }

    const handleMenuClick = (e: any) => {
        // const num: number = parseInt(e.key)
        console.log('2222',e);
        // setSelectMenuItem(num)
        menuData.map((item:any)=>{
            if(item.key === e.key){
                setShop(item.label)
                setShopId(item.id)
            }
        })
    }
    const menu = (
        <Menu onClick={handleMenuClick}>
            {menuData.map((item:any, index:any) => {
                return <Menu.Item key={`${index}`}>
                    {item.label}
                </Menu.Item>
            })}
        </Menu>
    );
    const onFinish = (page_num: number, page_size: number,clear?:boolean) => {
        let dict: any = { page_num, page_size }
        if(!clear){
            if (keyword) {
                dict.keyword = keyword
            }
            if(shopId){
                dict.shop_id = shopId
            }
        }
        setCurrentPage(page_num);
        setPageSize(page_size)
        setIsLoading(true)
        getModelList(dict).then(response => {
            if (response.code === 200) {
                setTotal(response.data.totalItems)
                let dataCopy = []
                for (let index = 0; index < response.data.items.length; index++) {
                    let item = response.data.items[index]
                    item.key = index + (page_num - 1) * page_size
                    dataCopy.push(item)
                }
                setDataSource(dataCopy)
            } else {
                toastErrorMessage(response.msg)
            }
            if(clear){
                isClear()
            }
            setIsLoading(false)
        }).catch(err => {
            setIsLoading(false)
        })

    }

    const isClear = ()=>{
        setKeyword('')
        setShop('请选择')
        setShopId('')
    }

    const getShopListData = () => {
        getShopList({ page_num: 1, page_size: 1000 }).then(response => {
            if (response.code === 200) {
                
                if(response.data.items.length >0){
                    let List: any =[]
                    for(let index=0;index<response.data.items.length;index++){
                        const item = response.data.items[index]
                        List.push({
                            id:item.id,
                            label: item.name,
                            key: index+''
                        })
                    }
                    setMenuData(List)
                }

            }

        }).catch(err => {

        })
    }
    useEffect(() => {
        getShopListData()
        onFinish(1, 10)
    }, [])

    const handleShowTotal = (total: number, range: number[]) => {
        const totalPage = Math.ceil(total / pageSize);
        return (

            <div style={{ flex: 1, display: "flex", color: '#999999', }}>
                {`共${total}条记录，第${currentPage}/${totalPage}页`}
            </div>


        )
    }

    return (
        <div style={{ height: '100%', width: '100%' }}>
            <div className="ProductionTableStyle">
                <div style={{ paddingTop:10,flex: 1, flexDirection: 'row', display: 'flex', backgroundColor: '#FFF',}}>
                    <div style={{ width: 24, height: 1 }} />

                    <div style={{ fontSize: 14, marginTop: 18 }}>门店名称：</div>
                    <Dropdown overlay={menu} >
                        <Button style={{ width: 197, marginTop: 12,flexDirection: 'row', display: 'flex', }}>
                            {shop.length<10? shop: shop.substring(0,9)+'...'}
                            <div style={{ flex: 1, display: 'flex' }} />
                            <img style={{ marginRight: 0,width: 30, height: 30, marginTop: -3  }}
                                src={require('../../assets/icon_expanded.png')}
                                alt=""
                            />
                        </Button>
                    </Dropdown>


                    <Input style={{ width: 200,height:32, marginRight: 20,marginLeft:20,marginTop:12 }} value={keyword} placeholder='请输入模板名称' onChange={(e) => {
                        setKeyword(e.target.value)
                    }}
                    />
                    <div className='button' style={{ backgroundColor: '#FB186A', marginTop: 12 }} onClick={(e) => {
                        onFinish(1, pageSize)
                    }}>
                        <div className='buttonTitle' style={{ color: '#FFF' }}>
                            查询
                        </div>
                    </div>
                    <div className='button' style={{ borderStyle: 'solid', borderWidth: 1, borderColor: '#FB186A', marginLeft: 20, marginTop: 12 }} onClick={() => {
                            onFinish(1, pageSize,true)
                    }}>
                        <div className='buttonTitle'>
                            重置
                        </div>
                    </div>
                    <div style={{flex:1}}/>


                    <div className='button' style={{width:96, backgroundColor: '#FB186A', marginLeft: 20, marginTop: 12, marginRight: 20 }} onClick={(e) => {
                        navigate('/addModel')
                    }}>
                         <img
                    alt=''
                    style={{width:13,height:13,marginRight:5}}
                    src={require('../../assets/icon_add.png')}
                />
                        <div className='buttonTitle' style={{ color: '#FFF',fontSize:14,paddingTop:2, }}>
                            添加模版
                        </div>
                    </div>
               
                </div>
            </div>


            <div style={{backgroundColor:'#FFF'}}>
                <div style={{padding:20}}>
                <Table
                    className={'commonTable'}
                    columns={columns}
                    dataSource={dataSource}
                    loading={isLoading}
                    scroll={{ x: 1200 }}
                    pagination={{
                        position: ['bottomRight'],
                        defaultCurrent: 1,
                        defaultPageSize: 10,
                        current: currentPage,
                        pageSize: pageSize,
                        showSizeChanger: true,
                        total: total,
                        showQuickJumper: true,
                        onChange: onFinish,
                        showTotal: handleShowTotal
                    }}
                />

                </div>
               
            </div>


        </div>
    )
}
export default ModelManager
