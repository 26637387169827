import React, { useContext, useEffect, useState } from 'react';
import { Menu } from 'antd';
import { useNavigate, useLocation } from "react-router-dom";
import { UserContext } from "../../contexts/userContext";
import type { MenuProps } from 'antd';


type MenuItem = Required<MenuProps>['items'][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: 'group',
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}

const items: MenuItem[] = [
  getItem('门店管理', '4', null),
  getItem('模板中心', '3', null),
  getItem('订单管理', '2', null),
  getItem('兑换码管理', '1', null),
  getItem('商户管理', '0', null),
];


const SiderBar = () => {
  const [selectedKeys, setSelectedKeys] = useState<string>('4');
  const navigate = useNavigate();
  const location = useLocation();
  const { useInfo } = useContext(UserContext);
  const showMenuItem = (item: any) => {
  
      return <Menu.Item key={item.key} className={'mes-subMenu-text'}>
      <img src={selectedKeys === item.key ? require(`../../assets/spink-${item.key}-icon.png`) : require(`../../assets/pink-${item.key}-icon.png`)} alt={''} className={'mes-menu-icon'} />
      <span className={'mes-subMenu-text-icon'} style={{ color: selectedKeys === item.key ? '#fb186a' : '#FFF' }}>{item.label}</span>
    </Menu.Item>
  }
  useEffect(() => {

    console.log('useEffect',location.pathname);

    if(useInfo.permission ===1 ){
      if ((location.pathname === '/storgeManager')  ) {
        setSelectedKeys('4')
      }else if((location.pathname === '/model')){
        setSelectedKeys('3')
      }else if( (location.pathname === '/order')){
        setSelectedKeys('2')
      }else if((location.pathname === '/codeManager')){
        setSelectedKeys('1')
      }else if((location.pathname === '/businessManager')){
        setSelectedKeys('0')
      }
    }else{
      setSelectedKeys('2')
    }
    
  }, [location.pathname, useInfo])

  return (
    <Menu 
    theme={'dark'}
    mode={'inline'}
      onClick={e => {
        console.log('MenuMenu',e.key);
        
        if (e.key === '1') {
          navigate('/codeManager')
          setTimeout(() => {
            setSelectedKeys('1')
          }, 300)
        } else if (e.key === '2') {
          navigate('/order')
          setTimeout(() => {
            setSelectedKeys('2')
          }, 300)

        } else if (e.key === '3') {
          navigate('/model')
          setTimeout(() => {
            setSelectedKeys('3')
          }, 300)

        } else if (e.key === '4') {
          navigate('/storgeManager')
          setTimeout(() => {
            setSelectedKeys('4')
          }, 300)
        } else if (e.key === '0') {
          navigate('/businessManager')
          setTimeout(() => {
            setSelectedKeys('0')
          }, 300)
        }
    
      }}
      selectedKeys={[selectedKeys]}
    >
      {
        items.map((e:any,index:number) => {
          console.log('useInfo.permission', useInfo.permission);
          if(useInfo.permission===1){
            return showMenuItem(e)
          }else{
            if(index === 2){
              return showMenuItem(e)
            }else{
              return null
            }
          }
        })
      }
    </Menu>
  )
}

export default React.memo(SiderBar);
