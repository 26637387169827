import Login from "./Login";
import OrderManager from "./Order/OrderManager"
import ModelManager from "./Model"
import AddModel from "./Model/AddModel"
import EditModel from "./Model/EditModel"
import QueryModel from "./Model/QueryModel"
import OrderDetail from "./Order/OderDetail"
import StorgeManager from "./Storge"
import CodeManager from "./Code"
import AddStorge from "./Storge/AddStorge"
import QueryStorge from "./Storge/QueryStorge"
import EditStorge from "./Storge/EditStorge"
import CreatCode from "./Code/CreatCode"
import Refund from "./Order/Refund"
import BusinessManager from "./BusinessManager";

export interface Indexable {
    [key: string]: any;
}

export default {
    Login,
    OrderManager,
    ModelManager,
    AddModel,
    EditModel,
    OrderDetail,
    StorgeManager,
    CodeManager,
    AddStorge,
    QueryStorge,
    EditStorge,
    QueryModel,
    CreatCode,
    Refund,
    BusinessManager
} as Indexable
