import React from "react";
import {Result, Button} from 'antd';
import {useNavigate} from 'react-router-dom';


const Result403 = () => {
    const navigate = useNavigate();

    return (
        <Result status={'403'} title={'403'} subTitle={'您没有此页面的访问权限！'}
                extra={<Button type="primary" onClick={() => navigate('/')}>返回首页</Button>}/>
    )
}

const Result404 = () => {
    const navigate = useNavigate()

    return (
        <Result status={'404'} title={'404'} subTitle={'此页面未找到！'}
                extra={<Button type="primary" onClick={() => navigate('/')}>返回首页</Button>}/>
    )
}

export {
    Result403,
    Result404,
}
