import React, {useContext, useEffect, useState} from "react";
import {Routes, Route, useLocation, useNavigate} from 'react-router-dom';
import AllComponents from '../pages';
import {Result403, Result404} from '../pages/ErrorPage'
import {UserContext} from "../contexts/userContext";
import StorgeManager from '../pages/Storge'
import OrderManager from "../pages/Order/OrderManager";


export interface IFMenuBase {
    key: string;
    label?: any;
    icon?: any;
    title: any;
    component?: string;
    route?: string;
}

export interface IFMenu extends IFMenuBase {
    children?: IFMenu[];
}

export const menus =[
    { key: 'order',
    component: "OrderManager",
    route:'/order',
    title: "订单管理"},
    { key: 'model',
    component: "ModelManager",
    route:'/model',
    title: "模板中心"},
    { key: 'addModel',
    component: "AddModel",
    route:'/addModel',
    title: "模板/添加"},
    { key: 'editModel',
    component: "EditModel",
    route:'/editModel',
    title: "模板/编辑"},
    { key: 'queryModel',
    component: "QueryModel",
    route:'/queryModel',
    title: "模板/详情"},
    { key: 'orderDetail',
    component: "OrderDetail",
    route:'/orderDetail',
    title: "订单管理/订单详情"},
    { key: 'storgeManager',
    component: "StorgeManager",
    route:'/storgeManager',
    title: "门店管理"},
    { key: 'codeManager',
    component: "CodeManager",
    route:'/codeManager',
    title: "兑换码管理"},
    { key: 'addStorge',
    component: "AddStorge",
    route:'/addStorge',
    title: "门店管理/添加"},
    { key: 'queryStorge',
    component: "QueryStorge",
    route:'/queryStorge',
    title: "门店管理/详情"},
    { key: 'editStorge',
    component: "EditStorge",
    route:'/editStorge',
    title: "门店管理/编辑"},
    { key: 'creatCode',
    component: "CreatCode",
    route:'/creatCode',
    title: "兑换码管理/生成兑换码"},
    { key: 'refund',
    component: "Refund",
    route:'/refund',
    title: "兑换码管理/退款"},
    { key: 'businessManager',
        component: "BusinessManager",
        route:'/businessManager',
        title: "商户管理"},
 ];
const Router = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const {useInfo} = useContext(UserContext);
 
    const getRoutes = (r: IFMenu) => {
        const route = (r: IFMenuBase) => {
            const Component: React.ReactNode = r.component && AllComponents[r.component];
            if (Component) {
                return (
                    <Route
                        key={r.key}
                        path={r.route}
                        // @ts-ignore
                        element={<Component/>}
                    />
                );
            }
        };

        const subRoute = (r: IFMenu): any =>
            r.children && r.children.map((subR: any) => (subR.children ? subRoute(subR) : route(subR)));
            return r.component ? route(r) : subRoute(r);
    }

    useEffect(() => {
      
    }, [])

    return (
        <>
            <Routes>
            <Route path="/" element={<StorgeManager/>}/>
                <Route path="/403" element={<Result403/>}/>
                <Route path="/404" element={<Result404/>}/>
                {menus && menus.map(getRoutes)}
            </Routes>
        </>
    )
}

export default Router;
