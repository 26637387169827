import React, { useEffect, useState, } from "react";
import '../../../globalLess/global.css'
import '../../../components/Layout/index.less';
import { getOrderDetail, OrderRefund } from "../../../service";
import { useLocation } from "react-router-dom";
import '../OderDetail/index.less'
import { toastErrorMessage, toastMessage, toastSuccessMessage } from "../../../utils";


const Refund = (): React.ReactElement => {

    let location = useLocation()

    const [order_Id,setOrder_Id] = useState('')
    const [orderInfo, setOrderInfo] = useState<any>({
        order_amount: '',
        deduction_amount: '',
        payment_amount: ''
    });
    const [serverInfo, setServerInfo] = useState<any>({
        choose_num: '',
        unify_single_price: '',
        total: ''
    })
    const [data, setData] = useState<any>({
        reserve_name: '',
        reserve_phone: '',
        reserve_sex: '',
        user: {
            wx_name: ''
        },
        shop_snapshot: {
            ticket_name: '',
            take_duration: '',
            cut_number: 0,
            ticket_price
                : ''
        },
        order_code: '',
        status: 0,

    });

    const orderDetail = (id: string) => {
        let dict = {
            id
        }
        getOrderDetail(dict).then((res: any) => {
            if (res.code === 200) {
                setData(res.data)
                setOrderInfo({
                    order_amount: res.data.order_amount,
                    deduction_amount: res.data.deduction_amount,
                    payment_amount: res.data.payment_amount ? res.data.payment_amount : ''
                })

                if (res.data.nativePay) {
                    setServerInfo({
                        choose_num: res.data.nativePay.choose_num ? res.data.nativePay.choose_num : '',
                        unify_single_price: (res.data.nativePay.charge_type === 0) ? (res.data.nativePay.unify_single_price * 1.0 / 100) : (res.data.nativePay.charge_type === 1) ? (res.data.nativePay.stair_single_price * 1.0 / 100) : '',
                        total: (res.data.nativePay.total * 1.0 / 100)
                    })
                }
            }
        }).catch((err: any) => {

        })
    }

    const refund = ()=>{
        OrderRefund({order_id: order_Id}).then((res: any) => {
            if (res.code === 200) {
                toastSuccessMessage('退款成功')
                setTimeout(()=>{
                    window.history.go(-1)
                },1000)
            }else{
                toastMessage(res.msg)
            }
        }).catch((err: any) => {
toastErrorMessage(err.msg)
        })
    }

    useEffect(() => {
        // eslint-disable-next-line array-callback-return
        if (location && location.state && location.state.id) {
            orderDetail(location.state.id)
            setOrder_Id(location.state.id)
        }
    }, [])

    return (
        <div style={{ height: '100%', width: '100%', }}>
            <div style={{ height: 284, width: '100%', backgroundColor: '#FFF', marginTop: 0 }}>
                <div style={{ flexDirection: 'row', display: 'flex', paddingTop: 22 }}>
                    <div style={{ marginLeft: 32, fontSize: 18, color: '#1E1E1E' }}>订单号：</div>
                    <div style={{ fontSize: 18, color: '#FB186A' }}>{data.order_code}</div>
                </div>
                <div style={{ height: 202, backgroundColor: '#FCFCFC', flexDirection: 'row', display: 'flex', marginLeft: 18, marginRight: 18, marginTop: 0 }}>
                    <div style={{ flex: 1, display: 'flex', flexDirection: 'row', }}>
                        <div style={{ fontSize: 18, color: '#1E1E1E', width: 100, marginLeft: 16, marginTop: 17 }}>基础服务</div>
                        <div style={{ width: '100%', marginTop: 57, flexDirection: 'row', display: 'flex' }}>
                            <div style={{ marginLeft: 74 }}>
                                <div style={{ flexDirection: 'row', display: 'flex', marginTop: 14 }}>
                                    <div style={{ fontSize: 14, color: '#666666' }}>应付金额：</div>
                                    <div style={{ fontSize: 14, color: '#000000', marginLeft: 400 }}>{orderInfo.order_amount ? ('¥' + orderInfo.order_amount)
                                        : orderInfo.order_amount}</div>
                                </div>
                                <div>
                                </div>
                                <div style={{ flexDirection: 'row', display: 'flex', marginTop: 12 }}>
                                    <div style={{ fontSize: 14, color: '#666666' }}>优惠金额：</div>
                                    <div style={{ fontSize: 14, color: '#000000', marginLeft: 400 }}>{(orderInfo.deduction_amount) || (orderInfo.deduction_amount === 0) ? ('¥' + orderInfo.deduction_amount)
                                        : orderInfo.deduction_amount}</div>
                                </div>
                                <div style={{ flexDirection: 'row', display: 'flex', marginTop: 12 }}>
                                    <div style={{ fontSize: 14, color: '#666666' }}>实付金额：</div>
                                    <div style={{ fontSize: 14, color: '#000000', marginLeft: 400 }}>{orderInfo.payment_amount ? ('¥' + orderInfo.payment_amount)
                                        : orderInfo.payment_amount
                                    }</div>
                                </div>
                                <div style={{ flexDirection: 'row', display: 'flex', marginTop: 12 }}>
                                    <div style={{ fontSize: 14, color: '#666666' }}>(退款后优惠将会原路退回)</div>
                                </div>
                                <div>
                                </div>
                            </div>

                        </div>


                    </div>
                    <div style={{ marginLeft: 0, width: 0.5, height: 154, marginTop: 24, backgroundColor: '#F0F0F0' }} />

                    <div style={{ flex: 1, display: 'flex' }}>
                        <div style={{ flex: 1, display: 'flex', flexDirection: 'row', }}>
                            <div style={{ fontSize: 18, color: '#1E1E1E', width: 100, marginLeft: 31, marginTop: 17 }}>增值服务</div>
                            <div style={{ width: '100%', marginTop: 57, flexDirection: 'row', display: 'flex' }}>
                                <div style={{ marginLeft: 74 }}>
                                    <div style={{ flexDirection: 'row', display: 'flex', marginTop: 14 }}>
                                        <div style={{ fontSize: 14, color: '#666666', marginLeft: -28 }}>购买条数：</div>
                                        <div style={{ fontSize: 14, color: '#000000', marginLeft: 400 }}>{serverInfo.choose_num}</div>
                                    </div>
                                    <div>
                                    </div>
                                    <div style={{ flexDirection: 'row', display: 'flex', marginTop: 12 }}>
                                        <div style={{ fontSize: 14, color: '#666666' }}>单价：</div>
                                        <div style={{ fontSize: 14, color: '#000000', marginLeft: 400 }}>{serverInfo.unify_single_price ? ('¥' + serverInfo.unify_single_price)
                                            : serverInfo.unify_single_price}</div>
                                    </div>
                                    <div style={{ flexDirection: 'row', display: 'flex', marginTop: 12 }}>
                                        <div style={{ fontSize: 14, color: '#666666' }}>小计：</div>
                                        <div style={{ fontSize: 14, color: '#000000', marginLeft: 400 }}>{(serverInfo.total) || (serverInfo.total === 0) ? ('¥' + serverInfo.total)
                                            : serverInfo.total
                                        }</div>
                                    </div>

                                    <div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ borderRadius: 5, backgroundColor: '#FFF', width: '100%', height: 200, marginTop: 16 }}>
                <div style={{ flexDirection: 'row', display: 'flex', marginLeft: 216, paddingTop: 32 }}>
                    <div
                        className='inputTitle' style={{ fontSize: 14, width: 90, color: '#666666', marginRight: -5 }}
                    >退款方式：</div>
                    <img style={{ marginLeft: 0, width: 16, marginTop: 3, height: 16, zIndex: 20, cursor: 'pointer' }}
                        src={require('../../../assets/icon_selectIcon.png')}
                        alt=""
                    />
                    <div
                        className='inputTitle' style={{ fontSize: 14, marginLeft: -10, width: 90, color: '#666666', marginRight: -5 }}
                    >原路返回</div>
                </div>
                <div style={{ flexDirection: 'row', display: 'flex', marginLeft: 216, marginTop: 20 }}>
                    <div
                        className='inputTitle' style={{ fontSize: 14, width: 90, color: '#666666', marginRight: -5 }}
                    >退款金额：</div>
                    <img style={{ marginLeft: 0, width: 16, marginTop: 3, height: 16, zIndex: 20, cursor: 'pointer' }}
                        src={require('../../../assets/icon_selectIcon.png')}
                        alt=""
                    />
                    <div
                        className='inputTitle' style={{ fontSize: 14, marginLeft: -10, width: 90, color: '#666666', marginRight: -5 }}
                    >全额退款</div>
                </div>
            </div>
            <div className='center' style={{ backgroundColor: '#FFF', width: '100%', flexDirection: 'row', display: 'flex', height: 80, marginTop: 16 }}>

                <div className='button' style={{ width: 104, backgroundColor: '#FB186A', marginRight: 24 }} onClick={(e) => {
               refund()
               }}>
                    <div className='buttonTitle' style={{ color: '#FFF', fontSize: 14, paddingTop: 2, }}>
                        确认退款
                    </div>
                </div>
                <div className='button' style={{ width: 104, borderStyle: 'solid', borderWidth: 1, borderColor: '#D9D9D9' }} onClick={() => {
                    window.history.go(-1)
                }}>
                    <div className='buttonTitle' style={{ color: '#333' }}>
                        取消
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Refund
