import React, { useEffect, useState } from "react";
import "../../../globalLess/global.css";
import "../../../components/Layout/index.less";
import { OrderStatus } from "../../../utils";
import { getOrderDetail } from "../../../service";
import { useLocation } from "react-router-dom";
import "./index.less";

import moment from "moment";

const tabMenu = [
  {
    label: "订单信息",
    key: "1",
  },
  {
    label: "分账明细",
    key: "2",
  },
];

const OrderDetail = (): React.ReactElement => {
  let location = useLocation();
  const [current, setCurrent] = useState("1");
  const [orderInfo, setOrderInfo] = useState<any>([
    { label: "下单时间", value: "" },
    { label: "付款时间", value: "" },
    { label: "应付金额", value: "" },
    { label: "优惠明细", value: "" },
    { label: "实付金额", value: "" },
  ]);
  const [serverInfo, setServerInfo] = useState<any>([
    { label: "购买条数", value: "" },
    { label: "单价", value: "" },
    { label: "支付时间", value: "" },
    { label: "实付金额", value: "" },
  ]);
  const [refundInfo, setRefundInfo] = useState<any>([
    { label: "退款单号", value: "" },
    { label: "退款金额", value: "" },
    { label: "退款状态", value: "" },
    { label: "退款完成时间", value: "" },
    { label: "操作时间", value: "" },
  ]);
  const [refundServer, setRefundServer] = useState<any>([
    { label: "退款单号", value: "" },
    { label: "退款金额", value: "" },
    { label: "退款状态", value: "" },
    { label: "退款完成时间", value: "" },
  ]);
  const [menuOrderInfo, setMenuOrderInfo] = useState<any>([
    {
      count:"参与记账商户",
      number:'商户号/手机号',
      math:'记账比例',
      amount:"记账金额",
    },
  ]);
  const [divideOrder, setDivideOrder] = useState<any>([
    {
      count:"分账商户",
      number:'商户号/手机号',
      radio:'分账比例',
      type:'服务类型',
      amount1:"应分金额",
      amount2:"已分金额",
      status:'分账状态'
    },
  ]);

  const [pay_time, setPay_time] = useState("");
  const [verify_time, setVerify_time] = useState("");
  const [step, setStep] = useState("0");
  const [edited_video, setEdited_video] = useState<any>([]);
  const [select_templates, setSelect_templates] = useState<any>([]);

  const [data, setData] = useState<any>({
    reserve_name: "",
    reserve_phone: "",
    reserve_sex: "",
    user: {
      wx_name: "",
    },
    shop_snapshot: {
      ticket_name: "",
      take_duration: "",
      cut_number: 0,
      ticket_price: "",
    },
    order_code: "",
    status: 0,
    payment_amount:0,
    profitrecord:{
        amount:0
    },
    profit_amount:0,
    profit_surplus:0,
    complete_time:''
  });

  const orderDetail = (id: string) => {
    let dict = {
      id,
    };
    getOrderDetail(dict)
      .then((res: any) => {
        if (res.code === 200) {
          setData(res.data);
          if (res.data.status === 4) {
            setStep("3");
          } else if (res.data.status === 1) {
            setStep("1");
          } else if (res.data.status === 0) {
            setStep("0");
          } else {
            setStep("2");
          }

          res.data.pay &&
            res.data.pay.success_time &&
            setPay_time(
              moment(res.data.pay.success_time).format("YYYY-MM-DD HH:mm")
            );
          res.data.verify_time &&
            setVerify_time(
              moment(res.data.verify_time).format("YYYY-MM-DD HH:mm")
            );
          setOrderInfo([
            {
              label: "下单时间",
              value: moment(res.data.create_time).format("YYYY-MM-DD HH:mm"),
            },
            {
              label: "付款时间",
              value:
                res.data.pay && res.data.pay.success_time
                  ? moment(res.data.pay.success_time).format("YYYY-MM-DD HH:mm")
                  : "",
            },
            { label: "应付金额", value: res.data.order_amount },
            { label: "优惠明细", value: res.data.deduction_amount },
            {
              label: "实付金额",
              value: res.data.payment_amount ? res.data.payment_amount : "",
            },
          ]);

          if (res.data.nativePay) {
            if (
              res.data.nativePay.status === 2 ||
              res.data.nativePay.status === 4
            ) {
              setServerInfo([
                {
                  label: "购买条数",
                  value: res.data.nativePay.choose_num
                    ? res.data.nativePay.choose_num
                    : "",
                },
                {
                  label: "单价",
                  value:
                    res.data.nativePay.charge_type === 0
                      ? (res.data.nativePay.unify_single_price * 1.0) / 100
                      : res.data.nativePay.charge_type === 1
                      ? (res.data.nativePay.stair_single_price * 1.0) / 100
                      : "",
                },
                {
                  label: "支付时间",
                  value: res.data.nativePay.success_time
                    ? moment(res.data.nativePay.success_time).format(
                        "YYYY-MM-DD HH:mm"
                      )
                    : "",
                },
                {
                  label: "实付金额",
                  value: (res.data.nativePay.total * 1.0) / 100,
                },
              ]);
            }

            setRefundServer([
              {
                label: "退款单号",
                value: res.data.nativePay.refund_id
                  ? res.data.nativePay.refund_id
                  : "",
              },
              {
                label: "退款金额",
                value: res.data.nativePay.refund_total
                  ? (res.data.nativePay.refund_total * 1.0) / 100
                  : "",
              },
              {
                label: "退款状态",
                value:
                  res.data.nativePay.status &&
                  res.data.nativePay.refund_status === "SUCCESS"
                    ? "已完成"
                    : "",
              },
              {
                label: "退款完成时间",
                value: res.data.nativePay.refund_success_time
                  ? moment(res.data.nativePay.refund_success_time).format(
                      "YYYY-MM-DD HH:mm"
                    )
                  : "",
              },
            ]);
          }
          if (res.data.refund) {
            setRefundInfo([
              {
                label: "退款单号",
                value: res.data.refund.refund_id
                  ? res.data.refund.refund_id
                  : "",
              },
              {
                label: "退款金额",
                value: res.data.refund.refund
                  ? (res.data.refund.refund * 1.0) / 100
                  : "",
              },
              { label: "退款状态", value: "已完成" },
              {
                label: "退款完成时间",
                value: res.data.refund.success_time
                  ? moment(res.data.refund.success_time).format(
                      "YYYY-MM-DD HH:mm"
                    )
                  : "",
              },
              {
                label: "操作时间",
                value: res.data.refund.create_time
                  ? moment(res.data.refund.create_time).format(
                      "YYYY-MM-DD HH:mm"
                    )
                  : "",
              },
            ]);
          }
          if(res.data.profitrecords&&res.data.profitrecords.length){
            let menuOrderInfoArray = [{
              count:"参与记账商户",
              number:'商户号/手机号',
              math:'记账比例',
              amount:"记账金额",
            }]
            for (let index = 0; index < res.data.profitrecords.length; index++) {
              const profitrecords = res.data.profitrecords[index];

              if(profitrecords.receivers && profitrecords.receivers.length){
                for (let j = 0; j < profitrecords.receivers.length; j++) {
                  const receivers = profitrecords.receivers[j];
                  let dict =  {
                    count:receivers.merchant.merchant_name,
                    number:receivers.merchant.merchant_type==='1'? receivers.merchant.merchant_code: receivers.merchant.merchant_phone,
                    math:parseFloat(receivers.ratio)*100 +'%',
                    amount:'¥'+receivers.amount/100,
                  }
                  menuOrderInfoArray.push(dict)
                }
              }
            }
            setMenuOrderInfo(menuOrderInfoArray)
          }

       
          if(res.data.profitsharings&&res.data.profitsharings.length){
            let divideOrderArray = [{
              count:"分账商户",
              number:'商户号/手机号',
              radio:'分账比例',
              type:'服务类型',
              amount1:"应分金额",
              amount2:"已分金额",
              status:'分账状态'
            }]
            for (let index = 0; index < res.data.profitsharings.length; index++) {
              const profitsharings = res.data.profitsharings[index];

              if(profitsharings.receivers && profitsharings.receivers.length){
                for (let j = 0; j < profitsharings.receivers.length; j++) {
                  const receivers = profitsharings.receivers[j];
                  let dict =  {
                    count:receivers.merchant.merchant_name,
                    number:receivers.merchant.merchant_type==='1'? receivers.merchant.merchant_code: receivers.merchant.merchant_phone,
                   radio: receivers.ratio? `${receivers.ratio*100}%`:'',
                    type: receivers.pay_type === 'PAY'?'门票':'增值服务',
                    amount1:receivers.amount?`¥${receivers.amount/100}` : '',
                    amount2: receivers.wechat_order && (receivers.wechat_order.result ==='SUCCESS')?`¥${receivers.wechat_order.amount/100}` :'',
                    status: receivers.status ? receivers.status ==='PENDING'?'分账中': receivers.status ==='SUCCESS'?'分账成功': receivers.status ==='REFUND'?'已退款':'分账失败' :'',
                  }
                  divideOrderArray.push(dict)
                }
              }
            }
            setDivideOrder(divideOrderArray)
          }



          res.data.edited_videos && setEdited_video(res.data.edited_videos);
          res.data.select_templates &&
            res.data.select_templates.length > 0 &&
            setSelect_templates(res.data.select_templates);
        }
      })
      .catch((err: any) => {});
  };
  const handleAmount= (profitsharings: any)=>{
    let amount = '¥0'
    let number =0

    if(profitsharings.length>0){
      for (let index = 0; index < profitsharings.length; index++) {
        const profitsharingsItem = profitsharings[index];
        for (let j = 0; j < profitsharingsItem.receivers.length; j++) {
          const receiver = profitsharingsItem.receivers[j];
          if(receiver.wechat_order && (receiver.wechat_order.result  === 'SUCCESS')){
            if(receiver.wechat_order.amount){
                number+=receiver.wechat_order.amount
            }
        }
        }
      }
    }

    if(number>0){
        amount= '¥'+number/100
    }

    return amount
}
const totalAmount= (data:any)=>{
  let number = 0 
  if(data.pay&&  data.pay.total){
   number= data.pay.total
 }
 if(data.nativePay){
   number+=data.nativePay.payer_total
 }
 if(number >0){
   number = number
   /100
 }
 return number
}
  useEffect(() => {
    // eslint-disable-next-line array-callback-return
    if (location && location.state && location.state.id) {
      orderDetail(location.state.id);
    }
  }, []);

  return (
    <div style={{ height: "100%", width: "100%", paddingBottom: 30 }}>
      <div
        style={{
          backgroundColor: "#FFF",
          display: "flex",
          flexDirection: "row",
          paddingTop: 20,
          marginBottom: 1,
        }}
      >
        {" "}
        {tabMenu.map((item) => {
          return (
            <div
              key={item.key}
              style={{
                marginLeft: item.key === "1" ? 5 : 20,
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
              }}
              onClick={() => {
                setCurrent(item.key);
              }}
            >
              <div
                style={{
                  width: 70,
                  fontSize: 14,
                  color: current === item.key ? "#FB186A" : "#000000",
                  textAlign: "center",
                }}
              >
                {" "}
                {item.label}
              </div>
              <div
                style={{
                  marginTop: 5,
                  height: 1,
                  marginLeft: 10,
                  width: 50,
                  backgroundColor: current === item.key ? "#FB186A" : "#FFF",
                }}
              />
            </div>
          );
        })}
      </div>
      {current === "1" && (
        <div>
          <div
            style={{
              backgroundColor: "#FFF",
              width: "100%",
              height: 130,
              paddingTop: 30,
              borderRadius: 5,
            }}
          >
            <div
              style={{
                width: "100%",
                height: 60,
                flexDirection: "column",
                display: "flex",
              }}
            >
              <div
                style={{ width: "100%", flexDirection: "row", display: "flex" }}
              >
                <div style={{ width: "22%", height: 1, display: "flex" }} />
                <div
                  style={{
                    width: "27%",
                    height: 30,
                    marginRight: -30,
                    flexDirection: "row",
                    display: "flex",
                  }}
                >
                  <img
                    style={{
                      marginRight: 5,
                      width: 32,
                      height: 32,
                      marginTop: 8,
                    }}
                    src={
                      step === "0"
                        ? require("../../../assets/icon_unStep1.png")
                        : require("../../../assets/icon_stepOK.png")
                    }
                    alt=""
                  />
                  <div
                    style={{ marginTop: 12, fontSize: 16, color: "#1E1E1E" }}
                  >
                    已支付{" "}
                  </div>
                  <div
                    style={{
                      backgroundColor: step === "0" ? "#999999" : "#FB186A",
                      width: "65%",
                      height: 1,
                      marginTop: 25,
                      marginLeft: 10,
                    }}
                  />
                </div>
                <div
                  style={{
                    width: "27%",
                    height: 30,
                    flexDirection: "row",
                    display: "flex",
                  }}
                >
                  <img
                    style={{
                      marginRight: 5,
                      width: 32,
                      height: 32,
                      marginTop: 8,
                    }}
                    src={
                      step === "2" || step === "3"
                        ? require("../../../assets/icon_stepOK.png")
                        : step === "1"
                        ? require("../../../assets/icon_step2.png")
                        : require("../../../assets/icon_unStep2.png")
                    }
                    alt=""
                  />
                  <div
                    style={{ marginTop: 12, fontSize: 16, color: "#1E1E1E" }}
                  >
                    已核销{" "}
                  </div>
                  <div
                    style={{
                      backgroundColor:
                        step === "2" || step === "3" ? "#FB186A" : "#999999",
                      width: "70%",
                      height: 1,
                      marginTop: 25,
                      marginLeft: 10,
                    }}
                  />
                </div>
                <div
                  style={{
                    width: "19%",
                    height: 30,
                    flexDirection: "row",
                    display: "flex",
                  }}
                >
                  <img
                    style={{
                      marginRight: 5,
                      width: 32,
                      height: 32,
                      marginTop: 8,
                    }}
                    src={
                      step === "3"
                        ? require("../../../assets/icon_stepOK.png")
                        : step === "2"
                        ? require("../../../assets/icon_step3.png")
                        : require("../../../assets/icon_unStep3.png")
                    }
                    alt=""
                  />
                  <div
                    style={{ fontSize: 16, marginTop: 12, color: "#1E1E1E" }}
                  >
                    订单完成{" "}
                  </div>
                </div>
              </div>
              <div
                style={{ width: "100%", flexDirection: "row", display: "flex" }}
              >
                <div style={{ width: "22%", height: 1, display: "flex" }} />
                <div
                  style={{
                    width: "27%",
                    height: 30,
                    marginRight: -30,
                    flexDirection: "row",
                    display: "flex",
                  }}
                >
                  <div
                    style={{
                      paddingLeft: 35,
                      fontSize: 14,
                      marginTop: 12,
                      color: "#666666",
                    }}
                  >
                    {pay_time}{" "}
                  </div>
                </div>
                <div
                  style={{
                    width: "27%",
                    height: 30,
                    flexDirection: "row",
                    display: "flex",
                  }}
                >
                  <div
                    style={{
                      paddingLeft: 35,
                      fontSize: 14,
                      marginTop: 12,
                      color: "#666666",
                    }}
                  >
                    {verify_time}{" "}
                  </div>
                </div>
                <div
                  style={{
                    width: "19%",
                    height: 30,
                    flexDirection: "row",
                    display: "flex",
                  }}
                ></div>
              </div>
            </div>
          </div>

          <div
            style={{
              height: 180,
              backgroundColor: "#FFF",
              flexDirection: "row",
              display: "flex",
              width: "100%",
              marginTop: 16,
            }}
          >
            <div style={{ flex: 1, display: "flex", flexDirection: "row" }}>
              <div
                style={{
                  fontSize: 18,
                  color: "#000000",
                  width: 100,
                  marginLeft: 32,
                  marginTop: 17,
                }}
              >
                拍摄信息
              </div>
              <div
                style={{
                  width: "100%",
                  marginTop: 57,
                  flexDirection: "row",
                  display: "flex",
                }}
              >
                <img
                  src={require("../../../assets/icon_testImg.png")}
                  alt={""}
                  style={{ width: 88, height: 88, marginLeft: 0 }}
                />
                <div style={{ marginLeft: 74 }}>
                  <div
                    style={{
                      flexDirection: "row",
                      display: "flex",
                      marginTop: 14,
                    }}
                  >
                    <div style={{ fontSize: 14, color: "#666666" }}>昵称：</div>
                    <div style={{ fontSize: 14, color: "#000000" }}>
                      {data.user.wx_name}
                    </div>
                  </div>
                  <div></div>
                  <div
                    style={{
                      flexDirection: "row",
                      display: "flex",
                      marginTop: 12,
                    }}
                  >
                    <div style={{ fontSize: 14, color: "#666666" }}>姓名：</div>
                    <div style={{ fontSize: 14, color: "#000000" }}>
                      {data.reserve_name}
                    </div>
                  </div>
                  <div></div>
                </div>
                <div style={{ marginLeft: 120 }}>
                  <div
                    style={{
                      flexDirection: "row",
                      display: "flex",
                      marginTop: 14,
                    }}
                  >
                    <div style={{ fontSize: 14, color: "#666666" }}>电话：</div>
                    <div style={{ fontSize: 14, color: "#000000" }}>
                      {data.reserve_phone}
                    </div>
                  </div>
                  <div></div>
                  <div
                    style={{
                      flexDirection: "row",
                      display: "flex",
                      marginTop: 12,
                    }}
                  >
                    <div style={{ fontSize: 14, color: "#666666" }}>
                      性别：{" "}
                    </div>
                    <div style={{ fontSize: 14, color: "#000000" }}>
                      {data.reserve_sex === 0 ? "男" : "女"}
                    </div>
                  </div>
                  <div></div>
                </div>
              </div>
            </div>
            <div
              style={{
                marginLeft: 0,
                width: 0.5,
                height: 110,
                marginTop: 35,
                backgroundColor: "#F0F0F0",
              }}
            />
            <div style={{ flex: 1, display: "flex" }}>
              <div style={{ flex: 1, display: "flex", flexDirection: "row" }}>
                <div
                  style={{
                    fontSize: 18,
                    color: "#000000",
                    width: 100,
                    marginLeft: 32,
                    marginTop: 17,
                  }}
                >
                  门票信息
                </div>
                <div
                  style={{
                    width: "100%",
                    marginTop: 57,
                    flexDirection: "row",
                    display: "flex",
                  }}
                >
                  <img
                    src={require("../../../assets/icon_testImg.png")}
                    alt={""}
                    style={{ width: 88, height: 88, marginLeft: 0 }}
                  />
                  <div style={{ marginLeft: 74 }}>
                    <div
                      style={{
                        flexDirection: "row",
                        display: "flex",
                        marginTop: 14,
                      }}
                    >
                      <div style={{ fontSize: 14, color: "#666666" }}>
                        门票名称：
                      </div>
                      <div style={{ fontSize: 14, color: "#000000" }}>
                        {data.shop_snapshot.ticket_name}
                      </div>
                    </div>
                    <div></div>
                    <div
                      style={{
                        flexDirection: "row",
                        display: "flex",
                        marginTop: 12,
                      }}
                    >
                      <div style={{ fontSize: 14, color: "#666666" }}>
                        拍摄时长：
                      </div>
                      <div style={{ fontSize: 14, color: "#000000" }}>
                        {(data.shop_snapshot.take_duration * 1.0) / 60 + "分钟"}
                      </div>
                    </div>
                    <div></div>
                  </div>
                  <div style={{ marginLeft: 120 }}>
                    <div
                      style={{
                        flexDirection: "row",
                        display: "flex",
                        marginTop: 14,
                      }}
                    >
                      <div style={{ fontSize: 14, color: "#666666" }}>
                        视频剪辑数量：
                      </div>
                      <div style={{ fontSize: 14, color: "#000000" }}>
                        {data.shop_snapshot.cut_number + "条"}
                      </div>
                    </div>
                    <div></div>
                    <div
                      style={{
                        flexDirection: "row",
                        display: "flex",
                        marginTop: 12,
                      }}
                    >
                      <div style={{ fontSize: 14, color: "#666666" }}>
                        票价：{" "}
                      </div>
                      <div style={{ fontSize: 14, color: "#000000" }}>
                        {"¥" + data.shop_snapshot.ticket_price}
                      </div>
                    </div>
                    <div></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              height: select_templates.length > 10 ? 240 : 220,
              backgroundColor: "#FFF",
              width: "100%",
              marginTop: 16,
              overflowX: select_templates.length > 10 ? "scroll" : "hidden",
            }}
          >
            <div
              style={{
                fontSize: 18,
                color: "#000000",
                width: 100,
                marginLeft: 32,
                marginTop: 17,
                paddingTop: 8,
              }}
            >
              已选模版
            </div>
            <div
              style={{
                width: "100%",
                marginTop: 0,
                flexDirection: "row",
                display: "flex",
              }}
            >
              {select_templates.map((item: any) => {
                return (
                  <div style={{ marginTop: 10, marginLeft: 30 }}>
                    <img
                      src={item.cover.url}
                      alt={""}
                      style={{ width: 88, height: 88, marginLeft: 6 }}
                    />
                    <div style={{ textAlign: "center", width: 100 }}>
                      {item.title}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div
            style={{
              borderRadius: 5,
              backgroundColor: "#FFF",
              width: "100%",
              height: 240,
              marginTop: 16,
            }}
          >
            <div style={{ paddingTop: 24, marginLeft: 32, fontSize: 18 }}>
              订单信息
            </div>
            <div
              style={{ flexDirection: "row", display: "flex", marginTop: 10 }}
            >
              <div style={{ marginLeft: 32, fontSize: 14, color: "#666666" }}>
                订单号：
              </div>
              <div style={{ fontSize: 14, color: "#000000" }}>
                {data.order_code}
              </div>
              <div style={{ marginLeft: 40, fontSize: 14, color: "#666666" }}>
                当前订单状态：
              </div>
              <div style={{ fontSize: 14, color: "#000000" }}>
                {OrderStatus[data.status]}
              </div>
            </div>
            <div
              style={{
                flexDirection: "row",
                display: "flex",
                width: "100%",
                height: 112,
                marginTop: 10,
                paddingLeft: 32,
                paddingRight: 32,
              }}
            >
              {orderInfo.map((item: any) => {
                return (
                  <div className="tabel">
                    <div className="tabelCell drColor">{item.label}</div>
                    <div className="tabelCell"> {item.value} </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div
            style={{
              borderRadius: 5,
              backgroundColor: "#FFF",
              width: "100%",
              height: 200,
              marginTop: 16,
            }}
          >
            <div style={{ paddingTop: 24, marginLeft: 32, fontSize: 18 }}>
              增值服务
            </div>
            <div
              style={{
                flexDirection: "row",
                display: "flex",
                width: "100%",
                height: 112,
                marginTop: 10,
                paddingLeft: 32,
                paddingRight: 32,
              }}
            >
              {serverInfo.map((item: any) => {
                return (
                  <div className="tabel">
                    <div className="tabelCell drColor">{item.label}</div>
                    <div className="tabelCell"> {item.value} </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div
            style={{
              borderRadius: 5,
              backgroundColor: "#FFF",
              width: "100%",
              height: 200,
              marginTop: 16,
            }}
          >
            <div style={{ paddingTop: 24, marginLeft: 32, fontSize: 18 }}>
              订单退款
            </div>
            <div
              style={{
                flexDirection: "row",
                display: "flex",
                width: "100%",
                height: 112,
                marginTop: 10,
                paddingLeft: 32,
                paddingRight: 32,
              }}
            >
              {refundInfo.map((item: any) => {
                return (
                  <div className="tabel">
                    <div className="tabelCell drColor">{item.label}</div>
                    <div className="tabelCell"> {item.value} </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div
            style={{
              borderRadius: 5,
              backgroundColor: "#FFF",
              width: "100%",
              height: 200,
              marginTop: 16,
            }}
          >
            <div style={{ paddingTop: 24, marginLeft: 32, fontSize: 18 }}>
              增值服务退款
            </div>
            <div
              style={{
                flexDirection: "row",
                display: "flex",
                width: "100%",
                height: 112,
                marginTop: 10,
                paddingLeft: 32,
                paddingRight: 32,
              }}
            >
              {refundServer.map((item: any) => {
                return (
                  <div className="tabel">
                    <div className="tabelCell drColor">{item.label}</div>
                    <div className="tabelCell"> {item.value} </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div
            style={{
              backgroundColor: "#FFF",
              width: "100%",
              height: 150,
              marginTop: 16,
              overflowX: edited_video.length > 4 ? "scroll" : "hidden",
            }}
          >
            <div style={{ paddingTop: 15, marginLeft: 36, fontSize: 18 }}>
              视频
            </div>
            <div style={{ flexDirection: "row", display: "flex" }}>
              {edited_video.map((item: any, index: number) => {
                return (
                  <div
                    style={{
                      width: 330,
                      height: 40,
                      borderWidth: 1,
                      borderColor: "#333",
                      borderStyle: "outset",
                      alignItems: "center",
                      marginTop: 24,
                      marginLeft: 24,
                    }}
                  >
                    <div
                      style={{
                        width: 330,
                        height: 40,
                        textAlign: "center",
                        paddingTop: 8,
                      }}
                    >
                      {item.originname.length > 20
                        ? item.originname.substring(0, 20) + "..."
                        : item.originname}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
         
        </div>
      )}
      {current === "2" && (<div>
        <div style={{ backgroundColor: "#FFF", paddingBottom:20 }}>
          <div className="rowCss">
            <div className="rowCss" style={{ flex: 1 }}>
              <div>{'订单号：'+data.order_code}</div>
            </div>
            <div className="rowCss" style={{ flex: 1 }}>
              <div>{data.complete_time ?`订单完成时间：${data.complete_time}`:'订单完成时间：'}</div>
            </div>
            <div className="rowCss" style={{ flex: 1 }}>
              <div>{data.generate_code ?`口令：${data.generate_code}`:'口令：'}</div>
            </div>
            <div className="rowCss" style={{ flex: 1 }}>
              <div>{data.shop_snapshot &&data.shop_snapshot.rule_name? `规则名称：${data.shop_snapshot.rule_name}`:'规则名称：'  }</div>
            </div>
          </div>

          <div className="rowCss">
            <div className="rowCss " style={{ flex: 1 }}>
              <div>{'订单实付总金额：¥'+ totalAmount(data) }</div>

             
            </div>
            <div className="rowCss centerCss" style={{ flex: 1 }}>
              <div>{'分账总额：¥'+data.profit_amount/100}</div>
            </div>
            <div className="rowCss centerCss" style={{ flex: 1 }}>
              <div>{'订单结余：¥'+data.profit_surplus/100}</div>
            </div>
            <div style={{flex:1}}/>
          </div>
        </div>

        <div
            style={{
              borderRadius: 5,
              backgroundColor: "#FFF",
              width: "100%",
              marginTop: 16,
            }}
          >
            <div style={{ paddingTop: 24, marginLeft: 32, fontSize: 18 }}>
              系统记账信息
            </div>
            <div
              style={{
                flexDirection: 'column',
                display: "flex",
                width: "100%",
                marginTop: 10,
                paddingLeft: 32,
                paddingRight: 32,
                paddingBottom:20
              }}
            >
              {menuOrderInfo.map((item: any,index:number) => {
                return (
                  <div  style={{   flex: 1,
                    width:'100%',
                    display: 'flex',    flexDirection: 'column',}}>
                    <div style={{flexDirection:'row',display:'flex'}}>
                    <div className="tabelCell" style={{backgroundColor:index===0 ?'#F5F6F8':'#FFF',height:56}} >{item.count}</div>
                    <div className="tabelCell" style={{backgroundColor:index===0 ?'#F5F6F8':'#FFF',height:56}}>{item.number}</div>
                    <div className="tabelCell" style={{backgroundColor:index===0 ?'#F5F6F8':'#FFF',height:56}}>{item.math}</div>
                    <div className="tabelCell" style={{backgroundColor:index===0 ?'#F5F6F8':'#FFF',height:56}}>{item.amount}</div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          <div
            style={{
              borderRadius: 5,
              backgroundColor: "#FFF",
              width: "100%",
              marginTop: 16,
            }}
          >
            <div style={{ paddingTop: 24, marginLeft: 32, fontSize: 18 }}>
              微信分账信息
            </div>
            <div
              style={{ flexDirection: "row", display: "flex", marginTop: 10 }}
            >
              <div style={{ marginLeft: 32, fontSize: 14, color: "#666666" }}>
                应分账金额：
              </div>
              <div style={{ fontSize: 14, color: "#000000" }}>
                {data.profitsharing_amount ?`¥${data.profitsharing_amount/100}`:''}
              </div>
              <div style={{ marginLeft: 32, fontSize: 14, color: "#666666" }}>
                已分账金额：
              </div>
              <div style={{ fontSize: 14, color: "#000000" }}>
                {data.profitsharings? handleAmount(data.profitsharings):''}
              </div>
            </div>
<div
              style={{
                flexDirection: 'column',
                display: "flex",
                width: "100%",
                marginTop: 10,
                paddingLeft: 32,
                paddingRight: 32,
                paddingBottom:20
              }}
            >
              {divideOrder.map((item: any,index:number) => {
                return (
                  <div  style={{   flex: 1,
                    width:'100%',
                    display: 'flex',    flexDirection: 'column',}}>
                    <div style={{flexDirection:'row',display:'flex'}}>
                    <div className="tabelCell" style={{backgroundColor:index===0 ?'#F5F6F8':'#FFF',height:56}} >{item.count}</div>
                    <div className="tabelCell" style={{backgroundColor:index===0 ?'#F5F6F8':'#FFF',height:56}} >{item.number}</div>
                    <div className="tabelCell" style={{backgroundColor:index===0 ?'#F5F6F8':'#FFF',height:56}} >{item.radio}</div>
                    <div className="tabelCell" style={{backgroundColor:index===0 ?'#F5F6F8':'#FFF',height:56}} >{item.type}</div>
                    <div className="tabelCell" style={{backgroundColor:index===0 ?'#F5F6F8':'#FFF',height:56}} >{item.amount1}</div>
                    <div className="tabelCell" style={{backgroundColor:index===0 ?'#F5F6F8':'#FFF',height:56}}>{item.amount2}</div>
                    <div className="tabelCell" style={{backgroundColor:index===0 ?'#F5F6F8':'#FFF',height:56}}>{item.status}</div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
      </div>
     
      )}
   
    </div>
  );
};
export default OrderDetail;
