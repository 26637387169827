import React, { useEffect, useState } from 'react'
import { Modal,  Upload, Button as AButton, Progress } from "antd";
import Button from '../../../components/button'
import { toastErrorMessage, toastSuccessMessage,toastMessage } from "../../../utils";
import '../../../globalLess/global.css'
import type { UploadFile, UploadProps } from 'antd/es/upload/interface';
import { UploadVideos,getOrderDetail,uploadVideo,presign } from '../../../service/index'
interface UploadVideoProps {
    closeClick: () => void,
    saveClick: () => void,
    order_id?:string, 
}


const ChangeVideo = (props: UploadVideoProps) => {
    const { saveClick } = props;
    const { closeClick } = props;
    const { order_id } = props;
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const [fileIDS, setFileIDS] = useState<string[]>([]);
    const [percent, setPercent] = useState(0)
    const [noticeTitle, setNoticeTitle] = useState('')
    const props2: UploadProps = {
        onRemove: (file) => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
            const newFileIDS = fileIDS.slice();
            newFileIDS.splice(index, 1);
            setFileIDS(newFileIDS)
        },
        beforeUpload: async (file) => {
            if(fileList.length === 3){
                toastErrorMessage('最多可上传3个视频，请删除后重试')
                return
            }
            if(file.size > 1024*1024*300){
                toastMessage('单个视频文件最大不可以超过300MB')
                return
            }
            const newFileList = fileList.concat(file);
            setFileList(newFileList);
            setPercent(10)
            setNoticeTitle('上传中')
            uploadFile([file])
            return false
        },
        fileList,
    };
    const uploadFile = (file: any) => {
        let dict = {
            size: file[0].size,
            originname: file[0].name,
            mimetype: file[0].type
        }
        setPercent(20)
        presign(dict).then(res => {
            if (res.code === 200) {
                   const newFileIDs = fileIDS.concat(res.data.id);
                setFileIDS(newFileIDs)
                upload_file(res.datapresign_url.url, res.data.presign_url.fields,file[0])
            }
        }).catch(err => {
            setTimeout(()=>{
                setPercent(0)
            },2000)
            setNoticeTitle('上传失败,请稍后重试')
        })
    }
    const upload_file = (url: string, data: any, file: any) =>{
        const formData: any = new FormData()
        Object.keys(data).forEach(key => {
            formData.append(key, data[key])
        })
        formData.append('file', file)
        uploadVideo(url,formData).then(res=>{
            console.log('resresres=>',res);
            setPercent(99)
            setTimeout(()=>{
                setPercent(100)
            },2000)
            setNoticeTitle('上传成功')
        }).catch(err=>{
            console.log('errrrr=>',err);
            setTimeout(()=>{
                setPercent(0)
            },2000)
            setNoticeTitle('上传失败,请稍后重试')
        })
    }
    const uploadVideos = ()=>{
        if(fileIDS.length===0){
            toastErrorMessage('至少选择一个视频')
            return
        }
        UploadVideos({order_id:order_id,file_ids:fileIDS }).then(res=>{
            if(res.code=== 200){
                toastSuccessMessage('视频更换成功')
                setTimeout(()=>{
                    saveClick()
                },500)
            }
        }).catch(err=>{

        })
    }

    const orderDetail = (id: string) => {
        let dict = {
            id
        }
        getOrderDetail(dict).then((res: any) => {
            if (res.code === 200) {
                if(res.data.original_videos.length>0){
                    let videoData = []
                    let ids_arr=[]
                    for(let index=0;index<res.data.original_videos.length;index++){
                        let item = res.data.original_videos[index]
                        item.name = item.originname
                        videoData.push(item)
                        ids_arr.push(item.id)
                    }
                    setFileList(videoData)
                    setFileIDS(ids_arr)
                   
                }
            }
        }).catch((err: any) => {

        })
    }
    useEffect(() => {
        if(order_id){
            orderDetail(order_id)
        }
    }, [])
    return (
        <Modal
            style={{ background: "transparent", borderRadius: 5, }}
            closeIcon={
                <img
                    alt=''
                    src={require('../../../assets/icon_close.png')}
                    onClick={() => closeClick()}
                />
            }
            title={'更换视频'}
            width={632}
            open={true}
            footer={null}
            centered
        >
            <div className='inputStyle' style={{ marginTop: 0 }}
            >
                <div
                    className='inputTitle' style={{ fontSize: 16, marginRight: -10, width: 110, marginTop: -190 }}
                >文件：</div>
                <div style={{ width: 530, height: 220 }}>
                    <Upload {...props2} maxCount={3} >
                        <AButton style={{ width: 530 }}>上传本地文件</AButton>
                    </Upload>
                </div>

            </div>
            {
               ((percent===0) || (percent===100)) ? <div style={{ width: '100%', height: 75, alignItems: 'center' }}>
               </div>
               :
               <div style={{ width: '100%', height: 75, backgroundColor: '#F3F4F5', alignItems: 'center' }}>
               <div style={{ paddingTop: 15, fontSize: 14, textAlign: 'center' }}> {noticeTitle}</div>
                   <div style={{ width: '90%', marginLeft: 28 }}>
                   <Progress strokeColor='#FB186A' percent={percent} showInfo={false} />
               </div>
           </div>
            }
            <div style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 20
            }}>
                <Button title='取消' titleStyle={{ color: '#333' }} buttonStyle={{ width: 100, backgroundColor: '#FFF', marginRight: 10, borderWidth: 1, borderColor: '#333', borderStyle: 'solid' }} onClick={() => {
                    closeClick()
                }} />
                <Button title='确定' buttonStyle={{ width: 100, backgroundColor: '#FB186A ' }} onClick={() => {
                    uploadVideos()

                }} />
            </div>
        </Modal>
    )
}
export default ChangeVideo