import React, { useEffect, useState, } from "react";
import { Input, Button, Menu, Dropdown, InputNumber, Spin } from 'antd';
import '../../../globalLess/global.css'
import '../../../components/Layout/index.less';
import { toastMessage } from "../../../utils";
import { getShopList, CreatCoupon, getCouponDetail } from "../../../service";

import moment from "moment";
const ExportJsonExcel = require('js-export-excel')
const CreatCode = (): React.ReactElement => {
    const [menuData, setMenuData] = useState<any>([])
    const [couponMenuData] = useState([{ key: '0', label: '优惠券码' }, { key: '1', label: '门票兑换码' }])
    const [shop, setShop] = useState('请选择')
    const [shopId, setShopId] = useState([])
    const [coupon_type, setCoupon_type] = useState(0)
    const [coupon_typeString, setCoupon_typeString] = useState('请选择')
    const [discount_type, setDiscount_type] = useState(0)
    const [discount_ratio, setDdiscount_ratio] = useState(undefined)
    const [deduction_amount, setDeduction_amount] = useState(undefined)
    const [validity_period_type, setValidity_period_type] = useState(0)
    const [validity_period_days, setValidity_period_days] = useState(undefined)
    const [quantity, setQuantity] = useState(undefined)
    const [specify_name, setSpecify_name] = useState('')
    const [specify_phone, setSpecify_phone] = useState('')
    const [specify_company, setSpecify_company] = useState('')
    const [coupon_record_id, setCoupon_record_id] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [data, setData] = useState<any>({
        sex: 0,
        name: '',
        phone: '',
        user: {
            name: '',
            wx_name: '',
            phone: ''
        },
        template_snapshot: {
            cover_url: '',
            title: '',
            desc: '',
            price: ''
        }
    });
    const [step, setStep] = useState('1');

    const handleCouponMenuClick = (e: any) => {
        couponMenuData.map((item: any) => {
            if (item.key === e.key) {
                setCoupon_type(parseInt(item.key))
                setCoupon_typeString(item.label)
            }
        })
    }

    const couponMenu = (
        <Menu onClick={handleCouponMenuClick}>
            {couponMenuData.map((item: any, index: any) => {
                return <Menu.Item key={`${index}`}>
                    {item.label}
                </Menu.Item>
            })}
        </Menu>
    );


    const handleMenuClick = (e: any) => {
    
    }

    const menu = (
        <Menu onClick={handleMenuClick}>
            {menuData.map((item: any, index: any) => {
                return <div key={`${index}`}  style={{ display: 'flex', flexDirection: 'row',height:40 }}    onClick={() => {
                    const newList = JSON.parse(JSON.stringify(menuData))
                    const item = newList[index]
                    item.select = !item.select
                    newList[index] = item
                    let isdArray:any = []
                    let names = ''
                    newList.map((item: any,index:number) => {
                        if (item.select) {
                            if(names===''){
                                names= item.label
                            }else{
                                names += ','+ item.label
                            }
                            isdArray.push(item.id)
                        }
                    })
                    setMenuData(newList)
                    if(JSON.stringify(isdArray) === '[]'){
                        setShop('请选择')
                    }else{
                        setShop(names)
                    }
                    setShopId(isdArray)
                }}>
                    <img style={{ marginLeft: 10, marginTop: 8, width: 16, height: 16, zIndex: 20, cursor: 'pointer' }}
                        src={  item.select? require('../../../assets/icon_selectIcon.png'): require('../../../assets/icon_unSelect.png')}
                        alt=""
                    />
                    <div  style={{backgroundColor:'#FFF',marginTop:5, marginLeft: 5,}} >
                        {item.label}
                    </div>
                </div>
            })}
        </Menu>
    );

    const getShopListData = () => {
        getShopList({ page_num: 1, page_size: 1000 }).then(response => {
            if (response.code === 200) {
                if (response.data.items.length > 0) {
                    let List: any = []
                    for (let index = 0; index < response.data.items.length; index++) {
                        const item = response.data.items[index]
                        List.push({
                            id: item.id,
                            label: item.name,
                            key: index + '',
                            select: false,
                        })
                    }
                    setMenuData(List)
                }
            }
        }).catch(err => {

        })
    }

    const onFinish = () => {
        let dict: any = {
            shop_ids: shopId,
            coupon_type,
            discount_type,
            validity_period_type,
            quantity,
            specify_name,
            specify_phone,
            specify_company
        }
        if (coupon_type === 0) {
            if (discount_type === 0) {
                if (discount_ratio) {
                    dict.discount_ratio =  (1- discount_ratio * 1.0 / 10)
                }
            } else if (discount_type === 1) {
                dict.deduction_amount = deduction_amount
            }
        }

        if (validity_period_type === 1) {
            dict.validity_period_days = validity_period_days
        }
        setIsLoading(true)
        CreatCoupon(dict).then(res => {
            if (res.code === 200) {
                setCoupon_record_id(res.data.coupon_record_id)
                setStep('3')
            } else {
                toastMessage(res.msg)
            }
            setIsLoading(false)
        }).catch(err => {
            setIsLoading(false)
        })
    }

    const downLoadCoupon = (id: string) => {
        getCouponDetail({ id }).then(res => {
            if (res.code === 200) {
                if (res.data.coupons.length > 0) {
                    let option: { fileName?: string, datas?: any[] } = {};
                    let dataTable: any = [];

                    let names=''
                    res.data.shops.map((item: any,index:number) => {
                            if(names===''){
                                names= item.name
                            }else{
                                names += ','+ item.name
                            }
                    })
                    let type =0
                    res.data.coupons.forEach((item: any) => {
                        type=res.data.type
                        if(res.data.type === 1){
                            dataTable.push({
                                shop: names,
                                type: '门票兑换码',
                                time: `${moment(item.validity_period_start_time).format('YYYY/MM/DD')}-${moment(item.validity_period_end_time).format('YYYY/MM/DD')}`,
                                coupon_id: item.coupon_id,
                                code: item.code,
                            })
                        }else{
                            dataTable.push({
                                shop: names,
                                type: '优惠券码',
                                discount_type: item.discount_type === 0 ? `${((1-item.discount_ratio)*10).toFixed(1)}折` : `减${item.deduction_amount}`,
                                time: `${moment(item.validity_period_start_time).format('YYYY/MM/DD')}-${moment(item.validity_period_end_time).format('YYYY/MM/DD')}`,
                                coupon_id: item.coupon_id,
                                code: item.code,
                            })
                        }
                        
                    })
                    option.fileName = '兑换码' + moment(res.data.create_time).format('YYYYMMDD')
                    if(type=== 1){
                        option.datas = [
                            {
                                sheetData: dataTable,
                                sheetName: 'sheet',
                                sheetFilter: ['shop', 'type',  'time', 'coupon_id', 'code'],
                                sheetHeader: ['适用门店', '兑换码类型', '兑换码有效期', '兑换码Id', '兑换码代码'],
                            }
                        ];
                    }else{
                        option.datas = [
                            {
                                sheetData: dataTable,
                                sheetName: 'sheet',
                                sheetFilter: ['shop', 'type', 'discount_type', 'time', 'coupon_id', 'code'],
                                sheetHeader: ['适用门店', '兑换码类型', '优惠方式', '兑换码有效期', '兑换码Id', '兑换码代码'],
                            }
                        ];
                    }
                    let toExcel = new ExportJsonExcel(option);
                    toExcel.saveExcel();
                    window.history.go(-1)
                }
            }
        }).catch(err => {

        })
    }
    useEffect(() => {
        getShopListData()
    }, [])

    return (
        <Spin spinning={isLoading}>
            <div style={{ height: '100%', width: '100%', paddingBottom: 30 }}>
                <div style={{ backgroundColor: '#FFF', width: '100%', height: 130, paddingTop: 30, borderRadius: 5 }}>
                    <div style={{ width: '100%', height: 60, flexDirection: 'row', display: 'flex' }} >
                        <div style={{ width: '22%', height: 1, display: 'flex' }} />
                        <div style={{ width: '27%', height: 60, marginRight: -30, flexDirection: 'row', display: 'flex' }} >
                            <img style={{ marginRight: 5, width: 32, height: 32, marginTop: 8 }}
                                src={(step === '1') ? require('../../../assets/icon_unStep1.png') : require('../../../assets/icon_stepOK.png')}
                                alt=""
                            />
                            <div style={{ marginTop: 12, fontSize: 16, color: '#1E1E1E' }}>券码信息 </div>
                            <div style={{ backgroundColor: (step === '1') ? '#999999' : '#FB186A', width: '65%', height: 1, marginTop: 25, marginLeft: 10 }} />

                        </div>
                        <div style={{ width: '27%', height: 60, flexDirection: 'row', display: 'flex' }} >
                            <img style={{ marginRight: 5, width: 32, height: 32, marginTop: 8 }}
                                src={(step === '2') ? require('../../../assets/icon_step2.png') : (step === '3') ? require('../../../assets/icon_stepOK.png') : require('../../../assets/icon_unStep2.png')}
                                alt=""
                            />
                            <div style={{ marginTop: 12, fontSize: 16, color: '#1E1E1E' }}>发放对象信息 </div>
                            <div style={{ backgroundColor: (step === '3') ? '#FB186A' : '#999999', width: '65%', height: 1, marginTop: 25, marginLeft: 10 }} />

                        </div>
                        <div style={{ width: '19%', height: 60, flexDirection: 'row', display: 'flex' }} >
                            <img style={{ marginRight: 5, width: 32, height: 32, marginTop: 8 }}
                                src={(step === '3') ? require('../../../assets/icon_stepOK.png') : require('../../../assets/icon_unStep3.png')}
                                alt=""
                            />
                            <div style={{ fontSize: 16, marginTop: 12, color: '#1E1E1E' }}>完成 </div>
                        </div>
                    </div>
                    <div style={{ width: '100%', height: 60, flexDirection: 'row', display: 'flex', marginTop: -10 }} >
                        <div style={{ width: '27%', height: 60, display: 'flex' }} ></div>
                        <div style={{ width: '27%', textAlign: 'left', fontSize: 18, paddingLeft: 65 }}>{data.verify_time ? moment(data.verify_time).format('YYYY-MM-DD HH:mm') : ''}</div>

                        <div style={{ width: '27%', textAlign: 'left', fontSize: 18, paddingLeft: 65 }}>{data.pay && data.pay.success_time
                            ? moment(data.pay.success_time).format('YYYY-MM-DD HH:mm') : ''}</div>
                        <div style={{ width: '19%', height: 60, display: 'flex' }} ></div>
                    </div>
                </div>
                {
                    (step === '1') &&
                    <div style={{ backgroundColor: '#FFF', width: '100%', height: 850, marginTop: 16, alignItems: 'center' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', height: 425, marginLeft: 130, alignItems: 'center' }}>
                            <div style={{ marginTop: 50, flexDirection: 'row', height: 80, display: 'flex', backgroundColor: '#FFF', }}>
                                <div className='inputTitle' style={{ fontSize: 14, marginLeft: 0, marginRight: -5, marginTop: 10, width: 100, color: '#666666', }}>适用门店：</div>
                                <Dropdown overlay={menu} >
                                    <Button style={{ marginLeft: 0, width: 400, marginTop: 12, flexDirection: 'row', display: 'flex', }}>
                                        {shop.length<25? shop:shop.substring(0,24)+'...'}
                                        <div style={{ flex: 1, display: 'flex' }} />
                                        <img style={{ marginRight: 0, width: 30, height: 30, marginTop: -3 }}
                                            src={require('../../../assets/icon_expanded.png')}
                                            alt=""
                                        />
                                    </Button>
                                </Dropdown>
                            </div>
                            <div style={{ marginTop: 0, flexDirection: 'row', display: 'flex', backgroundColor: '#FFF', }}>
                                <div className='inputTitle' style={{ fontSize: 14, marginLeft: 0, marginRight: -5, width: 100, color: '#666666', marginTop: 10, }}>兑换码类型：</div>
                                <Dropdown overlay={couponMenu} >
                                    <Button style={{ marginLeft: 0, width: 400, marginTop: 15, flexDirection: 'row', display: 'flex', }}>
                                        {coupon_typeString}
                                        <div style={{ flex: 1, display: 'flex' }} />
                                        <img style={{ marginRight: 0, width: 30, height: 30, marginTop: -3 }}
                                            src={require('../../../assets/icon_expanded.png')}
                                            alt=""
                                        />
                                    </Button>
                                </Dropdown>
                            </div>

                            {
                                (coupon_type === 0) && <div style={{ flexDirection: 'column', display: 'flex' }}>
                                    <div style={{ flexDirection: 'row', display: 'flex', marginTop: 20, }}>
                                        <div className='inputTitle' style={{ fontSize: 14, marginLeft: -126, marginRight: -5, width: 100, color: '#666666', marginTop: 0, }}>优惠方式：</div>
                                        <img style={{ marginLeft: 0, marginTop: 8, width: 16, height: 16, zIndex: 20, cursor: 'pointer' }}
                                            src={(discount_type === 0) ? require('../../../assets/icon_selectIcon.png') : require('../../../assets/icon_unSelect.png')}
                                            alt=""
                                            onClick={() => {
                                                setDiscount_type(0)
                                            }}
                                        />
                                        <div className='inputTitle' style={{ fontSize: 14, marginLeft: -15, marginRight: 0, color: '#666666', marginTop: 0, }}>折扣 </div>
                                        <div className='inputTitle' style={{ fontSize: 14, marginLeft: -10, marginRight: -15, color: '#666666', marginTop: 0, }}>打 </div>
                                        <InputNumber
                                            placeholder="请输入"
                                            precision={1}
                                            max={10}
                                            min={0}
                                            value={discount_ratio}
                                            className='textInputStr'
                                            style={{ width: 88 }}
                                            onChange={(e: any) => {
                                                setDdiscount_ratio(e)
                                            }}
                                        />
                                         <div className='inputTitle' style={{ fontSize: 14, marginLeft: -20, color: '#666666', marginTop: 0, }}>折 </div>
                                    </div>

                                    <div style={{ flexDirection: 'row', display: 'flex', marginTop: 8, }}>
                                        <img style={{ marginLeft: -30, marginTop: 8, width: 16, height: 16, zIndex: 20, cursor: 'pointer' }}
                                            src={(discount_type === 1) ? require('../../../assets/icon_selectIcon.png') : require('../../../assets/icon_unSelect.png')}
                                            alt=""
                                            onClick={() => {
                                                setDiscount_type(1)
                                            }}
                                        />
                                        <div className='inputTitle' style={{ fontSize: 14, marginLeft: -15, marginRight: 0, color: '#666666', marginTop: 0, }}>抵扣 </div>
                                        <div className='inputTitle' style={{ fontSize: 14, marginLeft: -10, marginRight: -15, color: '#666666', marginTop: 0, }}>减 </div>
                                        <InputNumber
                                            placeholder="请输入"
                                            precision={2}
                                            value={deduction_amount}
                                            className='textInputStr'
                                            style={{ width: 88 }}
                                            onChange={(e: any) => {
                                                setDeduction_amount(e)
                                            }}
                                        />
                                    </div>
                                </div>
                            }


                            <div style={{ flexDirection: 'column', display: 'flex' }}>
                                <div style={{ flexDirection: 'row', display: 'flex', marginTop: 20, }}>
                                    <div className='inputTitle' style={{ fontSize: 14, marginLeft: -102, marginRight: 10, width: 100, color: '#666666', marginTop: 0, }}>兑换码有效期：</div>
                                    <img style={{ marginLeft: 0, marginTop: 4, width: 16, height: 16, zIndex: 20, cursor: 'pointer' }}
                                        src={(validity_period_type === 0) ? require('../../../assets/icon_selectIcon.png') : require('../../../assets/icon_unSelect.png')}
                                        alt=""
                                        onClick={() => {
                                            setValidity_period_type(0)
                                        }}
                                    />
                                    <div className='inputTitle' style={{ fontSize: 14, marginLeft: -15, width: 100, marginRight: 0, color: '#666666', marginTop: 0, }}>永久有效 </div>

                                </div>

                                <div style={{ flexDirection: 'row', display: 'flex', marginTop: 8, }}>
                                    <img style={{ marginLeft: 8, marginTop: 8, width: 16, height: 16, zIndex: 20, cursor: 'pointer' }}
                                        src={(validity_period_type === 1) ? require('../../../assets/icon_selectIcon.png') : require('../../../assets/icon_unSelect.png')}
                                        alt=""
                                        onClick={() => {
                                            setValidity_period_type(1)
                                        }}
                                    />
                                    <div className='inputTitle' style={{ fontSize: 14, width: 100, marginLeft: -15, marginRight: 0, color: '#666666', marginTop: 0, }}>固定时间 </div>

                                    <InputNumber
                                        className='textInputStr'
                                        placeholder="请输入大于0的整数"
                                        addonAfter="天"
                                        style={{ width: 200 }}
                                        min={1}
                                        value={validity_period_days}
                                        precision={0}
                                        onChange={(e: any) => {
                                            setValidity_period_days(e)
                                        }}
                                    />
                                </div>
                                <div className='inputTitle' style={{ fontSize: 14, width: 300, marginLeft: -120, marginRight: 0, color: '#666666', marginTop: 0, }}>（兑换码自用户兑换起生效）</div>
                            </div>

                            <div className='inputStyle' style={{ marginTop: 30 }}
                            >
                                <div
                                    className='inputTitle' style={{ fontSize: 14, marginRight: -5, width: 100, color: '#666666', }}
                                >生成数量：</div>
                                <InputNumber
                                    className='textInputStr'
                                    placeholder="请输入"
                                    addonAfter="个"
                                    style={{ width: 400 }}
                                    min={1}
                                    value={quantity}
                                    precision={0}
                                    onChange={(e: any) => {
                                        setQuantity(e)
                                    }}
                                />
                            </div>


                            <div style={{ display: 'flex', flexDirection: 'row', marginTop: 30, }}>
                                <div className='button' style={{ zIndex: 20, borderStyle: 'solid', marginLeft: 0, borderWidth: 1, borderColor: '#E5E5E5', marginTop: 45 }} onClick={() => {
                                    window.history.go(-1)
                                }}>
                                    <div className='buttonTitle' style={{ color: '#333333' }}>
                                        取消
                                    </div>
                                </div>
                                <div className='button' style={{ zIndex: 20, marginLeft: 20, backgroundColor: '#FB186A', marginTop: 45 }} onClick={() => {
                                    console.log('qwdqwd');
                                    if (shop === '请选择') {
                                        toastMessage('请选择门店')
                                        return
                                    }
                                    if (coupon_typeString === '请选择') {
                                        toastMessage('请选择兑换码类型')
                                        return
                                    }
                                    if(coupon_type === 0){
                                        if (discount_type === 0) {
                                            if (!discount_ratio) {
                                                toastMessage('请输入折扣')
                                                return
                                            }
                                        } else if (discount_type === 1) {
                                            if (!deduction_amount) {
                                                toastMessage('请输入抵扣金额')
                                                return
                                            }
                                        }
                                    }
                                   
                                    if (validity_period_type === 1) {
                                        if (!validity_period_days) {
                                            toastMessage('请输入兑换码有效期')
                                            return
                                        }
                                    }
                                    if (!quantity) {
                                        toastMessage('请输入生成数量')
                                        return
                                    }
                                    setStep('2')
                                }}>
                                    <div className='buttonTitle' style={{ color: '#FFF' }}>
                                        下一步
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {
                    (step === '2') &&
                    <div style={{ backgroundColor: '#FFF', width: '100%', height: 850, marginTop: 16, alignItems: 'center' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', height: 425, marginLeft: 130, alignItems: 'center' }}>
                            <div style={{ flexDirection: 'column', display: 'flex' }}>
                                <div style={{ flexDirection: 'row', display: 'flex', marginTop: 50, }}>
                                    <div className='inputTitle' style={{ fontSize: 14, marginLeft: -10, width: 150, marginRight: -15, color: '#666666', marginTop: 0, }}>发放对象姓名： </div>
                                    <Input
                                        placeholder="请输入"
                                        className='textInputStr'
                                        style={{ width: 400 }}
                                        maxLength={10}
                                        value={specify_name}
                                        onChange={(e: any) => {
                                            setSpecify_name(e.target.value)
                                        }}
                                    />
                                </div>
                                <div style={{ flexDirection: 'row', display: 'flex', marginTop: 10, }}>
                                    <div className='inputTitle' style={{ fontSize: 14, marginLeft: 10, width: 150, marginRight: -35, color: '#666666', marginTop: 0, }}>手机号： </div>
                                    <Input
                                        placeholder="请输入"
                                        className='textInputStr'
                                        type="tel"
                                        value={specify_phone}
                                        style={{ width: 400 }}
                                        onChange={(e: any) => {
                                            setSpecify_phone(e.target.value)
                                        }}
                                    />
                                </div>
                                <div style={{ flexDirection: 'row', display: 'flex', marginTop: 10, }}>

                                    <div className='inputTitle' style={{ fontSize: 14, marginLeft: 6, width: 150, marginRight: -30, color: '#666666', marginTop: 0, }}>所属公司： </div>
                                    <Input
                                        maxLength={20}
                                        placeholder="请输入"
                                        className='textInputStr'
                                        style={{ width: 400 }}
                                        value={specify_company}
                                        onChange={(e: any) => {
                                            setSpecify_company(e.target.value)
                                        }}
                                    />
                                </div>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', marginTop: 30, }}>
                                <div className='button' style={{ zIndex: 20, borderStyle: 'solid', marginLeft: 0, borderWidth: 1, borderColor: '#E5E5E5', marginTop: 45 }} onClick={() => {
                                    setStep('1')
                                }}>
                                    <div className='buttonTitle' style={{ color: '#333333' }}>
                                        上一步
                                    </div>
                                </div>
                                <div className='button' style={{ zIndex: 20, marginLeft: 20, backgroundColor: '#FB186A', marginTop: 45 }} onClick={() => {
                                    console.log('qwdqwd');
                                    if (!specify_name) {
                                        toastMessage('请输入发放对象姓名')
                                        return
                                    }
                                    if (!specify_phone) {
                                        toastMessage('请输入手机号')
                                        return
                                    }
                                    if (specify_phone.length !== 11) {
                                        toastMessage('请输入正确手机号')
                                        return
                                    }
                                    if (!specify_company) {
                                        toastMessage('请输入所属公司')
                                        return
                                    }
                                    onFinish()
                                }}>
                                    <div className='buttonTitle' style={{ color: '#FFF' }}>
                                        确认生成
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {
                    (step === '3') &&
                    <div style={{ backgroundColor: '#FFF', width: '100%', height: 850, marginTop: 16, alignItems: 'center' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', height: 425, marginLeft: 130, alignItems: 'center' }}>
                            <div style={{ flexDirection: 'column', display: 'flex', alignItems: 'center' }}>
                                <img style={{ width: 80, height: 80, marginTop: 220 }}
                                    src={require('../../../assets/icon_stepOK.png')}
                                    alt=""
                                />
                                <div style={{ fontSize: 24, color: '#000000', marginTop: 20, }}>生成成功</div>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', marginTop: 0, }}>
                                <div className='button' style={{ zIndex: 20, backgroundColor: '#FB186A', marginTop: 20 }} onClick={() => {
                                    downLoadCoupon(coupon_record_id)
                                }}>
                                    <div className='buttonTitle' style={{ color: '#FFF' }}>
                                        去下载
                                    </div>
                                </div>
                                <div className='button' style={{ zIndex: 20, borderStyle: 'solid', marginLeft: 20, borderWidth: 1, borderColor: '#E5E5E5', marginTop: 20 }} onClick={() => {
                                    window.history.go(-1)
                                }}>
                                    <div className='buttonTitle' style={{ color: '#333333' }}>
                                        取消
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </Spin>
    )
}
export default CreatCode
