import {extend} from 'umi-request';
import type {ResponseInterceptor} from 'umi-request';
import {message} from 'antd';
import {createHashHistory} from "history";

const errorInterceptors: ResponseInterceptor = async (response) => {
    if (response.status === 401) {
        message.error('登录已过期！').then(() => {
            //跳转登录页面
            let history = createHashHistory()
            history.push('/order')
            window.sessionStorage.setItem('token', 'null')
            window.sessionStorage.setItem('isLogin', 'null')
            window.location.reload()
        })
        return response
    }
    return response
};

/** 配置request请求时的参数 */
export const request = extend({
    responseType: 'json',
    requestType: "form",
    timeout: 20000,
    credentials: "include",
});
request.interceptors.request.use((url, options)=>{
    const token = window.sessionStorage.getItem('token')
    if(token){
        const headers = {'token':token}
        return {
            url,
            options: {...options, headers}
        }
    } else{
        return {
            url,
            options
        }
    }
   
});
request.interceptors.response.use(errorInterceptors);

//登录
export async function login(
    body?: {
        account: string,
        password: string
    },
    params?: {},
    options?: { [key: string]: any }
) {
    return request<any>('/api/auth/loginWeb', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

//更改名称
export async function editName(
    body?: {
        id?: string,
        name?: string
    },
    params?: {},
    options?: { [key: string]: any }
) {
    return request<any>('/api/user/editSelfName', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

//更改名称
export async function editPassword(
    body?: {
        id?: string,
        old_password?: string,
        new_password?: string
    },
    params?: {},
    options?: { [key: string]: any }
) {
    return request<any>('/api/user/editSelfPassword', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

//获取当前用户信息
export async function getCurrentUser(
    params?: API.listParams,
    options?: { [key: string]: any }
) {
    return request<API.listResponse>(`/api/user/getUserInfo`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

//更改个人信息
export async function getEditSelf(
    body?: {id?: string, name?: string,duty?: string,phone?: string},
    params?: {},
    options?: { [key: string]: any }
) {
    return request<any>('/api/user/editSelf', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

//修改密码
export async function getEditPassword(
    body?: {id?: string, old_password?: string,new_password?: string},
    params?: {},
    options?: { [key: string]: any }
) {
    return request<any>('/api/user/editSelfPassword', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

//获取用户列表
export async function getUserList(
    params?:{
        page_num?: number,
        page_size?: number,
        content?: string
    },
    options?: { [key: string]: any }
) {
    return request<any>(`/api/user/getUserList`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

//管理员是否启用用户
export async function editUserEnable(
    body?: {
        id?: string,
        enable?: number,
    },
    params?:{},
    options?: { [key: string]: any }
) {
    return request<any>(`/api/user/editUserEnable`, {
        method: 'post',
        params,
        data: body,
        ...(options || {}),
    })
}

//启用模版
export async function editModelEnable(
    body?: {
        id?: string,
        is_use?: number,
    },
    params?:{},
    options?: { [key: string]: any }
) {
    return request<any>(`/api/template/update`, {
        method: 'post',
        params,
        data: body,
        ...(options || {}),
    })
}

//启用门店
export async function editModelShop(
    body?: {
        id?: string,
        is_use?: number,
    },
    params?:{},
    options?: { [key: string]: any }
) {
    return request<any>(`/api/shop/update`, {
        method: 'post',
        params,
        data: body,
        ...(options || {}),
    })
}

//启用门店
export async function CreatShop(
    body?: {
        name?: string,
        adress?: string,
        start_time?: string,
        end_time?: string,
        iphone?: string,
        is_use?: number,
        ticket_name?: string,
        take_duration?: number,
        cut_number?: number,
        ticket_price?: string,
        template_number?: number
    },
    params?:{},
    options?: { [key: string]: any }
) {
    return request<any>(`/api/shop/create`, {
        method: 'post',
        params,
        requestType:'json',
        data: body,
        ...(options || {}),
    })
}

//编辑门店
export async function editShop(
    body?: {
        id?: string,
        name?: string,
        adress?: string,
        start_time?: string,
        end_time?: string,
        iphone?: string,
        is_use?: number,
        ticket_name?: string,
        take_duration?: number,
        cut_number?: number,
        ticket_price?: string,
        append_rules?: any,
        template_number?:number,
    },
    params?:{},
    options?: { [key: string]: any }
) {
    return request<any>(`/api/shop/update`, {
        method: 'post',
        params,
        requestType: 'json',
        data: body,
        ...(options || {}),
    })
}

//获取订单详情
export async function getShopDetail(
    params?:{
        id?: string,
    },
    options?: { [key: string]: any }
) {
    return request<any>(`/api/shop/detail`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

//删除用户
export async function deleteShop(
    body?: {
        id? : string,
    },
    params?:{},
    options?: { [key: string]: any }
) {
    return request<any>(`/api/shop/delete`, {
        method: 'post',
        params,
        data: body,
        ...(options || {}),
    })
}

//更新用户
export async function updateInfo(
    body?: {
        shop_preference? : string,
    },
    params?:{},
    options?: { [key: string]: any }
) {
    return request<any>(`/api/user/updateInfo`, {
        method: 'post',
        params,  
        data: body,
        ...(options || {}),
    })
}


//新增用户
export async function addUser(
    body?: {
        account? : string,
        password? : string,
        name? : string,
        duty? : string,
        phone? : string,
    },
    params?:{},
    options?: { [key: string]: any }
) {
    return request<any>(`/api/user/addUser`, {
        method: 'post',
        params,
        data: body,
        ...(options || {}),
    })
}
//编辑用户
export async function editUser(
    body?: {
        id? : string,
        password? : string,
        name? : string,
        duty? : string,
        phone? : string,
    },
    params?:{},
    options?: { [key: string]: any }
) {
    return request<any>(`/api/user/editUser`, {
        method: 'post',
        params,
        data: body,
        ...(options || {}),
    })
}
//删除用户
export async function deleteUserInfo(
    body?: {
        id? : string,
    },
    params?:{},
    options?: { [key: string]: any }
) {
    return request<any>(`/api/user/deleteUser`, {
        method: 'post',
        params,
        data: body,
        ...(options || {}),
    })
}

//新增商户
export async function addMerchant(
    body?: {
        account : string,
        password: string,
        merchant_type : string,
        merchant_code? : string,
        merchant_phone? : string,
        merchant_name : string,
        is_generate_code : boolean,
    },
    params?:{},
    options?: { [key: string]: any }
) {
    return request<any>(`/api/user/addMerchant`, {
        method: 'post',
        params,
        requestType: 'json',
        data: body,
        ...(options || {}),
    })
}

//编辑商户
export async function editMerchant(
    body?: {
        id: string,
        account : string,
        password: string,
        merchant_type : string,
        merchant_code? : string,
        merchant_phone? : string,
        merchant_name : string,
        is_generate_code : boolean,
    },
    params?:{},
    options?: { [key: string]: any }
) {
    return request<any>(`/api/user/editMerchant`, {
        method: 'post',
        params,
        requestType: 'json',
        data: body,
        ...(options || {}),
    })
}

//删除商户
export async function deleteMerchant(
    body?: {
        id : string,
    },
    params?:{},
    options?: { [key: string]: any }
) {
    return request<any>(`/api/user/deleteMerchant`, {
        method: 'post',
        params,
        requestType: 'json',
        data: body,
        ...(options || {}),
    })
}



//获取商户列表
export async function getMerchantList(
   
    params?:{ page_num?: number,
        page_size?: number,
        merchant_type: string,
        content?: string,},
    options?: { [key: string]: any }
) {
    return request<any>(`/api/user/getMerchantList`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}


//获取订单管理列表
export async function getOrderList(
    params?:{
        page_num?: number,
        page_size?: number,
        status?: number,
        shop_id?: string,
        start_date?: string,
        end_date?: string,
        order_code?: string,
    },
    options?: { [key: string]: any }
) {
    return request<any>(`/api/order/orderManagementList`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

//交易统计
export async function statistics(
    params?:{  date: string  },
    options?: { [key: string]: any }
) {
    return request<any>(`/api/order/statistics`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}


//获取订单详情
export async function getOrderDetail(
    params?:{
        id?: string,
    },
    options?: { [key: string]: any }
) {
    return request<any>(`/api/order/detail`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

//取消订单
export async function CancelReserve(
    body?: {
        order_id?: string,
    },
    params?:{},
    options?: { [key: string]: any }
) {
    return request<any>(`/api/order/managementCancel`, {
        method: 'post',
        params,
        data: body,
        ...(options || {}),
    })
}

//获取模版列表
export async function getModelList(
    body?: {
        page_num?: number,
        page_size?: number,
        keyword?: string,
    },
    params?:{},
    options?: { [key: string]: any }
) {
    return request<any>(`/api/template/list`, {
        method: 'post',
        params,
        data: body,
        ...(options || {}),
    })
}


//上传文件
export async function UploadFileFun(
    body?: {
        file?: any,
    },
    params?:{},
    options?: { [key: string]: any }
) {
    return request<any>(`/api/file/upload`, {
        method: 'post',
        params,
        timeout: 300000,
        data: body,
        ...(options || {}),
    })
}

//上传文件预签名
export async function presign(
    body?: {
        size?: number,
        originname?: string,
        mimetype?: string,
        unChanged?: number
    },
    params?:{},
    options?: { [key: string]: any }
) {
    return request<any>(`/api/file/presign`, {
        method: 'post',
        params,
        timeout: 300000,
        data: body,
        ...(options || {}),
    })
}

//上传文件预下载 
export async function uploadVideo(
    url: string,
    body?: any,
    params?:{},
    options?: { [key: string]: any }
) {
    return request<any>(url, {
        method: 'post',
        params,
        timeout: 300000,
        credentials: 'omit',
        headers:{"Content-Type": "multipart/form-data"},
        data: body,
        ...(options || {}),
    })
}


//上传富文本图片
export async function UploadFuFileFun(
    body?: {
        file?: any,
    },
    params?:{},
    options?: { [key: string]: any }
) {
    return request<any>(`/api/file/upload_unchange`, {
        method: 'post',
        params,
        timeout: 300000,
        data: body,
        ...(options || {}),
    })
}
//创建模版
export async function CreateModel(
    body?: {
        title?: string,
        desc?: string,
        is_use?: number,
        point_tag?: string,
        cover_id?: string,
        video_id?: string,
        cut_command?: string,
        template_command?: string,
        shop_id?: string,
        record_duration?: string,
        count_down?:number,
    },
    params?:{},
    options?: { [key: string]: any }
) {
    return request<any>(`/api/template/create`, {
        method: 'post',
        params,
        requestType:'form',
        data: body,
        ...(options || {}),
    })
}

//更新模版
export async function UpdateModel(
    body?: {
        id?: string,
        title?: string,
        desc?: string,
        is_use?: number,
        key_point?: string,
        cover_id?: string,
        video_id?: string,
        cut_command?: string,
        template_command?: string,
        shop_id?: string,
        record_duration?: string,
        count_down?: number
    },
    params?:{},
    options?: { [key: string]: any }
) {
    return request<any>(`/api/template/update`, {
        method: 'post',
        params,
        data: body,
        ...(options || {}),
    })
}

//上传大字符串
export async function UpdateLargeText(
    body?: {
        text?: string,
    },
    params?:{},
    options?: { [key: string]: any }
) {
    return request<any>(`/api/file/upload_large_text`, {
        method: 'post',
        params,
        timeout: 300000,
        data: body,
        ...(options || {}),
    })
}

//模版详情
export async function getModelDetail(
    body?: {
        id?: string,
    },
    params?:{},
    options?: { [key: string]: any }
) {
    return request<any>(`/api/template/detail`, {
        method: 'post',
        params,
        data: body,
        ...(options || {}),
    })
}


//删除模版
export async function removeModel(
    body?: {
        id?: string,

    },
    params?:{},
    options?: { [key: string]: any }
) {
    return request<any>(`/api/template/remove`, {
        method: 'post',
        params,
        data: body,
        ...(options || {}),
    })
}


//上传视频
export async function UploadVideos(
    body?: {
        order_id?: string,
        file_ids?: any 
    },
    params?:{},
    options?: { [key: string]: any }
) {
    return request<any>(`/api/order/uploadOriginalVideo`, {
        method: 'post',
        requestType: 'json',
        params,
        timeout: 300000,
        data: body,
        ...(options || {}),
    })
}


//上传剪辑视频
export async function UploadEditVideoFun(
    body?: {
        order_id?: string,
        file_ids?: any
    },
    params?:{},
    options?: { [key: string]: any }
) {
    return request<any>(`/api/order/uploadEditedVideo`, {
        method: 'post',
        params,
        requestType: 'json',
        timeout: 300000,
        data: body,
        ...(options || {}),
    })
}

//获取用户列表
export async function getShopList(
    params?:{
        page_num?: number,
        page_size?: number,
        keyword?: string
    },
    options?: { [key: string]: any }
) {
    return request<any>(`/api/shop/query_list`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

//优惠券发放记录列表
export async function getCouponList(
    params?:{
        page_num?: number,
        page_size?: number,
        coupon_type?: number,
        is_use?: number,
        start_date?: string,
        end_date?: string,
        name?: string,
    },
    options?: { [key: string]: any }
) {
    return request<any>(`/api/coupon/recordList`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

//优惠券发放记录详情
export async function getCouponDetail(
    params?:{
        id?: string,
    },
    options?: { [key: string]: any }
) {
    return request<any>(`/api/coupon/recordDetail`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

//创建优惠券
export async function CreatCoupon(
    body?: {
        shop_id?: string,
        coupon_type?: number,
        discount_type?: number,
        discount_ratio?: number,
        deduction_amount?: number,
        validity_period_type?: number,
        validity_period_days?: number,
        quantity?: number,
        specify_name?: string,
        specify_phone?: string,
        specify_company?: string,
    },
    params?:{},
    options?: { [key: string]: any }
) {
    return request<any>(`/api/coupon/create`, {
        method: 'post',
        params,
        requestType: 'json',
        data: body,
        ...(options || {}),
    })
}


//退款
export async function OrderRefund(
    body?: {
        order_id?: string,
    },
    params?:{},
    options?: { [key: string]: any }
) {
    return request<any>(`/api/order/refund`, {
        method: 'post',
        params,
        requestType: 'json',
        data: body,
        ...(options || {}),
    })
}
