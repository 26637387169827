import React from 'react';
import '../globalLess/global.css'
import { Input as TextInput, Tooltip } from 'antd'

export default class Input extends React.Component<{
    require?: number,
    title: string;
    titlestyle?: React.CSSProperties,
    type?: string,
    style?: React.CSSProperties,
    onChange?: (e: string) => void,
    placeholder?: string,
    defaultValue?: string,
    value?: string,
    disabled?: boolean,
    remark?: boolean
}> {

    state = {
        isOpen: false,

    }
 
    render() {
        const {
            require,
            title,
            titlestyle,
            onChange,
            remark,
            value
        } = this.props
        const {
            isOpen
        } = this.state
        return (
            <Tooltip title={value}  defaultOpen={false} open={isOpen}  onOpenChange={(eBool: boolean)=>{
                if(eBool){
                    if (remark && value && (value?.length >= 16)) {
                        this.setState({
                            isOpen: eBool
                        })
                    }else{
                        this.setState({
                            isOpen: false
                        }) 
                    }
                }else{
                    this.setState({
                        isOpen: false
                    }) 
                }
               
            }} mouseEnterDelay={0.2}>
                <div className='inputStyle'
                >
                    {
                        require &&
                        <div className='inputRequire'>*</div>
                    }
                    <div
                        className='inputTitle'
                        style={titlestyle}>{title}</div>

                    <TextInput
                        className='textInputStr'
                        {...this.props}
                        onChange={(e) => {
                            if (e.target.value === '') {
                                onChange && onChange(e.target.value)
                            } else {
                                if (remark) {
                                    if (e.target.value.length < 201) {
                                        onChange && onChange(e.target.value)
                                    }
                                } else {
                                    if (e.target.value.length < 33) {
                                        onChange && onChange(e.target.value)
                                    }
                                }
                            }
                        }}
                    />
                </div>
            </Tooltip>
        )
    }
}

