import React, { useEffect, useState } from "react";
import {
  Input,
  InputNumber,
  Switch,
  TimePicker,
  Select,
  Tooltip,
  Modal,
} from "antd";
import "../../../globalLess/global.css";
import "../../../components/Layout/index.less";
import {
  editShop,
  getShopDetail,
  getMerchantList,
} from "../../../service/index";
import {
  toastErrorMessage,
  toastMessage,
  toastSuccessMessage,
  tabMenu,
} from "../../../utils";
import moment from "moment";
import { useLocation } from "react-router-dom";
import type { SelectProps } from "antd";
import { log } from "console";
const { confirm } = Modal;

type RuleType = {
  rule_name: string;
  rule_type: number;
  system_accounts: { id: string; radio: string }[];
  distribution_sub_accounts: string[];
  distribution_sub_account_radio: string;
  service_sub_accounts: { id: string; radio: string }[];
};

const EditStorge = (): React.ReactElement => {
  const defaultRule = {
    rule_name: "",
    rule_type: 3,
    system_accounts: [],
    distribution_sub_accounts: [],
    distribution_sub_account_radio: "",
    service_sub_accounts: [],
  };
  let location = useLocation();
  const [current, setCurrent] = useState("1");
  const [name, setName] = useState("");
  const [adress, setAdress] = useState("");
  const [start_time, setStart_time] = useState<any>(null);
  const [end_time, setEnd_time] = useState<any>(null);
  const [iphone, setIphone] = useState("");
  const [is_use, setIs_use] = useState(0);
  const [ticket_name, setTicket_name] = useState("");
  const [take_duration, setTake_duration] = useState<any>();
  const [cut_number, setCut_number] = useState<any>();
  const [ticket_price, setTicket_price] = useState<any>();
  const [is_use_append, setIs_use_append] = useState(0);
  const [free_type, setFree_type] = useState(0);
  const [append_price, setAppend_price] = useState("");
  const [timeValue, setTimeValue] = useState<any>([null, null]);
  const [append_rules_price1, setAppend_rules_price1] = useState<any>({
    count: undefined,
    price: undefined,
  });
  const [append_rules_priceLast, setAppend_rules_priceLast] =
    useState<number>();
  const [append_rules_priceMiddle, setAppend_rules_priceMiddle] = useState<any>(
    [{ count: undefined, price: undefined }]
  );
  const [shop_id, setShop_id] = useState("");
  const [template_number, setTemplate_number] = useState<any>();
  const [ruleModal, setRuleModal] = useState(false);
  const [use_sub_account, setUse_sub_account] = useState(0); //分账功能默认不起用
  const [sub_account_day, setSub_account_day] = useState(3); //分账到账周期天 3天
  const [sub_rules, setSub_rules] = useState<RuleType[]>([]);
  const [newRule, setNewRule] = useState<RuleType>(defaultRule);
  const [businessArray, setBusinessArray] = useState<any>([]);
  
  const [merchantList, setMerchantList] = useState<any>([
    { id: "", label: "" },
  ]);

  const [dsMerchantList, setDSMerchantList] = useState<any>([
    { id: "", label: "" },
  ]);
  const [isAddRule, setIsAddRule] = useState(true);

  const [editRuleNum, setEditRuleNum] = useState(0);
  const newOptions = (start: number, end: number) => {
    const options: SelectProps["options"] = [];
    for (let i = start; i < end; i++) {
      options.push({
        key: i + `${start}${end}`,
        label: i,
        value: i,
      });
    }
    return options;
  };
  const getMerchantListFetch = async (merchant_type: string) => {
    await getMerchantList({
      page_num: 1,
      page_size: 1000,
      merchant_type: merchant_type,
    })
      .then((response) => {
        if (response.code === 200) {
          if (merchant_type === "3") {
            setDSMerchantList(response.data.items);
            subBusinessList(response.data.items)
          } else {
            setMerchantList(response.data.items);
          }
        } else {
          toastErrorMessage(response.msg);
        }
      })
      .catch((err) => {});
  };

  const addStep = () => {
    if (append_rules_priceMiddle.length < 3) {
      const arrray: any = append_rules_priceMiddle.slice();
      arrray.push({ count: undefined, price: undefined });
      setAppend_rules_priceMiddle(arrray);
    }
  };

  const saveData = () => {
    if (!name) {
      toastMessage("请输入门店名称");
      return;
    }
    if (!adress) {
      toastMessage("请输入门店地址");
      return;
    }
    if (!start_time) {
      toastMessage("请输入营业时间");
      return;
    }
    if (!end_time) {
      toastMessage("请输入营业时间");
      return;
    }
    if (!iphone) {
      toastMessage("请输入客服电话");
      return;
    }

    if (!ticket_name) {
      toastMessage("请输入门票名称");
      return;
    }
    if (!take_duration) {
      toastMessage("请输入拍摄时长");
      return;
    }
    if (!cut_number) {
      toastMessage("请输入剪辑条数");
      return;
    }
    if (!ticket_price) {
      toastMessage("请输入门票价格");
      return;
    }
    if (!template_number) {
      toastMessage("请输入拍摄模板数量");
      return;
    }
    let dict: any = {
      name,
      adress,
      start_time,
      end_time,
      iphone,
      ticket_name,
      take_duration: take_duration * 60,
      cut_number,
      ticket_price: `${ticket_price}`,
      is_use,
      id: shop_id,
      template_number,
    };
    if (is_use_append === 0 || is_use_append === 1) {
      dict.is_use_append = is_use_append;
    }
    if (is_use_append === 0 || is_use_append === 1) {
      // dict.free_type = free_type
      if (free_type === 0) {
        if (is_use_append === 1) {
          if (!append_price) {
            toastMessage("请输入价格");
            return;
          }
        }

        dict.append_price = append_price;
      } else if (free_type === 1) {
        if (!append_rules_price1.count) {
          toastMessage("请输入购买视频条数");
          return;
        }
        if (!append_rules_price1.price) {
          toastMessage("请输入购买视频价格");
          return;
        }
        if (!append_rules_priceLast) {
          toastMessage("请输入购买视频价格");
          return;
        }
        if (append_rules_priceMiddle.length > 0) {
          let isOK = false;
          for (
            let index = 0;
            index < append_rules_priceMiddle.length;
            index++
          ) {
            const item = append_rules_priceMiddle[index];
            if (!item.count) {
              isOK = true;
            } else if (!item.price) {
              isOK = true;
            }
          }
          if (isOK) {
            toastMessage("请输入正确购买视频条数和价格");
            return;
          }

          let ArrayList = [];
          ArrayList.push([
            append_rules_price1.price,
            append_rules_price1.count,
          ]);
          for (
            let index = 0;
            index < append_rules_priceMiddle.length;
            index++
          ) {
            const item = append_rules_priceMiddle[index];
            ArrayList.push([item.price, item.count]);
          }
          ArrayList.push([append_rules_priceLast]);
          dict.append_rules = JSON.stringify(ArrayList);
        }
      }
    }
    if (use_sub_account === 1) {
      dict.use_sub_account = use_sub_account;
      dict.sub_account_day = sub_account_day;
      if(JSON.stringify(sub_rules) === '[]'){
        toastMessage("请设置分账规则");
        return
      }
      const nameArray: any = [];
      const sub_rulesCopy: any = JSON.parse(JSON.stringify(sub_rules));
      sub_rulesCopy.forEach((sItem: any, sIndex: any) => {
        nameArray.push(sItem.rule_name);
        if (sItem.distribution_sub_account_radio) {
          sItem.distribution_sub_account_radio =
            parseFloat(sItem.distribution_sub_account_radio) / 100 + "";
        }
        sItem.system_accounts.forEach((sysItem: any, sysIndex: any) => {
          sysItem.radio = parseFloat(sysItem.radio) / 100 + "";
        });
        sItem.service_sub_accounts.forEach((serItem: any, sysIndex: any) => {
          serItem.radio = parseFloat(serItem.radio) / 100 + "";
        });
      });
      if (nameArray.length > 0 && hasDuplicates(nameArray)) {
        toastMessage("规则名称不可重复");
        return;
      }
      dict.sub_rules = sub_rulesCopy;
    } else {
      dict.use_sub_account = use_sub_account;
    }
    editShop(dict)
      .then((res) => {
        if (res.code === 200) {
          toastSuccessMessage("保存成功");
          setTimeout(() => {
            window.history.go(-1);
          }, 1000);
        } else {
          toastSuccessMessage(res.msg);
        }
      })
      .catch((err) => {
        toastErrorMessage(err.message);
      });
  };
  const hasDuplicates = (arr: string[]) => {
    return new Set(arr).size !== arr.length;
  };
  const ShopDetail = (id: string) => {
    let dict = {
      id,
    };
    getShopDetail(dict)
      .then((res: any) => {
        if (res.code === 200) {
          setName(res.data.name);
          setAdress(res.data.adress);
          setStart_time(res.data.start_time);
          setEnd_time(res.data.end_time);
          setTimeValue([
            moment(res.data.start_time, "HH:mm"),
            moment(res.data.end_time, "HH:mm"),
          ]);
          setIphone(res.data.iphone);
          setIs_use(res.data.is_use);
          setTicket_name(res.data.ticket_name);
          setTake_duration(res.data.take_duration / 60);
          setCut_number(res.data.cut_number);
          setTicket_price(res.data.ticket_price);
          setIs_use_append(res.data.is_use_append);
          setFree_type(res.data.free_type);
          setTemplate_number(res.data.template_number);
          if (res.data.free_type === 0) {
            setAppend_price(res.data.append_price);
          } else if (res.data.free_type === 1) {
            const List = JSON.parse(res.data.append_rules);
            setAppend_rules_price1({ count: List[0][1], price: List[0][0] });
            setAppend_rules_priceLast(List[List.length - 1][0]);
            if (List.length > 2) {
              let ary: any = [];
              for (let index = 1; index < List.length - 1; index++) {
                const item = List[index];
                ary.push({ count: item[1], price: item[0] });
              }
              setAppend_rules_priceMiddle(ary);
            }
          }
          res.data.sub_account_day &&
            setSub_account_day(res.data.sub_account_day);
          res.data.use_sub_account &&
            setUse_sub_account(res.data.use_sub_account);
          if (res.data.sub_rules) {
            let sub_rulesCopy = JSON.parse(JSON.stringify(res.data.sub_rules));
            sub_rulesCopy.forEach((sItem: any, sIndex: any) => {
              sItem.distribution_sub_account_radio =
            Math.round(parseFloat(sItem.distribution_sub_account_radio) * 100) + "";
              sItem.system_accounts.forEach((sysItem: any, sysIndex: any) => {
                sysItem.radio = Math.round(parseFloat(sysItem.radio) * 100) + "";
              });

              let ids_array: any = [];
              sItem.distribution_sub_accounts.forEach(
                (sysItem: any, sysIndex: any) => {
                  ids_array.push(sysItem.id);
                }
              );
              sItem.distribution_sub_accounts = ids_array;
              sItem.service_sub_accounts.forEach(
                (serItem: any, sysIndex: any) => {
                  serItem.radio = Math.round(parseFloat(serItem.radio) * 100)  + "";
                }
              );
            });
            setSub_rules(sub_rulesCopy);
          }
        }
      })
      .catch((err: any) => {});
  };

  const subBusinessList = (dsMerchantList1:any) => {
    let list = JSON.parse(JSON.stringify(dsMerchantList1));
    let filterArray = list;
    if (sub_rules.length > 0) {
      for (let j = 0; j < sub_rules.length; j++) {
        const eRule = sub_rules[j];
        if (
          eRule.distribution_sub_accounts &&
          eRule.distribution_sub_accounts.length > 0
        ) {
          for (let k = 0; k < eRule.distribution_sub_accounts.length; k++) {
            const distribution = eRule.distribution_sub_accounts[k];
            const array = filterArray.filter(
              (abItem: any) => abItem.id !== distribution
            );
            filterArray = array;
          }
        }
      }
    }
    if (filterArray.length) {
      let array = []
      for (let index = 0; index < filterArray.length; index++) {
        const element = filterArray[index];
        array.push({
          value: element.id,
          label: element.merchant_name,
        })
      }
      console.log('123213',array)
    setBusinessArray(array)
    } else {
      setBusinessArray([])
    }

  };

  const jzCount = (system_accounts: any) => {
    let num = 0;
    if (system_accounts.length > 0) {
      for (let index = 0; index < system_accounts.length; index++) {
        const element: any = system_accounts[index];
        if (element.radio) {
          num += parseFloat(element.radio);
        }
      }
    }
    return num;
  };

  const fzCount = (service_sub_accounts: any) => {
    let num = 0;
    if (service_sub_accounts.length > 0) {
      for (let index = 0; index < service_sub_accounts.length; index++) {
        const element: any = service_sub_accounts[index];
        if (element.radio) {
          num += parseFloat(element.radio);
        }
      }
    }
    if (newRule.distribution_sub_account_radio) {
      num += parseFloat(newRule.distribution_sub_account_radio);
    }
    return num;
  };

  const totalCount = (newRule: any) => {
    let num = 0;
    if (newRule.system_accounts.length > 0) {
      for (let index = 0; index < newRule.system_accounts.length; index++) {
        const element: any = newRule.system_accounts[index];
        if (element.radio) {
          num += parseFloat(element.radio);
        }
      }
    }

    if (newRule.service_sub_accounts.length > 0) {
      for (
        let index = 0;
        index < newRule.service_sub_accounts.length;
        index++
      ) {
        const element: any = newRule.service_sub_accounts[index];
        if (element.radio) {
          num += parseFloat(element.radio);
        }
      }
    }
    if (newRule.distribution_sub_account_radio) {
      num += parseFloat(newRule.distribution_sub_account_radio);
    }
    return num;
  };

  const systemItemName = (id: string) => {
    let name = "";
    merchantList.forEach((element: any) => {
      if (element.id === id) {
        name = element.merchant_name;
      }
    });
    return name;
  };
  const distributionName = (ids: string[]) => {
    let name = "";
    console.log('dsMerchantListdsMerchantList',dsMerchantList);
    console.log('ids',ids);
    for (let index = 0; index < ids.length; index++) {
      const element = ids[index];
      for (let j = 0; j < dsMerchantList.length; j++) {
        const item = dsMerchantList[j];
        if (item.id === element) {
          if (name === "") {
            name += item.merchant_name;
          } else {
            name += "," + item.merchant_name;
          }
        }
      }
    }
    return name;
  };

  useEffect(() => {
    getMerchantListFetch("0");
    getMerchantListFetch("3");
    // eslint-disable-next-line array-callback-return
    if (location && location.state && location.state.id) {
      ShopDetail(location.state.id);
      setShop_id(location.state.id);
    }
  }, []);
  return (
    <div style={{ height: "100%", width: "100%" }}>
      <div style={{ backgroundColor: "#FFF", width: "100%", height: 900 }}>
        <div
          style={{
            backgroundColor: "#FFF",
            display: "flex",
            flexDirection: "row",
            paddingTop: 20,
          }}
        >
          {tabMenu.map((item) => {
            return (
              <div
                key={item.key}
                style={{
                  marginLeft: item.key === "1" ? 5 : 20,
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setCurrent(item.key);
                }}
              >
                <div
                  style={{
                    width: 70,
                    fontSize: 14,
                    color: current === item.key ? "#FB186A" : "#000000",
                    textAlign: "center",
                  }}
                >
                  {" "}
                  {item.label}
                </div>
                <div
                  style={{
                    marginTop: 5,
                    height: 1,
                    marginLeft: 10,
                    width: 50,
                    backgroundColor: current === item.key ? "#FB186A" : "#FFF",
                  }}
                />
              </div>
            );
          })}
        </div>
        <div
          style={{ width: "100%", height: 0.5, backgroundColor: "#F3F4F5" }}
        />

        {current === "1" && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              height: 400,
              alignItems: "center",
            }}
          >
            <div
              className="inputTitle"
              style={{
                fontSize: 16,
                marginLeft: -380,
                width: 100,
                marginTop: 20,
                fontWeight: "bold",
              }}
            >
              门店信息
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: 470,
                marginTop: 20,
                alignItems: "center",
                borderWidth: 2,
                borderColor: "#F3F4F5",
                borderStyle: "dashed",
              }}
            >
              <div className="inputStyle" style={{ marginTop: 20 }}>
                <div
                  className="inputTitle"
                  style={{
                    fontSize: 14,
                    marginRight: -5,
                    width: 3,
                    color: "red",
                  }}
                >
                  *
                </div>
                <div
                  className="inputTitle"
                  style={{
                    fontSize: 14,
                    marginRight: -5,
                    width: 100,
                    color: "#666666",
                  }}
                >
                  门店名称：
                </div>
                <Input
                  className="textInputStr"
                  placeholder="请输入"
                  style={{ width: 336 }}
                  showCount
                  value={name}
                  maxLength={20}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                />
              </div>

              <div className="inputStyle" style={{ marginTop: 20 }}>
                <div
                  className="inputTitle"
                  style={{
                    fontSize: 14,
                    marginRight: -5,
                    width: 3,
                    color: "red",
                  }}
                >
                  *
                </div>

                <div
                  className="inputTitle"
                  style={{
                    fontSize: 14,
                    marginRight: -5,
                    width: 100,
                    color: "#666666",
                  }}
                >
                  门店地址：
                </div>
                <Input
                  className="textInputStr"
                  placeholder="请输入"
                  maxLength={50}
                  value={adress}
                  style={{ width: 336 }}
                  onChange={(e) => {
                    setAdress(e.target.value);
                  }}
                />
              </div>

              <div className="inputStyle" style={{ marginTop: 20 }}>
                <div
                  className="inputTitle"
                  style={{
                    fontSize: 14,
                    marginRight: -5,
                    width: 3,
                    color: "red",
                  }}
                >
                  *
                </div>
                <div
                  className="inputTitle"
                  style={{
                    fontSize: 14,
                    marginRight: -5,
                    width: 100,
                    color: "#666666",
                  }}
                >
                  营业时间：
                </div>

                <TimePicker.RangePicker
                  order={false}
                  value={timeValue}
                  format={"HH:mm"}
                  onChange={(values: any, formatString: [string, string]) => {
                    if (formatString && formatString[0]) {
                      setStart_time(formatString[0]);
                      setEnd_time(formatString[1]);
                      setTimeValue([
                        moment(formatString[0], "HH:mm"),
                        moment(formatString[1], "HH:mm"),
                      ]);
                    } else {
                      setStart_time(null);
                      setEnd_time(null);
                      setTimeValue([null, null]);
                    }
                  }}
                  style={{ marginTop: 5, width: 336 }}
                />
              </div>

              <div className="inputStyle" style={{ marginTop: 20 }}>
                <div
                  className="inputTitle"
                  style={{
                    fontSize: 14,
                    marginRight: -5,
                    width: 3,
                    color: "red",
                  }}
                >
                  *
                </div>
                <div
                  className="inputTitle"
                  style={{
                    fontSize: 14,
                    marginRight: -5,
                    width: 100,
                    color: "#666666",
                  }}
                >
                  客服电话：
                </div>
                <Input
                  className="textInputStr"
                  placeholder="请输入"
                  style={{ width: 336 }}
                  type="tel"
                  value={iphone}
                  onChange={(e) => {
                    setIphone(e.target.value);
                  }}
                />
              </div>
              <div
                className="inputStyle"
                style={{ marginTop: 20, marginBottom: 20 }}
              >
                <div
                  className="inputTitle"
                  style={{
                    fontSize: 14,
                    marginLeft: -210,
                    width: 130,
                    color: "#666666",
                    marginRight: -5,
                  }}
                >
                  (选填)是否启用：
                </div>
                <Switch
                  checked={is_use === 1}
                  onChange={(e) => {
                    const num = e ? 1 : 0;
                    setIs_use(num);
                  }}
                />
              </div>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                height: 400,
                alignItems: "center",
              }}
            >
              <div
                className="inputTitle"
                style={{
                  fontSize: 16,
                  marginLeft: -380,
                  width: 100,
                  marginTop: 20,
                  fontWeight: "bold",
                }}
              >
                门票信息
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: 470,
                  marginTop: 20,
                  alignItems: "center",
                  borderWidth: 2,
                  borderColor: "#F3F4F5",
                  borderStyle: "dashed",
                }}
              >
                <div className="inputStyle" style={{ marginTop: 20 }}>
                  <div
                    className="inputTitle"
                    style={{
                      fontSize: 14,
                      marginRight: -5,
                      width: 3,
                      color: "red",
                    }}
                  >
                    *
                  </div>
                  <div
                    className="inputTitle"
                    style={{
                      fontSize: 14,
                      marginRight: -5,
                      width: 100,
                      color: "#666666",
                    }}
                  >
                    门票名称：
                  </div>
                  <Input
                    className="textInputStr"
                    placeholder="请输入"
                    style={{ width: 336 }}
                    showCount
                    value={ticket_name}
                    maxLength={10}
                    onChange={(e) => {
                      console.log("eeeee", e);
                      setTicket_name(e.target.value);
                    }}
                  />
                </div>

                <div className="inputStyle" style={{ marginTop: 20 }}>
                  <div
                    className="inputTitle"
                    style={{
                      fontSize: 14,
                      marginRight: -5,
                      width: 3,
                      color: "red",
                    }}
                  >
                    *
                  </div>

                  <div
                    className="inputTitle"
                    style={{
                      fontSize: 14,
                      marginRight: -5,
                      width: 100,
                      color: "#666666",
                    }}
                  >
                    拍摄时长：
                  </div>
                  <InputNumber
                    className="textInputStr"
                    placeholder="请输入"
                    addonAfter="分钟"
                    value={take_duration}
                    step={0.1}
                    min={0}
                    precision={1}
                    style={{ width: 336 }}
                    onChange={(e) => {
                      setTake_duration(e);
                    }}
                  />
                </div>

                <div className="inputStyle" style={{ marginTop: 20 }}>
                  <div
                    className="inputTitle"
                    style={{
                      fontSize: 14,
                      marginRight: -5,
                      width: 3,
                      color: "red",
                    }}
                  >
                    *
                  </div>
                  <div
                    className="inputTitle"
                    style={{
                      fontSize: 14,
                      marginRight: -5,
                      width: 100,
                      color: "#666666",
                    }}
                  >
                    剪辑条数：
                  </div>
                  <InputNumber
                    className="textInputStr"
                    addonAfter="条"
                    placeholder="请输入"
                    value={cut_number}
                    min={1}
                    style={{ width: 336 }}
                    onChange={(e) => {
                      setCut_number(e);
                    }}
                  />
                </div>

                <div
                  className="inputStyle"
                  style={{ marginTop: 20, marginBottom: 0 }}
                >
                  <div
                    className="inputTitle"
                    style={{
                      fontSize: 14,
                      marginLeft: 30,
                      marginRight: -5,
                      width: 3,
                      color: "red",
                    }}
                  >
                    *
                  </div>
                  <div
                    className="inputTitle"
                    style={{
                      fontSize: 14,
                      marginRight: -5,
                      width: 70,
                      color: "#666666",
                    }}
                  >
                    票价：
                  </div>
                  <InputNumber
                    className="textInputStr"
                    placeholder="请输入"
                    addonAfter="元"
                    value={ticket_price}
                    step={1}
                    min={0}
                    precision={2}
                    style={{ width: 336 }}
                    onChange={(e) => {
                      setTicket_price(e);
                    }}
                  />
                </div>

                <div
                  className="inputStyle"
                  style={{ marginTop: 20, marginBottom: 20 }}
                >
                  <div
                    className="inputTitle"
                    style={{
                      fontSize: 14,
                      marginLeft: -15,
                      marginRight: -7,
                      width: 3,
                      color: "red",
                    }}
                  >
                    *
                  </div>
                  <div
                    className="inputTitle"
                    style={{
                      fontSize: 14,
                      marginRight: -5,
                      width: 120,
                      color: "#666666",
                    }}
                  >
                    拍摄模板数量：
                  </div>
                  <InputNumber
                    className="textInputStr"
                    placeholder="请输入"
                    addonAfter="条"
                    value={template_number}
                    step={1}
                    precision={0}
                    min={0}
                    style={{ width: 336 }}
                    onChange={(e) => {
                      setTemplate_number(e);
                    }}
                  />
                </div>
              </div>
            </div>

            <div
              style={{ display: "flex", flexDirection: "row", marginTop: 20 }}
            >
              <div
                className="button"
                style={{
                  zIndex: 20,
                  borderStyle: "solid",
                  borderWidth: 1,
                  borderColor: "#E5E5E5",
                  marginTop: 45,
                }}
                onClick={() => {
                  window.history.go(-1);
                }}
              >
                <div className="buttonTitle" style={{ color: "#333333" }}>
                  取消
                </div>
              </div>
              <div
                className="button"
                style={{
                  zIndex: 20,
                  marginLeft: 20,
                  backgroundColor: "#FB186A",
                  marginTop: 45,
                }}
                onClick={() => {
                  setCurrent("2");
                }}
              >
                <div className="buttonTitle" style={{ color: "#FFF" }}>
                  下一步
                </div>
              </div>
            </div>
          </div>
        )}
        {current === "2" && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              height: 400,
              alignItems: "center",
            }}
          >
            <div className="inputStyle" style={{ marginTop: 20 }}>
              <div
                className="inputTitle"
                style={{
                  fontSize: 14,
                  marginLeft: -210,
                  width: 130,
                  color: "#666666",
                  marginRight: -5,
                }}
              >
                启用增值服务：
              </div>
              <Switch
                checked={is_use_append === 1}
                onChange={(e) => {
                  const num = e ? 1 : 0;
                  if (num === 0) {
                    setAppend_price("");
                    setAppend_rules_price1({
                      count: undefined,
                      price: undefined,
                    });
                    setAppend_rules_priceLast(undefined);
                    setAppend_rules_priceMiddle([
                      { count: undefined, price: undefined },
                    ]);
                  }
                  setIs_use_append(num);
                }}
              />
            </div>

            <div className="inputStyle" style={{ marginTop: 20 }}>
              <div
                className="inputTitle"
                style={{
                  fontSize: 14,
                  marginLeft: -85,
                  width: 90,
                  color: "#666666",
                  marginRight: -5,
                }}
              >
                计费方式：
              </div>
              <img
                style={{
                  marginLeft: 0,
                  width: 16,
                  height: 16,
                  zIndex: 20,
                  cursor: "pointer",
                }}
                src={
                  free_type === 0
                    ? require("../../../assets/icon_selectIcon.png")
                    : require("../../../assets/icon_unSelect.png")
                }
                alt=""
                onClick={() => {
                  setFree_type(0);
                  setAppend_rules_price1({
                    count: undefined,
                    price: undefined,
                  });
                  setAppend_rules_priceLast(undefined);
                  setAppend_rules_priceMiddle([
                    { count: undefined, price: undefined },
                  ]);
                }}
              />
              <div
                className="inputTitle"
                style={{
                  fontSize: 14,
                  marginLeft: -10,
                  width: 90,
                  color: "#666666",
                  marginRight: -5,
                }}
              >
                统一计费
              </div>
              <img
                style={{
                  marginLeft: 0,
                  width: 16,
                  height: 16,
                  zIndex: 20,
                  cursor: "pointer",
                }}
                src={
                  free_type === 1
                    ? require("../../../assets/icon_selectIcon.png")
                    : require("../../../assets/icon_unSelect.png")
                }
                alt=""
                onClick={() => {
                  setFree_type(1);
                  setAppend_price("");
                }}
              />
              <div
                className="inputTitle"
                style={{
                  fontSize: 14,
                  marginLeft: -10,
                  width: 90,
                  color: "#666666",
                  marginRight: -5,
                }}
              >
                阶梯计费
              </div>
            </div>
            {free_type === 0 && (
              <div>
                <div className="inputStyle" style={{ marginTop: 20 }}>
                  <div
                    className="inputTitle"
                    style={{
                      fontSize: 14,
                      marginLeft: -78,
                      width: 90,
                      color: "#666666",
                      marginRight: -25,
                    }}
                  >
                    价格：
                  </div>

                  <InputNumber
                    className="textInputStr"
                    placeholder="请输入"
                    addonAfter="元/条"
                    style={{ width: 180 }}
                    min={0}
                    precision={2}
                    value={parseFloat(append_price)}
                    onChange={(e) => {
                      let str = e + "";
                      setAppend_price(str);
                    }}
                  />
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: 400,
                  }}
                >
                  <div
                    className="button"
                    style={{
                      zIndex: 20,
                      borderStyle: "solid",
                      borderWidth: 1,
                      borderColor: "#E5E5E5",
                      marginTop: 45,
                    }}
                    onClick={() => {
                      setCurrent("1");
                    }}
                  >
                    <div className="buttonTitle" style={{ color: "#333333" }}>
                      上一步
                    </div>
                  </div>

                  <div
                    className="button"
                    style={{
                      zIndex: 20,
                      marginLeft: 20,
                      backgroundColor: "#FB186A",
                      marginTop: 45,
                    }}
                    onClick={() => {
                      setCurrent("3");
                    }}
                  >
                    <div className="buttonTitle" style={{ color: "#FFF" }}>
                      下一步
                    </div>
                  </div>
                </div>
              </div>
            )}
            {free_type === 1 && (
              <div style={{ marginTop: 20, paddingLeft: 50 }}>
                <div className="inputStyle">
                  <div
                    className="inputTitle"
                    style={{
                      fontSize: 14,
                      marginLeft: 220,
                      width: 90,
                      color: "#000000",
                      marginRight: -25,
                    }}
                  >
                    阶段1：
                  </div>
                  <div
                    style={{
                      borderStyle: "solid",
                      borderWidth: 1,
                      borderColor: "#D9D9D9",
                    }}
                  >
                    <div
                      className="inputStyle"
                      style={{
                        marginTop: 20,
                        marginLeft: 20,
                        marginRight: 300,
                      }}
                    >
                      <div
                        className="inputTitle"
                        style={{
                          fontSize: 14,
                          marginLeft: 0,
                          width: 70,
                          color: "#666666",
                          marginRight: -25,
                        }}
                      >
                        购买视频：
                      </div>
                      <div
                        className="inputTitle"
                        style={{
                          fontSize: 14,
                          marginLeft: 0,
                          width: 90,
                          color: "#000000",
                          marginRight: -25,
                        }}
                      >
                        小于
                      </div>
                      <InputNumber
                        className="textInputStr"
                        placeholder="请输入"
                        addonAfter="条"
                        style={{ width: 150 }}
                        min={0}
                        value={
                          append_rules_price1.count &&
                          parseFloat(append_rules_price1.count)
                        }
                        onChange={(e) => {
                          setAppend_rules_price1({
                            count: e,
                            price: append_rules_price1.price,
                          });
                        }}
                      />
                    </div>
                    <div
                      className="inputStyle"
                      style={{
                        marginTop: 10,
                        marginLeft: 20,
                        marginBottom: 20,
                      }}
                    >
                      <div
                        className="inputTitle"
                        style={{
                          fontSize: 14,
                          marginLeft: 15,
                          width: 70,
                          color: "#666666",
                          marginRight: -10,
                        }}
                      >
                        价格：
                      </div>
                      <InputNumber
                        className="textInputStr"
                        placeholder="请输入"
                        addonAfter="元/条"
                        style={{ width: 150 }}
                        min={0}
                        value={
                          append_rules_price1.price &&
                          parseFloat(append_rules_price1.price)
                        }
                        onChange={(e) => {
                          setAppend_rules_price1({
                            count: append_rules_price1.count,
                            price: e,
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className="inputStyle"
                  style={{
                    marginTop: 20,
                    marginLeft: 200,
                    width: 720,
                    backgroundColor: "#FBFBFB",
                    flexDirection: "column",
                  }}
                >
                  {append_rules_priceMiddle.map((item: any, index: any) => (
                    <div
                      key={"a" + index}
                      className="inputStyle"
                      style={{ marginTop: 20 }}
                    >
                      <div
                        className="inputTitle"
                        style={{
                          fontSize: 14,
                          marginLeft: 0,
                          width: 90,
                          color: "#000000",
                          marginRight: -25,
                        }}
                      >{`阶段${index + 2}：`}</div>
                      <div
                        style={{
                          borderStyle: "solid",
                          borderWidth: 1,
                          borderColor: "#D9D9D9",
                          width: 585,
                        }}
                      >
                        <div
                          className="inputStyle"
                          style={{ marginTop: 20, marginLeft: 20 }}
                        >
                          <div
                            className="inputTitle"
                            style={{
                              fontSize: 14,
                              marginLeft: 0,
                              width: 70,
                              color: "#666666",
                              marginRight: -25,
                            }}
                          >
                            购买视频：
                          </div>
                          <div
                            className="inputTitle"
                            style={{
                              fontSize: 14,
                              marginLeft: 20,
                              width: 90,
                              color: "#000000",
                              marginRight: -10,
                            }}
                          >
                            {index === 0
                              ? append_rules_price1.count
                                ? `大于等于${append_rules_price1.count}条`
                                : "大于等于"
                              : append_rules_priceMiddle[index - 1].count
                              ? `大于等于${
                                  append_rules_priceMiddle[index - 1].count
                                }条`
                              : "大于等于"}
                          </div>
                          <div
                            className="inputTitle"
                            style={{
                              fontSize: 14,
                              marginLeft: 10,
                              width: 90,
                              color: "#000000",
                              marginRight: -10,
                            }}
                          >
                            且小于
                          </div>
                          <InputNumber
                            className="textInputStr"
                            placeholder="请输入"
                            addonAfter="条"
                            style={{ width: 150, marginRight: 40 }}
                            min={0}
                            value={
                              append_rules_priceMiddle[index].count &&
                              parseFloat(append_rules_priceMiddle[index].count)
                            }
                            onChange={(e) => {
                              const list = JSON.parse(
                                JSON.stringify(append_rules_priceMiddle)
                              );
                              item = list[index];
                              item.count = e;
                              list[index] = item;
                              setAppend_rules_priceMiddle(list);
                            }}
                          />
                        </div>
                        <div
                          className="inputStyle"
                          style={{
                            marginTop: 10,
                            marginLeft: 20,
                            marginBottom: 20,
                          }}
                        >
                          <div
                            className="inputTitle"
                            style={{
                              fontSize: 14,
                              marginLeft: 15,
                              width: 70,
                              color: "#666666",
                              marginRight: -10,
                            }}
                          >
                            价格：
                          </div>
                          <InputNumber
                            className="textInputStr"
                            placeholder="请输入"
                            addonAfter="元/条"
                            style={{ width: 150 }}
                            value={
                              append_rules_priceMiddle[index].price &&
                              parseFloat(append_rules_priceMiddle[index].price)
                            }
                            min={0}
                            onChange={(e) => {
                              const list = JSON.parse(
                                JSON.stringify(append_rules_priceMiddle)
                              );
                              item = list[index];
                              item.price = e;
                              list[index] = item;
                              setAppend_rules_priceMiddle(list);
                            }}
                          />
                        </div>
                      </div>
                      <img
                        alt=""
                        style={{
                          width: 28,
                          height: 28,
                          marginLeft: 5,
                          cursor: "pointer",
                        }}
                        src={require("../../../assets/icon_remove.png")}
                        onClick={() => {
                          const newList = JSON.parse(
                            JSON.stringify(append_rules_priceMiddle)
                          );
                          newList.splice(index, 1);
                          setAppend_rules_priceMiddle(newList);
                        }}
                      />
                    </div>
                  ))}
                  <div
                    className="button"
                    style={{
                      width: 582,
                      height: 45,
                      marginLeft: 35,
                      backgroundColor: "#FB186A",
                      marginTop: 20,
                      marginBottom: 20,
                    }}
                    onClick={(e) => {
                      addStep();
                    }}
                  >
                    <img
                      alt=""
                      style={{ width: 13, height: 13, marginRight: 5 }}
                      src={require("../../../assets/icon_add.png")}
                    />
                    <div
                      className="buttonTitle"
                      style={{ color: "#FFF", fontSize: 14, paddingTop: 2 }}
                    >
                      新增阶段
                    </div>
                  </div>
                </div>
                <div className="inputStyle" style={{ marginTop: 20 }}>
                  <div
                    className="inputTitle"
                    style={{
                      fontSize: 14,
                      marginLeft: 220,
                      width: 90,
                      color: "#000000",
                      marginRight: -25,
                    }}
                  >{`阶段${append_rules_priceMiddle.length + 2}：`}</div>
                  <div
                    style={{
                      borderStyle: "solid",
                      borderWidth: 1,
                      borderColor: "#D9D9D9",
                      width: 585,
                    }}
                  >
                    <div
                      className="inputStyle"
                      style={{
                        marginTop: 20,
                        marginLeft: 20,
                        marginRight: 275,
                      }}
                    >
                      <div
                        className="inputTitle"
                        style={{
                          fontSize: 14,
                          marginLeft: 0,
                          width: 70,
                          color: "#666666",
                          marginRight: -25,
                        }}
                      >
                        购买视频：
                      </div>
                      <div
                        className="inputTitle"
                        style={{
                          fontSize: 14,
                          marginLeft: 15,
                          width: 120,
                          color: "#000000",
                        }}
                      >
                        {append_rules_priceMiddle.length > 0 &&
                        append_rules_priceMiddle[
                          append_rules_priceMiddle.length - 1
                        ].count
                          ? `大于等于${
                              append_rules_priceMiddle[
                                append_rules_priceMiddle.length - 1
                              ].count
                            }条`
                          : append_rules_price1.count
                          ? `大于等于${append_rules_price1.count}条`
                          : "大于等于"}
                      </div>
                    </div>
                    <div
                      className="inputStyle"
                      style={{
                        marginTop: 10,
                        marginLeft: 20,
                        marginBottom: 20,
                      }}
                    >
                      <div
                        className="inputTitle"
                        style={{
                          fontSize: 14,
                          marginLeft: 15,
                          width: 70,
                          color: "#666666",
                          marginRight: -10,
                        }}
                      >
                        价格：
                      </div>

                      <InputNumber
                        className="textInputStr"
                        placeholder="请输入"
                        addonAfter="元/条"
                        style={{ width: 150 }}
                        value={append_rules_priceLast}
                        min={0}
                        onChange={(e: any) => {
                          setAppend_rules_priceLast(e);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: 30,
                    marginLeft: 400,
                  }}
                >
                  <div
                    className="button"
                    style={{
                      zIndex: 20,
                      borderStyle: "solid",
                      borderWidth: 1,
                      borderColor: "#E5E5E5",
                      marginTop: 45,
                    }}
                    onClick={() => {
                      setCurrent("1");
                    }}
                  >
                    <div className="buttonTitle" style={{ color: "#333333" }}>
                      上一步
                    </div>
                  </div>
                  <div
                    className="button"
                    style={{
                      zIndex: 20,
                      marginLeft: 20,
                      backgroundColor: "#FB186A",
                      marginTop: 45,
                    }}
                    onClick={() => {
                      setCurrent("3");
                    }}
                  >
                    <div className="buttonTitle" style={{ color: "#FFF" }}>
                      下一步
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}

        {current === "3" && (
          <div>
            <div className="inputStyle" style={{ marginTop: 20 }}>
              <div style={{ fontSize: 14, marginLeft: 62, marginRight: 3 }}>
                分账功能：
              </div>
              <Switch
                checked={use_sub_account === 1}
                onChange={(e) => {
                  if (e) {
                    setUse_sub_account(1);
                  } else {
                    setUse_sub_account(0);
                  }
                }}
              />
            </div>
            {use_sub_account === 1 && (
              <div className="rowCss" style={{ marginTop: 20 }}>
                <div style={{ fontSize: 14, marginRight: 10, marginLeft: 28 }}>
                  *分账到账周期:
                </div>
                <Select
                  style={{ width: 160 }}
                  value={sub_account_day}
                  onChange={(e) => {
                    setSub_account_day(e);
                  }}
                  options={newOptions(1, 29)}
                />
                <div style={{ fontSize: 14, marginRight: 10, marginLeft: 5 }}>
                  天
                </div>
              </div>
            )}
            {use_sub_account === 1 && (
              <div
                style={{
                  flex: 1,
                  height: 36,
                  marginTop: 40,
                  marginLeft: 36,
                  marginRight: 36,
                  borderStyle: "dashed",
                  display: "flex",
                  borderWidth: 1,
                  borderColor: "#FB186A",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setNewRule(defaultRule);
                  setIsAddRule(true);
                  setRuleModal(true);
                }}
              >
                <div style={{ textAlign: "center", display: "flex" }}>
                  <img
                    alt=""
                    style={{
                      width: 13,
                      height: 13,
                      marginRight: 5,
                      marginTop: 10,
                    }}
                    src={require("../../../assets/icon_rAdd.png")}
                  />
                  <div
                    style={{
                      textAlign: "center",
                      marginTop: 6,
                      color: "#FB186A",
                    }}
                  >
                    新建规则
                  </div>
                </div>
              </div>
            )}
            {use_sub_account === 1 &&
              sub_rules.map((rule, index) => {
                return (
                  <div
                    style={{ marginLeft: 30, marginRight: 30, marginTop: 20 }}
                  >
                    <div style={{ textAlign: "center", display: "flex" }}>
                      <div style={{ fontSize: 16 }}>{rule.rule_name}</div>
                      <img
                        alt=""
                        style={{
                          width: 18,
                          height: 18,
                          marginLeft: 10,
                          marginTop: 3,
                          cursor: "pointer",
                        }}
                        src={require("../../../assets/icon_edit.png")}
                        onClick={() => {
                          setEditRuleNum(index);
                          setIsAddRule(false);
                          setNewRule(rule);
                          setRuleModal(true);
                        }}
                      />
                      <img
                        alt=""
                        style={{
                          width: 18,
                          height: 18,
                          marginLeft: 10,
                          marginTop: 3,
                          cursor: "pointer",
                        }}
                        src={require("../../../assets/icon_delete.png")}
                        onClick={() => {
                          confirm({
                            title: "提示",
                            content: "是否删除该规则?",
                            onOk() {
                              const newList = JSON.parse(
                                JSON.stringify(sub_rules)
                              );
                              newList.splice(index, 1);
                              setSub_rules(newList);
                            },
                            onCancel() {},
                          });
                        }}
                      />
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        padding: 20,
                        borderStyle: "dashed",
                        borderWidth: 1,
                        borderColor: "#F3F4F5",
                      }}
                    >
                      {(rule.rule_type === 1 || rule.rule_type === 3) && (
                        <div
                          style={{ flex: 1, marginRight: 10, marginLeft: 10 }}
                        >
                          <div> 系统记账：</div>
                          <div
                            style={{
                              height: 34,
                              display: "flex",
                              flexDirection: "row",
                              backgroundColor: "#F5F6F8",
                            }}
                          >
                            <div className="subCell"> 商户名称</div>
                            <div className="subCell">分账比例</div>
                          </div>
                          {rule.system_accounts.map(
                            (systemItem: any, index: any) => {
                              return (
                                <div
                                  key={`aa${index}`}
                                  style={{
                                    height: 34,
                                    display: "flex",
                                    flexDirection: "row",
                                    borderBottomColor: "#F5F6F8",
                                    borderBottomWidth: 1,
                                    borderBottomStyle: "solid",
                                  }}
                                >
                                  <div className="subCell">
                                    {" "}
                                    {systemItemName(systemItem.id)}
                                  </div>
                                  <div className="subCell">
                                    {systemItem.radio + "%"}
                                  </div>
                                </div>
                              );
                            }
                          )}
                        </div>
                      )}

                      {(rule.rule_type === 2 || rule.rule_type === 3) && (
                        <div
                          style={{ flex: 1, marginRight: 10, marginLeft: 10 }}
                        >
                          <div> 微信分账：</div>
                          <div
                            style={{
                              height: 34,
                              display: "flex",
                              flexDirection: "row",
                              backgroundColor: "#F5F6F8",
                            }}
                          >
                            <div className="subCell"> </div>
                            <div className="subCell"> 商户名称</div>
                            <div className="subCell">分账比例</div>
                          </div>
                          <div
                            style={{
                              height: 34,
                              display: "flex",
                              flexDirection: "row",
                              borderBottomColor: "#F5F6F8",
                              borderBottomWidth: 1,
                              borderBottomStyle: "solid",
                            }}
                          >
                            <div className="subCell"> 分销商</div>
                            <Tooltip placement="top" title={distributionName(rule.distribution_sub_accounts)}>
                            <div className="subCell">
                              {distributionName(rule.distribution_sub_accounts).length>9?(distributionName(rule.distribution_sub_accounts).substring(0,9)+'...'):distributionName(rule.distribution_sub_accounts)}
                            </div>
                            </Tooltip>
                            <div className="subCell">
                              {rule.distribution_sub_account_radio + "%"}
                            </div>
                          </div>
                          {rule.service_sub_accounts.map(
                            (subItem: any, subIndex: any) => {
                              return (
                                <div
                                  key={`cc${subIndex}`}
                                  style={{
                                    height: 34,
                                    display: "flex",
                                    flexDirection: "row",
                                    borderBottomColor: "#F5F6F8",
                                    borderBottomWidth: 1,
                                    borderBottomStyle: "solid",
                                  }}
                                >
                                  <div className="subCell"> 服务商</div>
                                  <div className="subCell">
                                    {" "}
                                    {systemItemName(subItem.id)}
                                  </div>
                                  <div className="subCell">
                                    {subItem.radio + "%"}
                                  </div>
                                </div>
                              );
                            }
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: 30,
                marginLeft: "45%",
              }}
            >
              <div
                className="button"
                style={{
                  zIndex: 20,
                  borderStyle: "solid",
                  borderWidth: 1,
                  borderColor: "#E5E5E5",
                  marginTop: 100,
                }}
                onClick={() => {
                  setCurrent("2");
                }}
              >
                <div className="buttonTitle" style={{ color: "#333333" }}>
                  上一步
                </div>
              </div>
              <div
                className="button"
                style={{
                  zIndex: 20,
                  marginLeft: 20,
                  backgroundColor: "#FB186A",
                  marginTop: 100,
                }}
                onClick={() => {
                  saveData();
                }}
              >
                <div className="buttonTitle" style={{ color: "#FFF" }}>
                  保存
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <Modal
        width={832}
        style={{ marginTop: 100 }}
        open={ruleModal}
        onOk={() => {
          setRuleModal(false);
        }}
        title="编辑规则"
        onCancel={() => {
          setRuleModal(false);
        }}
        footer={null}
      >
        <div style={{ display: "flex", flexDirection: "row", marginTop: 10 }}>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div
              className="inputTitle"
              style={{ fontSize: 14, marginRight: 10, marginLeft: 20 }}
            >
              *规则名称:
            </div>
            <Input
              className="textInputStr"
              maxLength={20}
              style={{ width: 280, marginRight: 0 }}
              placeholder="请输入"
              value={newRule.rule_name}
              onChange={(e) => {
                const value = JSON.parse(JSON.stringify(newRule));
                value.rule_name = e.target.value;
                setNewRule(value);
              }}
            />
          </div>

          <div style={{ display: "flex", flexDirection: "row" }}>
            <div
              className="inputTitle"
              style={{
                width: 90,
                fontSize: 14,
                marginRight: 0,
                marginLeft: 10,
              }}
            >
              *规则类型:
            </div>
            <Select
              style={{ width: 280 }}
              value={
                newRule.rule_type === 2 || newRule.rule_type === 3
                  ? 3
                  : newRule.rule_type === 1
                  ? 1
                  : undefined
              }
              onChange={(value: number) => {
                const valueModel = JSON.parse(JSON.stringify(newRule));
                valueModel.rule_type = value;
                setNewRule(valueModel);
              }}
              options={[
                {
                  value: 3,
                  label: "有口令",
                },
                {
                  value: 1,
                  label: "无口令",
                },
              ]}
            />
          </div>
        </div>
        {(newRule.rule_type === 2 || newRule.rule_type === 3) && (
          <div className="inputStyle" style={{ marginTop: 20 }}>
            <div
              className="inputTitle"
              style={{
                fontSize: 14,
                marginLeft: 480,
                width: 130,
                color: "#666666",
                marginRight: -5,
              }}
            >
              是否启用系统记账：
            </div>
            <Switch
              checked={newRule.rule_type === 3}
              onChange={(e) => {
                const valueModel = JSON.parse(JSON.stringify(newRule));
                if (e) {
                  valueModel.rule_type = 3;
                } else {
                  valueModel.rule_type = 2;
                }
                setNewRule(valueModel);
              }}
            />
          </div>
        )}
        {(newRule.rule_type === 1 || newRule.rule_type === 3) && (
          <div>
            <div
              style={{ display: "flex", flexDirection: "row", marginTop: 20 }}
            >
              <div style={{ fontSize: 16, color: "#000", fontWeight: 400 }}>
                系统记账
              </div>
              <Tooltip
                placement="top"
                title={"只在平台做分类统计，不走微信分账流程"}
              >
                <img
                  style={{ marginLeft: 0, width: 18, height: 18, marginTop: 4 }}
                  src={require("../../../assets/icon_notice.png")}
                  alt=""
                />
              </Tooltip>
              <div
                className="button"
                style={{
                  zIndex: 20,
                  marginLeft: 16,
                  width: 76,
                  backgroundColor: "#FB186A",
                  marginTop: 0,
                }}
                onClick={() => {
                  const valueModel = JSON.parse(JSON.stringify(newRule));
                  valueModel.system_accounts.push({
                    id: "",
                    radio: "",
                  });
                  setNewRule(valueModel);
                  console.log("valueModelvalueModel", valueModel);
                }}
              >
                <div className="buttonTitle" style={{ color: "#FFF" }}>
                  + 添加
                </div>
              </div>
            </div>

            {newRule.system_accounts.map((system_accounts, index) => {
              return (
                <div
                  key={`a${index}`}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: 10,
                  }}
                >
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div
                      className="inputTitle"
                      style={{ fontSize: 14, marginRight: 10, marginLeft: 20 }}
                    >
                      *商户名称:
                    </div>
                    <Select
                      style={{ width: 280 }}
                      value={system_accounts.id}
                      onChange={(e) => {
                        system_accounts.id = e;
                        const valueModel = JSON.parse(JSON.stringify(newRule));
                        valueModel.system_accounts[index] = system_accounts;
                        setNewRule(valueModel);
                      }}
                      options={merchantList.map((d: any) => ({
                        value: d.id,
                        label: d.merchant_name,
                      }))}
                    />
                  </div>

                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div
                      className="inputTitle"
                      style={{
                        width: 90,
                        fontSize: 14,
                        marginRight: 0,
                        marginLeft: 10,
                      }}
                    >
                      *分账比例:
                    </div>
                    <InputNumber
                      maxLength={14}
                      minLength={6}
                      max="100"
                      min="0"
                      value={system_accounts.radio}
                      precision={2}
                      onChange={(e) => {
                        if (e) {
                          system_accounts.radio = e;
                          const valueModel = JSON.parse(
                            JSON.stringify(newRule)
                          );
                          valueModel.system_accounts[index] = system_accounts;
                          setNewRule(valueModel);
                        }
                      }}
                      style={{ width: 280, marginRight: 0 }}
                      placeholder="请输入"
                      addonAfter="%"
                    />
                    <img
                      alt=""
                      style={{
                        width: 18,
                        height: 18,
                        marginLeft: 5,
                        marginTop: 5,
                        cursor: "pointer",
                      }}
                      src={require("../../../assets/icon_rDelete.png")}
                      onClick={() => {
                        const valueModel = JSON.parse(JSON.stringify(newRule));
                        const newFileList = newRule.system_accounts.slice();
                        newFileList.splice(index, 1);
                        valueModel.system_accounts = newFileList;
                        setNewRule(valueModel);
                      }}
                    />
                  </div>
                </div>
              );
            })}

            <div
              style={{
                fontSize: 14,
                color: "#000",
                marginLeft: 100,
                marginTop: 10,
                marginBottom: 10,
              }}
            >
              {"记账比例合计：" +
                (jzCount(newRule.system_accounts)
                  ? `${jzCount(newRule.system_accounts)}%`
                  : "")}
            </div>
          </div>
        )}

        {(newRule.rule_type === 2 || newRule.rule_type === 3) && (
          <div>
            <div style={{ fontSize: 16, color: "#000", fontWeight: 400 }}>
              微信分账
            </div>
            <div
              style={{
                fontSize: 16,
                marginLeft: 16,
                marginTop: 5,
                color: "#000",
                fontWeight: 400,
              }}
            >
              分销商
            </div>
            <div
              style={{ display: "flex", flexDirection: "row", marginTop: 10 }}
            >
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div
                  className="inputTitle"
                  style={{ fontSize: 14, marginRight: 10, marginLeft: 20 }}
                >
                  *商户名称:
                </div>
                <Select
                  mode="multiple"
                  style={{ width: 280 }}
                  allowClear
                  onChange={(value: string[]) => {
                    const valueModel = JSON.parse(JSON.stringify(newRule));
                    valueModel.distribution_sub_accounts = value;
                    setNewRule(valueModel);
                  }}
                  value={newRule.distribution_sub_accounts}
                  options={businessArray}
                />
              </div>

              <div style={{ display: "flex", flexDirection: "row" }}>
                <div
                  className="inputTitle"
                  style={{
                    width: 90,
                    fontSize: 14,
                    marginRight: 0,
                    marginLeft: 10,
                  }}
                >
                  *分账比例:
                </div>
                <InputNumber
                  maxLength={14}
                  minLength={6}
                  precision={2}
                  style={{ width: 280, marginRight: 0 }}
                  value={newRule.distribution_sub_account_radio}
                  placeholder="请输入"
                  addonAfter="%"
                  max="30"
                  min="0"
                  onChange={(e) => {
                    if (e) {
                      const valueModel = JSON.parse(JSON.stringify(newRule));
                      valueModel.distribution_sub_account_radio = `${e}`;
                      setNewRule(valueModel);
                    }
                  }}
                />
              </div>
            </div>
            <div
              style={{ flexDirection: "row", display: "flex", marginTop: 25 }}
            >
              <div
                style={{
                  fontSize: 16,
                  marginLeft: 16,
                  color: "#000",
                  fontWeight: 400,
                }}
              >
                服务商
              </div>

              <div
                className="button"
                style={{
                  zIndex: 20,
                  marginLeft: 16,
                  width: 100,
                  backgroundColor: "#FB186A",
                  marginTop: 0,
                }}
                onClick={() => {
                  const valueModel = JSON.parse(JSON.stringify(newRule));
                  valueModel.service_sub_accounts.push({
                    id: "",
                    radio: "",
                  });
                  setNewRule(valueModel);
                }}
              >
                <div className="buttonTitle" style={{ color: "#FFF" }}>
                  + 添加服务商
                </div>
              </div>
            </div>

            {newRule.service_sub_accounts.map((service_sub_account, index) => {
              return (
                <div
                  key={`b${index}`}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: 10,
                  }}
                >
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div
                      className="inputTitle"
                      style={{ fontSize: 14, marginRight: 10, marginLeft: 20 }}
                    >
                      商户名称:
                    </div>
                    <Select
                      style={{ width: 280 }}
                      value={service_sub_account.id}
                      onChange={(e) => {
                        service_sub_account.id = e;
                        const valueModel = JSON.parse(JSON.stringify(newRule));
                        valueModel.service_sub_accounts[index] =
                          service_sub_account;
                        setNewRule(valueModel);
                      }}
                      options={merchantList.map((d: any) => ({
                        value: d.id,
                        label: d.merchant_name,
                      }))}
                    />
                  </div>

                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div
                      className="inputTitle"
                      style={{
                        width: 90,
                        fontSize: 14,
                        marginRight: 0,
                        marginLeft: 10,
                      }}
                    >
                      分账比例:
                    </div>
                    <InputNumber
                      maxLength={14}
                      minLength={6}
                      max="30"
                      min="0"
                      value={service_sub_account.radio}
                      precision={2}
                      onChange={(e) => {
                        if (e) {
                          service_sub_account.radio = e;
                          const valueModel = JSON.parse(
                            JSON.stringify(newRule)
                          );
                          valueModel.service_sub_accounts[index] =
                            service_sub_account;
                          setNewRule(valueModel);
                        }
                      }}
                      style={{ width: 280, marginRight: 0 }}
                      placeholder="请输入"
                      addonAfter="%"
                    />
                    <img
                      alt=""
                      style={{
                        width: 18,
                        height: 18,
                        marginLeft: 5,
                        marginTop: 5,
                        cursor: "pointer",
                      }}
                      src={require("../../../assets/icon_rDelete.png")}
                      onClick={() => {
                        const valueModel = JSON.parse(JSON.stringify(newRule));
                        const newFileList =
                          newRule.service_sub_accounts.slice();
                        newFileList.splice(index, 1);
                        valueModel.service_sub_accounts = newFileList;
                        setNewRule(valueModel);
                      }}
                    />
                  </div>
                </div>
              );
            })}

            <div
              style={{
                fontSize: 14,
                color: "#000",
                marginLeft: 100,
                marginTop: 10,
                marginBottom: 10,
              }}
            >
              {"分账比例合计：" +
                (fzCount(newRule.service_sub_accounts)
                  ? `${fzCount(newRule.service_sub_accounts)}%`
                  : "")}
            </div>
          </div>
        )}

        {newRule.rule_type === 3 && (
          <div
            style={{
              fontSize: 18,
              color: "#000",
              fontWeight: "bold",
              marginLeft: 15,
              marginTop: 10,
              marginBottom: 10,
            }}
          >
            {"总比例：" +
              (totalCount(newRule) ? `${totalCount(newRule)}%` : "")}
          </div>
        )}

        <div style={{ flexDirection: "row", display: "flex", marginTop: 50 }}>
          <div
            className="btn"
            style={{
              marginLeft: 300,
              backgroundColor: "#FFF",
              borderColor: "#D9D9D9",
              borderWidth: 0.5,
              borderStyle: "solid",
            }}
            onClick={(e) => {
              setRuleModal(false);
            }}
          >
            <div
              className="buttonTitle"
              style={{
                color: "#4E5969",
              }}
            >
              取消
            </div>
          </div>
          <div
            className="btn"
            style={{ marginLeft: 10 }}
            onClick={(e) => {
              if (!newRule.rule_name) {
                toastMessage("请输入规则名称");
                return;
              }
              if (newRule.rule_type === 1 || newRule.rule_type === 3) {
                if (newRule.system_accounts.length > 0) {
                  let isBusinessOK = false;
                  let isRadioOK = false;
                  for (
                    let index = 0;
                    index < newRule.system_accounts.length;
                    index++
                  ) {
                    const element = newRule.system_accounts[index];
                    if (!element.id) {
                      isBusinessOK = true;
                      return;
                    }
                    if (!element.radio) {
                      isRadioOK = true;

                      return;
                    }
                  }
                  if (isBusinessOK) {
                    toastMessage("请选择商户");
                    return;
                  }
                  if (isRadioOK) {
                    toastMessage("请输入分账比例");
                    return;
                  }
                } else {
                  toastMessage("请选择商户");
                  return;
                }
              } else if (newRule.rule_type === 2) {
                if (newRule.service_sub_accounts.length > 0) {
                  let isBusinessOK = false;
                  let isRadioOK = false;
                  for (
                    let index = 0;
                    index < newRule.service_sub_accounts.length;
                    index++
                  ) {
                    const element = newRule.service_sub_accounts[index];
                    if (!element.id) {
                      isBusinessOK = true;
                      return;
                    }
                    if (!element.radio) {
                      isRadioOK = true;

                      return;
                    }
                  }
                  if (isBusinessOK) {
                    toastMessage("请选择商户");
                    return;
                  }
                  if (isRadioOK) {
                    toastMessage("请输入分账比例");
                    return;
                  }
                }
              }

              if (newRule.rule_type === 2) {
                if(!newRule.distribution_sub_account_radio){
                  toastMessage('请输入分销商分账比例')
                  return
                }
                const num = fzCount(newRule.service_sub_accounts);
                if (num > 30) {
                  toastMessage("微信分账比例不得大于30%");
                  return;
                }
              } else if (newRule.rule_type === 3) {
                if(!newRule.distribution_sub_account_radio){
                  toastMessage('请输入分销商分账比例')
                  return
                }
                const num = fzCount(newRule.service_sub_accounts);
                if (num > 30) {
                  toastMessage("微信分账比例不得大于30%");
                  return;
                }
                const totalNum = totalCount(newRule);
                if (totalNum > 100) {
                  toastMessage("所有分账比例之和不得大于100%");
                  return;
                }
              }
              if (isAddRule) {
                const copyArray = JSON.parse(JSON.stringify(sub_rules));
                copyArray.push(newRule);
                console.log('isAddRuleisAddRule',copyArray );
                setSub_rules(copyArray);
              } else {
                const copyArray = JSON.parse(JSON.stringify(sub_rules));
                copyArray[editRuleNum] = newRule;
                setSub_rules(copyArray);
              }
              setRuleModal(false);
            }}
          >
            <div
              className="buttonTitle"
              style={{
                color: "#FFF",
              }}
            >
              保存
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};
export default EditStorge;
