import React, { useEffect, useState } from 'react'
import { Modal } from "antd";
import '../../../globalLess/global.css'
import { getOrderDetail } from '../../../service/index'
import { toastMessage } from '../../../utils';
interface UploadVideoProps {
    closeClick: () => void,
    order_id?: string,
}


const DownloadVideo = (props: UploadVideoProps) => {
    const { closeClick } = props;
    const { order_id } = props;

    const [editVideo, setEditVideo] = useState<any>([])
    const [download, setDownload] = useState<any>([])

    const orderDetail = (id: string) => {
        let dict = {
            id
        }
        getOrderDetail(dict).then((res: any) => {
            if (res.code === 200) {
                res.data.edited_videos && setEditVideo(res.data.edited_videos)
                if(res.data.edited_videos.length>0){
                    let isDownload:any =[]
                    for(let index=0;index<res.data.edited_videos.length;index++){
                        isDownload.push({select:false})
                    }
                    setDownload(isDownload)
                }
            }
        }).catch((err: any) => {

        })
    }
    const downloadFile = (url: string, name: string,type:number) => {
        toastMessage('视频下载中，请稍后')
        console.log('url=>',url);
        // window.open(url);
        let link = url
        let fileName = name
        let x = new XMLHttpRequest()
        x.open('GET',link,true)
        x.responseType = 'blob'
        x.onload =(e)=>{
            let url = window.URL.createObjectURL(x.response)
            let a = document.createElement('a')
            a.href = url
            a.download = fileName
            a.click()
        }
        x.onloadend=(e)=>{
            let downloadCopy = JSON.parse(JSON.stringify(download))
            let item = downloadCopy[type]
            item.select = false
            downloadCopy[type] = item
            setDownload(downloadCopy)
        }
        x.send()
    }

    

    useEffect(() => {
        if (order_id) {
            orderDetail(order_id)
        }
    }, [])
    return (
        <Modal
            style={{ background: "transparent", borderRadius: 5, }}
            closeIcon={
                <img
                    alt=''
                    src={require('../../../assets/icon_close.png')}
                    onClick={() => closeClick()}
                />
            }
            title={'下载视频'}
            width={632}
            open={true}
            footer={null}
            centered
        >
            <div style={{ fontSize: 14, fontWeight: 'bold',}}> 修片</div>

            {editVideo.map((item: any, index: number) => {
                return <div key={index} style={{ flexDirection: 'row', display: 'flex', marginTop: 10 }}  >
                    <div style={{ fontSize: 14, marginRight: 20 }}>{item.originname
                    }</div>
                    <div style={{ flex: 1 }} />
                    <div className='button' style={{ marginLeft: 20, backgroundColor:download[index].select?'#F3F4F5':'#FB186A', marginTop: 0, width: 70, height: 28 }} onClick={() => {
                        let downloadCopy = JSON.parse(JSON.stringify(download))
                        let aItem = downloadCopy[index]
                        aItem.select = true
                        downloadCopy[index] = aItem
                        setDownload(downloadCopy)
                        downloadFile(item.url, item.originname,index)
                    }}>
                        <div className='buttonTitle' style={{ color: download[index].select?'#999':'#FFF' }}>
                            {download[index].select?'下载中...':'下载'}
                        </div>
                    </div>
                </div>
            })}
          
        </Modal>
    )
}
export default DownloadVideo