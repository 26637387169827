import React, { useEffect, useState, useRef } from "react";
import { Table, Modal, Input, Select, Button, Checkbox } from "antd";
import "../../globalLess/global.css";
import "../../components/Layout/index.less";
import {
  toastErrorMessage,
  toastMessage,
  toastSuccessMessage,
} from "../../utils";
import {
  deleteMerchant,
  addMerchant,
  getMerchantList,
  editMerchant,
} from "../../service";
import type { CheckboxChangeEvent } from "antd/es/checkbox";
import moment from "moment";
import QRCode from "qrcode.react";
import html2canvas from "html2canvas";
import { saveAs } from 'file-saver';

const { confirm } = Modal;

type BusinessType = {
  id?: string;
  account: string;
  password: string;
  merchant_type: string;
  merchant_code?: string;
  merchant_phone?: string;
  merchant_name: string;
  is_generate_code: boolean;
};
const defaultBusiness = {
  account: "",
  password: "",
  merchant_type: "1",
  merchant_name: "",
  is_generate_code: false,
};

const BusinessManager = (): React.ReactElement => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [total, setTotal] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(false);
  const [dataSource, setDataSource] = useState<any>([]);
  const [isAddBusiness, setIsAddBusiness] = useState(false);
  const [isEditBusiness, setIsEditBusiness] = useState(false);
  const [isQueryBusiness, setIsQueryBusiness] = useState(false);
  const [business, setBusiness] = useState<BusinessType>(defaultBusiness);
  const [merchant_type, setMerchant_type] = useState("0");
  const [codeValue, setCodeValue] = useState("");
  const [isShowCode, setIsShowCode] = useState(false);
  const [content, setContent] = useState("");
  const captureRef = useRef(null);
  const columns: any = [
    {
      title: "商户号/手机号",
      dataIndex: "merchant_type",
      width: 100,
      align: "center",
      ellipsis: true,
      render: (merchant_type: string, record: any) => {
        const showValue =
          merchant_type === "1" ? record.merchant_code : record.merchant_phone;
        return <div>{showValue}</div>;
      },
    },
    {
      title: "商户类型",
      dataIndex: "merchant_type",
      width: 100,
      align: "center",
      ellipsis: true,
      render: (merchant_type: string) => (
        <div>{merchant_type === "1" ? "商户" : "个体户"}</div>
      ),
    },
    {
      title: "商户名称",
      dataIndex: "merchant_name",
      width: 100,
      align: "center",
      ellipsis: true,
    },
    {
      title: "系统账号",
      dataIndex: "account",
      width: 100,
      align: "center",
      ellipsis: true,
    },
    {
      title: "创建时间",
      dataIndex: "create_time",
      width: 100,
      align: "center",
      ellipsis: true,
      render: (create_time: string) => (
        <div>{moment(create_time).format("YYYY-MM-DD HH:mm:ss")}</div>
      ),
    },
    {
      title: "口令/二维码",
      dataIndex: "generate_code",
      width: 100,
      align: "center",
      ellipsis: true,
      render: (generate_code: string, record:any) => (
        <div
          style={{ color: "#1890FF", cursor: "pointer" }}
          onClick={() => {
            if(record.is_has_generate_code === 1){
              setCodeValue(generate_code);
              setIsShowCode(true);
            }
          }}
        >
          {(record.is_has_generate_code === 1)? "预览" : "一"}
        </div>
      ),
    },
    {
      title: "操作",
      width: 120,
      align: "center",
      dataIndex: "id",
      render: (id: string, record: any) => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <div style={{ flex: 1, display: "flex" }} />
          <div
            style={{
              color: "#1890FF",
              cursor: "pointer",
              marginLeft: 10,
              marginRight: 10,
            }}
            onClick={() => {
              setBusiness({
                id: record.id,
                account: record.account,
                password: record.password,
                merchant_type: record.merchant_type,
                merchant_code: record.merchant_code,
                merchant_phone: record.merchant_phone,
                merchant_name: record.merchant_name,
                is_generate_code: record.generate_code ? true : false,
              });
              setIsQueryBusiness(true);
            }}
          >
            详情
          </div>
          <div
            style={{
              color: "#1890FF",
              cursor: "pointer",
              marginLeft: 10,
              marginRight: 10,
            }}
            onClick={() => {
              setBusiness({
                id: record.id,
                account: record.account,
                password: record.password,
                merchant_type: record.merchant_type,
                merchant_code: record.merchant_code,
                merchant_phone: record.merchant_phone,
                merchant_name: record.merchant_name,
                is_generate_code: record.generate_code ? true : false,
              });
              setIsEditBusiness(true);
            }}
          >
            编辑
          </div>
          <div
            style={{
              color: "#FB186A",
              cursor: "pointer",
              marginLeft: 10,
              marginRight: 10,
            }}
            onClick={() => {
              confirm({
                title: "提示",
                content: "确定删除该商户？",
                onOk() {
                  deleteModel(id);
                },
                onCancel() {},
              });
            }}
          >
            删除
          </div>
          <div style={{ flex: 1, display: "flex" }} />
        </div>
      ),
    },
  ];

  const onChange = (e: CheckboxChangeEvent) => {
    const value = JSON.parse(JSON.stringify(business));
    value.is_generate_code = e.target.checked;
    setBusiness(value);
  };

  const deleteModel = (id?: string) => {
    let dict: any = { id };
    deleteMerchant(dict)
      .then((response) => {
        if (response.code === 200) {
          toastSuccessMessage("删除成功");
          onFinish(currentPage, pageSize);
        }else if(response.code === 99){
          confirm({
            title: "提示",
            content: "该商户已被添加为分账对象，请解除关联后再操作？",
            onOk() {
            },
            onCancel() {},
          });

        }
      })
      .catch((err) => {});
  };

  const onFinish = async (
    page_num: number,
    page_size: number,
    isReset?: string
  ) => {
    let dict: any = { page_num, page_size };
    if (isReset) {
      dict.merchant_type = "0";
    } else {
      dict.merchant_type = merchant_type;
      if (content) {
        dict.content = content;
      }
    }
    setCurrentPage(page_num);
    setPageSize(page_size);
    setIsLoading(true);
    await getMerchantList(dict)
      .then((response) => {
        if (response.code === 200) {
          setTotal(response.data.totalItems);
          setDataSource(response.data.items);
        } else {
          toastErrorMessage(response.msg);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const handleChange = (value: string) => {
    const valueModel = JSON.parse(JSON.stringify(business));
    valueModel.merchant_type = value;
    setBusiness(valueModel);
  };
  const validatePassword = (password: any) => {
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{6,14}$/;
    return regex.test(password);
  };
  const isValidPhoneNumber = (phoneNumber: any) => {
    return /^1[3-9]\d{9}$/.test(phoneNumber);
  };

  const addBusiness = async () => {
    console.log("business", business);
    if (!business.account) {
      toastMessage("请输入系统账号");
      return;
    }
    if (!business.password) {
      toastMessage("请输入登录密码");
      return;
    }
    if (!validatePassword(business.password)) {
      toastMessage(" 密码必须是6~14位大写字母、小写字母和数字组合");
      return;
    }
    if (business.merchant_type === "1") {
      if (!business.merchant_code) {
        toastMessage("请输入商户号");
        return;
      }
    } else if (business.merchant_type === "2") {
      if (!business.merchant_phone) {
        toastMessage("请输入手机号");
        return;
      } else if (!isValidPhoneNumber(business.merchant_phone)) {
        toastMessage("请输入正确手机号");
        return;
      }
    }
    if (!business.merchant_name) {
      toastMessage("请输入商户名称");
      return;
    }
    const requestDict: BusinessType = {
      account: business.account,
      password: business.password,
      merchant_type: business.merchant_type,
      merchant_name: business.merchant_name,
      is_generate_code: business.is_generate_code,
    };
    if (requestDict.merchant_type === "1") {
      requestDict.merchant_code = business.merchant_code;
    } else if (requestDict.merchant_type === "2") {
      requestDict.merchant_phone = business.merchant_phone;
    }

    await addMerchant(requestDict)
      .then((res) => {
        if (res.code === 200) {
          toastSuccessMessage("添加成功");
          onFinish(1, 10);
          setBusiness(defaultBusiness);
          setIsAddBusiness(false);
        } else {
          toastMessage(res.msg);
        }
      })
      .catch((err) => {
        toastErrorMessage(err.msg);
      });
  };

  const editBusiness = async () => {
    if (!business.account) {
      toastMessage("请输入系统账号");
      return;
    }
    if (!business.password) {
      toastMessage("请输入登录密码");
      return;
    }
    if (!validatePassword(business.password)) {
      toastMessage(" 密码必须是6~14位大写字母、小写字母和数字组合");
      return;
    }
    if (business.merchant_type === "1") {
      if (!business.merchant_code) {
        toastMessage("请输入商户号");
        return;
      }
    } else if (business.merchant_type === "2") {
      if (!business.merchant_phone) {
        toastMessage("请输入手机号");
        return;
      } else if (!isValidPhoneNumber(business.merchant_phone)) {
        toastMessage("请输入正确手机号");
        return;
      }
    }
    if (!business.merchant_name) {
      toastMessage("请输入商户名称");
      return;
    }
    const requestDict: any = {
      id: business.id,
      account: business.account,
      password: business.password,
      merchant_type: business.merchant_type,
      merchant_name: business.merchant_name,
      is_generate_code: business.is_generate_code,
    };
    if (requestDict.merchant_type === "1") {
      requestDict.merchant_code = business.merchant_code;
    } else if (requestDict.merchant_type === "2") {
      requestDict.merchant_phone = business.merchant_phone;
    }

    await editMerchant(requestDict)
      .then((res) => {
        if (res.code === 200) {
          toastSuccessMessage("编辑成功");
          onFinish(1, 10);
          setBusiness(defaultBusiness);
          setIsEditBusiness(false);
        } else {
          toastMessage(res.msg);
        }
      })
      .catch((err) => {
        toastErrorMessage(err.msg);
      });
  };

  const handleCapture = async () => {
    try {
      const node: any = captureRef.current;
      const canvas = await html2canvas(node);
      const img = canvas.toDataURL("image/png");
      const blob = fetch(img)
      .then(res => res.blob())
      .then(blob => new File([blob], "image.png", { type: "image/png" }));
    blob.then(file => saveAs(file));
    } catch (error) {
      console.error("Error capturing screen:", error);
    }
  };

  useEffect(() => {
    onFinish(1, 10);
  }, []);

  const handleShowTotal = (total: number, range: number[]) => {
    const totalPage = Math.ceil(total / pageSize);
    return (
      <div style={{ flex: 1, display: "flex", color: "#999999" }}>
        {`共${total}条记录，第${currentPage}/${totalPage}页`}
      </div>
    );
  };

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <div className="ProductionTableStyle">
        <div
          style={{
            paddingTop: 10,
            flex: 1,
            flexDirection: "row",
            display: "flex",
            backgroundColor: "#FFF",
          }}
        >
          <div style={{ width: 24, height: 1 }} />

          <div style={{ fontSize: 14, marginTop: 18 }}>商户类型：</div>
          <Select
            style={{ width: 166, marginTop: 13 }}
            onChange={(value) => {
              setMerchant_type(value);
            }}
            value={merchant_type}
            options={[
              {
                value: "0",
                label: "全部",
              },
              {
                value: "1",
                label: "商户",
              },
              {
                value: "2",
                label: "个体",
              },
            ]}
          />

          <Input
            style={{
              width: 200,
              height: 32,
              marginRight: 20,
              marginLeft: 20,
              marginTop: 12,
            }}
            value={content}
            placeholder="商户号/手机号/商户名称"
            onChange={(e) => {
              setContent(e.target.value);
            }}
          />
          <div
            className="button"
            style={{ backgroundColor: "#FB186A", marginTop: 12 }}
            onClick={(e) => {
              onFinish(1, pageSize);
            }}
          >
            <div className="buttonTitle" style={{ color: "#FFF" }}>
              查询
            </div>
          </div>
          <div
            className="button"
            style={{
              borderStyle: "solid",
              borderWidth: 1,
              borderColor: "#FB186A",
              marginLeft: 20,
              marginTop: 12,
            }}
            onClick={() => {
              setMerchant_type("0");
              setContent("");
              onFinish(1, pageSize, 'true');
            }}
          >
            <div className="buttonTitle">重置</div>
          </div>
          <div style={{ flex: 1 }} />

          <div
            className="button"
            style={{
              width: 96,
              backgroundColor: "#FB186A",
              marginLeft: 20,
              marginTop: 12,
              marginRight: 20,
            }}
            onClick={(e) => {
              setBusiness(defaultBusiness)
              setIsAddBusiness(true);
            }}
          >
            <img
              alt=""
              style={{ width: 13, height: 13, marginRight: 5 }}
              src={require("../../assets/icon_add.png")}
            />
            <div
              className="buttonTitle"
              style={{ color: "#FFF", fontSize: 14, paddingTop: 2 }}
            >
              新增
            </div>
          </div>
        </div>
      </div>

      <div style={{ backgroundColor: "#FFF" }}>
        <div style={{ padding: 20 }}>
          <Table
            className={"commonTable"}
            columns={columns}
            dataSource={dataSource}
            loading={isLoading}
            scroll={{ x: 1200 }}
            pagination={{
              position: ["bottomRight"],
              defaultCurrent: 1,
              defaultPageSize: 10,
              current: currentPage,
              pageSize: pageSize,
              showSizeChanger: true,
              total: total,
              showQuickJumper: true,
              onChange: onFinish,
              showTotal: handleShowTotal,
            }}
          />
        </div>
      </div>
      <Modal
        width={832}
        style={{ marginTop: 100 }}
        title={
          <div className="alert-title-bkg">
            <div className="alert-title">
              <div className="alert-title-icon" />
              <div className="alert-title-text">新增商户</div>
            </div>
            <div className="alert-line"></div>
          </div>
        }
        open={isAddBusiness}
        onOk={() => {
          setIsAddBusiness(false);
        }}
        onCancel={() => {
          setIsAddBusiness(false);
        }}
        footer={null}
      >
        <div style={{ height: 280 }}>
          <div style={{ fontSize: 16, color: "#000", fontWeight: 400 }}>
            系统信息
          </div>
          <div style={{ display: "flex", flexDirection: "row", marginTop: 10 }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div
                className="inputTitle"
                style={{ fontSize: 14, marginRight: 10, marginLeft: 20 }}
              >
                *系统账号:
              </div>
              <Input
                className="textInputStr"
                maxLength={20}
                value={business.account}
                style={{ width: 280, marginRight: 0 }}
                placeholder="请输入"
                onChange={(e) => {
                  const value = JSON.parse(JSON.stringify(business));
                  value.account = e.target.value;
                  setBusiness(value);
                }}
              />
            </div>

            <div style={{ display: "flex", flexDirection: "row" }}>
              <div
                className="inputTitle"
                style={{
                  width: 90,
                  fontSize: 14,
                  marginRight: 10,
                  marginLeft: 20,
                }}
              >
                *登录密码:
              </div>

              <Input.Group compact>
                <Input
                  className="textInputStr"
                  maxLength={14}
                  minLength={6}
                  type="password"
                  value={business.password}
                  style={{ width: 285, marginRight: 0 }}
                  placeholder="请输入"
                  onChange={(e) => {
                    const value = JSON.parse(JSON.stringify(business));
                    value.password = e.target.value;
                    setBusiness(value);
                  }}
                />
              </Input.Group>
            </div>
          </div>

          <div
            style={{
              marginTop: 10,
              fontSize: 16,
              color: "#000",
              fontWeight: 400,
            }}
          >
            分账信息
          </div>

          <div style={{ display: "flex", flexDirection: "row", marginTop: 10 }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div
                className="inputTitle"
                style={{ fontSize: 14, marginRight: 10, marginLeft: 20 }}
              >
                *商户类型:
              </div>
              <Select
                style={{ width: 280 }}
                onChange={handleChange}
                value={business.merchant_type}
                options={[
                  {
                    value: "1",
                    label: "商户",
                  },
                  {
                    value: "2",
                    label: "个体",
                  },
                ]}
              />
            </div>

            <div style={{ display: "flex", flexDirection: "row" }}>
              <div
                className="inputTitle"
                style={{ fontSize: 14, marginRight: 10, marginLeft: 20 }}
              >
                 {business.merchant_type==='1'? '*商户号:' :'*手机号:'} 
              </div>
              <Input
                className="textInputStr"
                maxLength={20}
                style={{ width: 280, marginRight: 0 }}
                placeholder="请输入"
                value={business.merchant_type==='1'?business.merchant_code: business.merchant_phone}
                onChange={(e) => {
                  const value = JSON.parse(JSON.stringify(business));
                  if (value.merchant_type === "1") {
                    value.merchant_code = e.target.value;
                  } else if (value.merchant_type === "2") {
                    value.merchant_phone = e.target.value;
                  }
                  setBusiness(value);
                }}
              />
            </div>
          </div>

          <div style={{ display: "flex", flexDirection: "row", marginTop: 20 }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div
                className="inputTitle"
                style={{ fontSize: 14, marginRight: 10, marginLeft: 20 }}
              >
                *商户名称:
              </div>
              <Input
                className="textInputStr"
                maxLength={20}
                style={{ width: 280, marginRight: 0 }}
                placeholder="请输入"
                value={business.merchant_name}
                onChange={(e) => {
                  const value = JSON.parse(JSON.stringify(business));
                  value.merchant_name = e.target.value;
                  setBusiness(value);
                }}
              />
            </div>

            <Checkbox
              checked={business.is_generate_code}
              style={{ marginLeft: 100, marginTop: 5 }}
              onChange={onChange}
            >
              生成口令/二维码
            </Checkbox>
          </div>

          <div style={{ flexDirection: "row", display: "flex", marginTop: 50 }}>
            <div
              className="btn"
              style={{
                marginLeft: 300,
                backgroundColor: "#FFF",
                borderColor: "#D9D9D9",
                borderWidth: 0.5,
                borderStyle: "solid",
              }}
              onClick={(e) => {
                setIsAddBusiness(false);
              }}
            >
              <div
                className="buttonTitle"
                style={{
                  color: "#4E5969",
                }}
              >
                取消
              </div>
            </div>
            <div
              className="btn"
              style={{ marginLeft: 10 }}
              onClick={(e) => {
                addBusiness();
              }}
            >
              <div
                className="buttonTitle"
                style={{
                  color: "#FFF",
                }}
              >
                保存
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        width={832}
        style={{ marginTop: 100 }}
        title={
          <div className="alert-title-bkg">
            <div className="alert-title">
              <div className="alert-title-icon" />
              <div className="alert-title-text">编辑商户</div>
            </div>
            <div className="alert-line"></div>
          </div>
        }
        open={isEditBusiness}
        onOk={() => {
          setIsEditBusiness(false);
        }}
        onCancel={() => {
          setIsEditBusiness(false);
        }}
        footer={null}
      >
        <div style={{ height: 280 }}>
          <div style={{ fontSize: 16, color: "#000", fontWeight: 400 }}>
            系统信息
          </div>
          <div style={{ display: "flex", flexDirection: "row", marginTop: 10 }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div
                className="inputTitle"
                style={{ fontSize: 14, marginRight: 10, marginLeft: 20 }}
              >
                *系统账号:
              </div>
              <Input
                className="textInputStr"
                maxLength={20}
                value={business.account}
                style={{ width: 280, marginRight: 0 }}
                placeholder="请输入"
                onChange={(e) => {
                  const value = JSON.parse(JSON.stringify(business));
                  value.account = e.target.value;
                  setBusiness(value);
                }}
              />
            </div>

            <div style={{ display: "flex", flexDirection: "row" }}>
              <div
                className="inputTitle"
                style={{
                  width: 90,
                  fontSize: 14,
                  marginRight: 10,
                  marginLeft: 20,
                }}
              >
                *登录密码:
              </div>
              <Input.Group compact>
                <Input
                  className="textInputStr"
                  maxLength={14}
                  minLength={6}
                  value={business.password}
                  type="password"
                  style={{ width: 220, marginRight: 0 }}
                  placeholder="请输入"
                  onChange={(e) => {
                    const value = JSON.parse(JSON.stringify(business));
                    value.password = e.target.value;
                    setBusiness(value);
                  }}
                />
                <Button
                  type="primary"
                  onClick={() => {
                    const value = JSON.parse(JSON.stringify(business));
                    value.password = "";
                    setBusiness(value);
                  }}
                >
                  重置
                </Button>
              </Input.Group>
            </div>
          </div>
          <div
            style={{
              marginTop: 10,
              fontSize: 16,
              color: "#000",
              fontWeight: 400,
            }}
          >
            分账信息
          </div>
          <div style={{ display: "flex", flexDirection: "row", marginTop: 10 }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div
                className="inputTitle"
                style={{ fontSize: 14, marginRight: 10, marginLeft: 20 }}
              >
                *商户类型:
              </div>
              <Select
              disabled
                style={{ width: 280 }}
                onChange={handleChange}
                value={business.merchant_type}
                options={[
                  {
                    value: "1",
                    label: "商户",
                  },
                  {
                    value: "2",
                    label: "个体",
                  },
                ]}
              />
            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div
                className="inputTitle"
                style={{ fontSize: 14, marginRight: 10, marginLeft: 20 }}
              >
                {business.merchant_type==='1'? '*商户号:' :'*手机号:'} 
              </div>
              <Input
              disabled
                className="textInputStr"
                maxLength={20}
                value={
                  business.merchant_type === "1"
                    ? business.merchant_code
                    : business.merchant_phone
                }
                style={{ width: 280, marginRight: 0 }}
                placeholder="请输入"
                onChange={(e) => {
                  const value = JSON.parse(JSON.stringify(business));
                  if (value.merchant_type === "1") {
                    value.merchant_code = e.target.value;
                  } else if (value.merchant_type === "2") {
                    value.merchant_phone = e.target.value;
                  }
                  setBusiness(value);
                }}
              />
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "row", marginTop: 20 }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div
                className="inputTitle"
                style={{ fontSize: 14, marginRight: 10, marginLeft: 20 }}
              >
                *商户名称:
              </div>
              <Input
              disabled
                className="textInputStr"
                maxLength={20}
                value={business.merchant_name}
                style={{ width: 280, marginRight: 0 }}
                placeholder="请输入"
                onChange={(e) => {
                  const value = JSON.parse(JSON.stringify(business));
                  value.merchant_name = e.target.value;
                  setBusiness(value);
                }}
              />
            </div>

            <Checkbox
              style={{ marginLeft: 100, marginTop: 5 }}
              checked={business.is_generate_code}
              onChange={onChange}
            >
              生成口令/二维码
            </Checkbox>
          </div>
          <div style={{ flexDirection: "row", display: "flex", marginTop: 50 }}>
            <div
              className="btn"
              style={{
                marginLeft: 300,
                backgroundColor: "#FFF",
                borderColor: "#D9D9D9",
                borderWidth: 0.5,
                borderStyle: "solid",
              }}
              onClick={(e) => {
                setIsEditBusiness(false);
              }}
            >
              <div
                className="buttonTitle"
                style={{
                  color: "#4E5969",
                }}
              >
                取消
              </div>
            </div>
            <div
              className="btn"
              style={{ marginLeft: 10 }}
              onClick={(e) => {
                editBusiness();
              }}
            >
              <div
                className="buttonTitle"
                style={{
                  color: "#FFF",
                }}
              >
                保存
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        width={832}
        style={{ marginTop: 100 }}
        title={
          <div className="alert-title-bkg">
            <div className="alert-title">
              <div className="alert-title-icon" />
              <div className="alert-title-text">查看商户</div>
            </div>
            <div className="alert-line"></div>
          </div>
        }
        open={isQueryBusiness}
        onOk={() => {
          setIsQueryBusiness(false);
        }}
        onCancel={() => {
          setBusiness(defaultBusiness);
          setIsQueryBusiness(false);
        }}
        footer={null}
      >
        <div style={{ height: 280 }}>
          <div style={{ fontSize: 16, color: "#000", fontWeight: 400 }}>
            系统信息
          </div>
          <div style={{ display: "flex", flexDirection: "row", marginTop: 10 }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div
                className="inputTitle"
                style={{ fontSize: 14, marginRight: 10, marginLeft: 20 }}
              >
                *系统账号:
              </div>
              <Input
                className="textInputStr"
                maxLength={20}
                value={business.account}
                style={{ width: 280, marginRight: 0 }}
                placeholder="请输入"
                disabled
              />
            </div>

            <div style={{ display: "flex", flexDirection: "row" }}>
              <div
                className="inputTitle"
                style={{
                  width: 90,
                  fontSize: 14,
                  marginRight: 10,
                  marginLeft: 20,
                }}
              >
                *登录密码:
              </div>

              <Input.Group compact>
                <Input
                  className="textInputStr"
                  maxLength={14}
                  minLength={6}
                  value={business.password}
                  type="password"
                  style={{ width: 285, marginRight: 0 }}
                  placeholder="请输入"
                  disabled
                />
              </Input.Group>
            </div>
          </div>

          <div
            style={{
              marginTop: 10,
              fontSize: 16,
              color: "#000",
              fontWeight: 400,
            }}
          >
            分账信息
          </div>

          <div style={{ display: "flex", flexDirection: "row", marginTop: 10 }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div
                className="inputTitle"
                style={{ fontSize: 14, marginRight: 10, marginLeft: 20 }}
              >
                *商户类型:
              </div>
              <Select
                style={{ width: 280 }}
                disabled
                value={business.merchant_type}
                options={[
                  {
                    value: "1",
                    label: "商户",
                  },
                  {
                    value: "2",
                    label: "个体",
                  },
                ]}
              />
            </div>

            <div style={{ display: "flex", flexDirection: "row" }}>
              <div
                className="inputTitle"
                style={{ fontSize: 14, marginRight: 10, marginLeft: 20 }}
              >
                  {business.merchant_type==='1'? '*商户号:' :'*手机号:'} 
              </div>
              <Input
                className="textInputStr"
                maxLength={20}
                value={
                  business.merchant_type === "1"
                    ? business.merchant_code
                    : business.merchant_phone
                }
                style={{ width: 280, marginRight: 0 }}
                placeholder="请输入"
                disabled
              />
            </div>
          </div>

          <div style={{ display: "flex", flexDirection: "row", marginTop: 20 }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div
                className="inputTitle"
                style={{ fontSize: 14, marginRight: 10, marginLeft: 20 }}
              >
                *商户名称:
              </div>
              <Input
                className="textInputStr"
                maxLength={20}
                value={business.merchant_name}
                style={{ width: 280, marginRight: 0 }}
                placeholder="请输入"
                disabled
              />
            </div>

            <Checkbox
              style={{ marginLeft: 100, marginTop: 5 }}
              checked={business.is_generate_code}
              disabled
            >
              生成口令/二维码
            </Checkbox>
          </div>
        </div>
      </Modal>
     
        <Modal
          width={260}
          style={{ marginTop: 100 }}
          open={isShowCode}
          onOk={() => {
            setIsShowCode(false);
          }}
          onCancel={() => {
            setIsShowCode(false);
          }}
          footer={null}
        >
           <div ref={captureRef}>
          <div
            style={{
              fontSize: 16,
              fontWeight: 400,
              marginTop: 10,
              marginBottom: 10,
              textAlign: "center",
            }}
          >{`口令：${codeValue}`}</div>
          <QRCode
            id="qrCode"
            value={codeValue}
            size={160} // 二维码的大小
            fgColor="#000000" // 二维码的颜色
            style={{ margin: "auto", marginLeft: 25 }}
            imageSettings={{
              // 二维码中间的logo图片
              src: "logoUrl",
              height: 80,
              width: 80,
              excavate: true, // 中间图片所在的位置是否镂空
            }}
          />
          <Button
            type="primary"
            style={{ marginLeft: 60, marginTop: 10 }}
            shape="round"
            onClick={handleCapture}
          >
            点击下载
          </Button>
          </div>
        </Modal>
    </div>
  );
};
export default BusinessManager;
