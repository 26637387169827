import React, { useEffect, useState } from "react";
import { Input,  Dropdown ,InputNumber} from 'antd';
import '../../../globalLess/global.css'
import '../../../components/Layout/index.less';
import { UploadOutlined } from '@ant-design/icons';
import { Button, Upload, Switch, Menu } from 'antd';
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface';
import {  getModelDetail, presign, uploadVideo, getShopList } from '../../../service/index'
import { toastMessage, upload_file, } from "../../../utils"
import { useLocation } from "react-router-dom";
import BraftEditor from 'braft-editor'
import 'braft-editor/dist/index.css'

const { TextArea } = Input;

const QueryModel = (): React.ReactElement => {
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const [imageFileList, setImageFileList] = useState<UploadFile[]>([
    ]);
    const [modelFileId, setModelFileId] = useState('')
    const [imageFileId, setImageFileId] = useState('')
    const [modelTitle, setModelTitle] = useState('')
    const [modelDes, setModelDes] = useState('')
    const [modelPrice, setModelPrice] = useState(0)
    const [is_use, setIs_use] = useState(1)
    const [photo_data, setPhoto_data] = useState<any>(null)
    const [dataSource, setDataSource] = useState<any>()
    const [menuData, setMenuData] = useState<any>([])
    const [shop,setShop] = useState('请选择')
    const [shopId,setShopId] = useState('')
    const [template_command,setTemplate_command] = useState('')
    const [cut_command,setCut_command] = useState('')
    const[id,setId] =useState('')
    const [record_duration, setRecord_duration] = useState<any>()
    const [count_down, setCount_down] = useState<number>(5)

    
    let location = useLocation()
    const props: UploadProps = {
        onRemove: (file) => {
            setFileList([]);
        },
        beforeUpload: async (file) => {
            if (file.size > 1024 * 1024 * 20) {
                toastMessage('单个视频文件最大不可以超过20MB')
                return
            }
            uploadFile([file])
            return false
        },
        fileList
    };

    const handleMenuClick = (e: any) => {
        // const num: number = parseInt(e.key)
        console.log('2222',e);
        // setSelectMenuItem(num)
        menuData.map((item:any)=>{
            if(item.key === e.key){
                setShop(item.label)
                setShopId(item.id)
            }
        })
    }

    const menu = (
        <Menu onClick={handleMenuClick}>
            {menuData.map((item:any, index:any) => {
                return <Menu.Item key={`${index}`}>
                    {item.label}
                </Menu.Item>
            })}
        </Menu>
    );


    const uploadFile = (file: any) => {
        let dict = {
            size: file[0].size,
            originname: file[0].name,
            mimetype: file[0].type
        }
        presign(dict).then(res => {
            if (res.code === 200) {
                setModelFileId(res.data.id)
                setFileList(file);
                upload_file(res.data.presign_url.url, res.data.presign_url.fields, file[0])
            }
        }).catch(err => {
        })
    }

    const uploadImageFile = (file: any) => {
        let dict = {
            size: file[0].size,
            originname: file[0].name,
            mimetype: file[0].type
        }
        presign(dict).then(res => {
            if (res.code === 200) {
                setImageFileId(res.data.id)
                upload_file(res.data.presign_url.url, res.data.presign_url.fields, file[0])
            }
        }).catch(err => {
        })
    }
    const onChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
        setImageFileList(newFileList);
    };
    const onImageChange: UploadProps['beforeUpload'] = (e: any) => {
        uploadImageFile([e])
        return false
    };
    const onImageRemove = () => {
        setImageFileId('')
    }
    const onPreview = async (file: UploadFile) => {
        let src = file.url as string;
        if (!src) {
            src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj as RcFile);
                reader.onload = () => resolve(reader.result as string);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow?.document.write(image.outerHTML);
    };

    const upload_file2 = (url: string, data: any, file: any, param: any, imageURL: string) => {
        const formData: any = new FormData()
        for (const key in data) {
            formData.append(key,data[key])
        }
        formData.append('file', file)
        uploadVideo(url, formData).then(res => {
            setTimeout(() => {
                param.success({
                    url: imageURL,
                    meta: {
                        id: '123',
                        title: '3232',
                        alt: '123213',
                        loop: true, // 指定音视频是否循环播放
                        autoPlay: true, // 指定音视频是否自动播放
                        controls: true, // 指定音视频是否显示控制栏
                        poster: imageURL, // 指定视频播放器的封面
                    }
                })
            }, 1000);

        }).catch(err => {

        })
    }
    const modelDetail = (id: string) => {
        let dict = {
            id
        }
        getModelDetail(dict).then((res: any) => {
            if (res.code === 200) {
                setDataSource(res.data)
                let videoData = res.data.video
                videoData.name = res.data.video.originname
                setFileList([videoData])
                let coverData = res.data.cover
                coverData.name = res.data.cover.filename
                setImageFileList([coverData])
                setModelFileId(res.data.video.id)
                setImageFileId(res.data.cover.id)
                setModelTitle(res.data.title)
                res.data.desc && setModelDes(res.data.desc)
                setModelPrice(res.data.price)
                setIs_use(res.data.is_use)
                setPhoto_data(BraftEditor.createEditorState(res.data.key_point))
                setShop(res.data.shop.name)
                setShopId(res.data.shop.id)
                setTemplate_command(res.data.template_command)
                setCut_command(res.data.cut_command)
                setId(res.data.id)
                setRecord_duration(res.data.record_duration)
                setCount_down(res.data.count_down)
            }
        }).catch((err: any) => {

        })
    }
    const myUploadFn = (param: any) => {
        let dict = {
            size: param.file.size,
            originname: param.file.name,
            mimetype: param.file.type,
            unChanged: 1
        }
        let imageURL = ''
        presign(dict).then(res => {
            if (res.code === 200) {
                imageURL = res.data.url
                upload_file2(res.data.presign_url.url, res.data.presign_url.fields, param.file, param, imageURL)
            }
        }).catch(err => {
        })

    }


    const getShopListData = () => {
        getShopList({ page_num: 1, page_size: 1000 }).then(response => {
            if (response.code === 200) {
                
                if(response.data.items.length >0){
                    let List: any =[]
                    for(let index=0;index<response.data.items.length;index++){
                        const item = response.data.items[index]
                        List.push({
                            id:item.id,
                            label: item.name,
                            key: index+''
                        })
                    }
                    setMenuData(List)
                }

            }

        }).catch(err => {

        })
    }

    useEffect(() => {
        getShopListData()
        // eslint-disable-next-line array-callback-return
        if (location && location.state && location.state.id) {
            modelDetail(location.state.id)
        }

    }, [])

    return (
        <div style={{ height: '100%', width: '100%' }}>
            <div style={{ backgroundColor: '#FFF', width: '100%', height: 1300 }}>

                <div style={{ display: 'flex', flexDirection: 'column', height: 425, marginLeft: 130 }}>


                    <div style={{ flex: 1, marginTop: 45, flexDirection: 'row', display: 'flex', backgroundColor: '#FFF', }}>

                        <div className='inputTitle' style={{ fontSize: 16, marginLeft: 0, marginRight: -5, width: 100 }}>适用门店：</div>
                        <Dropdown disabled overlay={menu} >
                            <Button style={{marginLeft:0,width: 560, marginTop: 0 ,flexDirection: 'row', display: 'flex',}}>
                                {shop}
                                <div style={{ flex: 1, display: 'flex' }} />
                                <img style={{ marginRight: 0, width: 30, height: 30,marginTop: -3}}
                                    src={require('../../../assets/icon_expanded.png')}
                                    alt=""
                                />
                            </Button>
                        </Dropdown>
                    </div>
                    <div className='inputStyle' style={{ marginTop: 30 }}
                    >
                        <div
                            className='inputTitle' style={{ fontSize: 16, marginRight: -5, width: 100 }}
                        >模板标题：</div>
                        <Input
                            placeholder="请输入模板标题"
                            className='textInputStr'
                            disabled
                            style={{ width: 560 }}
                            value={modelTitle}
                            showCount
                            maxLength={20}
                            onChange={(e) => {
                                setModelTitle(e.target.value)
                            }}
                        />
                    </div>

                    <div className='inputStyle' style={{ marginTop: 30 }}
                    >
                        <div
                            className='inputTitle' style={{ fontSize: 16, marginLeft: -4, marginRight: -10, width: 110, marginTop: -40 }}
                        >模板链接：</div>
                        <div style={{ width: 560, height: 70 }}>
                            <Upload {...props}      disabled    fileList={fileList} maxCount={1} accept='.gif,.mp4,.mkv,.avi,.rmvb' >
                                <Button icon={<UploadOutlined />} style={{ width: 560 }}>上传本地文件</Button>
                            </Upload>
                        </div>

                    </div>
                    <div style={{ width: 650, height: 120, marginTop: 5, alignItems: 'left', display: 'flex', flexDirection: 'row' }}>

                        <div
                            className='inputTitle' style={{ fontSize: 16, marginLeft: 32, marginRight: -5, marginTop: -80 }}
                        >封面：</div>
                        <div>
                            <Upload
                            disabled
                                listType="picture-card"
                                fileList={imageFileList}
                                beforeUpload={onImageChange}
                                onChange={onChange}
                                onPreview={onPreview}
                                onRemove={onImageRemove}
                            >
                                {imageFileList.length < 1 && '+ 上传'}
                            </Upload>
                        </div>
                    </div>


                    <div className='inputStyle' style={{ marginTop: 30 }}
                    >
                        <div
                            className='inputTitle' style={{ marginTop: -45, fontSize: 16, marginLeft: -35, marginRight: 3, width: 130 }}
                        >模板简介(选填)：</div>
                        <TextArea
                            placeholder="请输入模板简介"
                            value={modelDes}
                            maxLength={50}
                            rows={2}
                            disabled
                            showCount
                            className='textInputStr'
                            style={{ width: 560, resize: 'none' }}
                            onChange={(e) => {
                                setModelDes(e.target.value)
                            }}
                        />
                    </div>

                    <div className='inputStyle' style={{ marginTop: 20 }}
                    >
                        <div
                            className='inputTitle' style={{ fontSize: 16, marginRight: -4, width: 100, marginLeft: 2 }}
                        >模板指令：</div>
                         <Input
                         disabled
                            placeholder="请输入模板标题"
                            className='textInputStr'
                            style={{ width: 560 }}
                            value={template_command}
                            onChange={(e) => {
                                setTemplate_command(e.target.value)
                            }}
                        />
                    </div>

                    <div className='inputStyle' style={{ marginTop: 20 }}
                    >
                        <div
                            className='inputTitle' style={{ fontSize: 16, marginRight: -4, width: 120, marginLeft: -17 }}
                        >模板录制时长：</div>
                        <InputNumber
                        disabled
                            placeholder="请输入"
                            precision={1}
                            min={0}
                            addonAfter="秒"
                            value={record_duration}
                            className='textInputStr'
                            style={{ width: 560 }}
                        />
                    </div>
                    <div className='inputStyle' style={{ marginTop: 30 }}
                    >
                        <div
                            className='inputTitle' style={{ fontSize: 16, marginRight: -4, width: 100, marginLeft: 2 }}
                        >剪辑指令：</div>
                        <Input
                        disabled
                            placeholder="请输入模板标题"
                            className='textInputStr'
                            style={{ width: 560 }}
                            value={cut_command}
                            onChange={(e) => {
                                setCut_command(e.target.value)
                            }}
                        />
                    </div>
                    <div className='inputStyle' style={{ marginTop: 20 }}
                    >
                        <div
                            className='inputTitle' style={{ fontSize: 16, marginRight: -4, width: 140, marginLeft: -37 }}
                        >拍摄倒计时时长：</div>
                        <InputNumber
                        disabled
                            placeholder="请输入"
                            precision={0}
                            min={2}
                            addonAfter="秒"
                            value={count_down}
                            className='textInputStr'
                            style={{ width: 560 }}
                          
                        />
                    </div>

                    <div style={{ width: 650, height: 120, marginTop: 30, marginLeft: 5, alignItems: 'left', display: 'flex', flexDirection: 'row' }}>
                        <div
                            className='inputTitle' style={{ fontSize: 16, marginRight: -5, width: 100 }}
                        >是否启用：</div>
                        <Switch disabled  checked={is_use === 1} onChange={(e) => {
                            const num = e ? 1 : 0
                            setIs_use(num)
                        }} />
                    </div>
                    <div  style={{ width: 1150, height: 380, marginTop: 30, alignItems: 'left', display: 'flex', flexDirection: 'row' }}>
                        <div
                            className='inputTitle' style={{ fontSize: 16, marginRight: 0, marginLeft: 12, width: 100, marginTop: -320 }}
                        >拍摄要点：</div>
                        <div  style={{ width: 1130, height: 380, borderColor: "#333", borderWidth: 1, borderStyle: 'outset' }}>
                            <BraftEditor
                            
                                contentStyle={{ height: 230 }}
                                value={photo_data}
                                media={{
                                    accepts: {
                                        image: 'image/jpeg,image/png',
                                        video: false,
                                        audio: false,
                                    },
                                    uploadFn: myUploadFn
                                }}
                            />
                        </div>
                    </div>

                </div>
            </div>

        </div>
    )
}
export default QueryModel
