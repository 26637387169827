import React, { useEffect, useState } from "react";
import { Input, InputNumber, Switch, TimePicker, Select, Tooltip } from "antd";
import "../../../globalLess/global.css";
import "../../../components/Layout/index.less";
import { getShopDetail, getMerchantList } from "../../../service/index";
import { toastErrorMessage, tabMenu } from "../../../utils";
import moment from "moment";
import { useLocation } from "react-router-dom";
import type { SelectProps } from "antd";

type RuleType = {
  rule_name: string;
  rule_type: number;
  system_accounts: { id: string; radio: string }[];
  distribution_sub_accounts: string[];
  distribution_sub_account_radio: string;
  service_sub_accounts: { id: string; radio: string }[];
};

const QueryStorge = (): React.ReactElement => {
  let location = useLocation();
  const [current, setCurrent] = useState("1");
  const [name, setName] = useState("");
  const [adress, setAdress] = useState("");
  const [start_time, setStart_time] = useState<any>("00:00");
  const [end_time, setEnd_time] = useState<any>("00:00");
  const [iphone, setIphone] = useState("");
  const [is_use, setIs_use] = useState(0);
  const [ticket_name, setTicket_name] = useState("");
  const [take_duration, setTake_duration] = useState<any>();
  const [cut_number, setCut_number] = useState<any>();
  const [ticket_price, setTicket_price] = useState<any>();
  const [is_use_append, setIs_use_append] = useState(0);
  const [free_type, setFree_type] = useState(0);
  const [append_price, setAppend_price] = useState("");
  const [append_rules, setAppend_rules] = useState<any>([]);
  const [append_rules_price1, setAppend_rules_price1] = useState<any>({
    count: undefined,
    price: undefined,
  });
  const [append_rules_priceLast, setAppend_rules_priceLast] =
    useState<number>();
  const [append_rules_priceMiddle, setAppend_rules_priceMiddle] = useState<any>(
    [{ count: undefined, price: undefined }]
  );
  const [template_number, setTemplate_number] = useState<any>();
  const [sub_rules, setSub_rules] = useState<RuleType[]>([]);
  const [merchantList, setMerchantList] = useState<any>([
    { id: "", label: "" },
  ]);

  const [dsMerchantList, setDSMerchantList] = useState<any>([
    { id: "", label: "" },
  ]);
  const [use_sub_account, setUse_sub_account] = useState(0); //分账功能默认不起用
  const [sub_account_day, setSub_account_day] = useState(3); //分账到账周期天 3天

  const newOptions = (start: number, end: number) => {
    const options: SelectProps["options"] = [];
    for (let i = start; i < end; i++) {
      options.push({
        key: i + `${start}${end}`,
        label: i,
        value: i,
      });
    }
    return options;
  };
  const ShopDetail = (id: string) => {
    let dict = {
      id,
    };
    getShopDetail(dict)
      .then((res: any) => {
        if (res.code === 200) {
          setName(res.data.name);
          setAdress(res.data.adress);
          setStart_time(res.data.start_time);
          setEnd_time(res.data.end_time);
          setIphone(res.data.iphone);
          setIs_use(res.data.is_use);
          setTicket_name(res.data.ticket_name);
          setTake_duration(res.data.take_duration / 60);
          setCut_number(res.data.cut_number);
          setTicket_price(res.data.ticket_price);
          setIs_use_append(res.data.is_use_append);
          setFree_type(res.data.free_type);
          setTemplate_number(res.data.template_number);
          if (res.data.free_type === 0) {
            setAppend_price(res.data.append_price);
          } else if (res.data.free_type === 1) {
            const List = JSON.parse(res.data.append_rules);
            setAppend_rules_price1({ count: List[0][1], price: List[0][0] });
            setAppend_rules_priceLast(List[List.length - 1][0]);
            if (List.length > 2) {
              let ary: any = [];
              for (let index = 1; index < List.length - 1; index++) {
                const item = List[index];
                ary.push({ count: item[1], price: item[0] });
              }
              setAppend_rules_priceMiddle(ary);
            }
          }
          res.data.sub_account_day &&
            setSub_account_day(res.data.sub_account_day);
          res.data.use_sub_account &&
            setUse_sub_account(res.data.use_sub_account);
          if (res.data.sub_rules) {
            let sub_rulesCopy = JSON.parse(JSON.stringify(res.data.sub_rules));
            sub_rulesCopy.forEach((sItem: any, sIndex: any) => {
              sItem.distribution_sub_account_radio =
               Math.round(parseFloat(sItem.distribution_sub_account_radio) * 100)  + "";
              sItem.system_accounts.forEach((sysItem: any, sysIndex: any) => {
                sysItem.radio = Math.round(parseFloat(sysItem.radio) * 100)  + "";
              });
              let ids_array: any = [];
              sItem.distribution_sub_accounts.forEach(
                (sysItem: any, sysIndex: any) => {
                  ids_array.push(sysItem.id);
                }
              );
              sItem.distribution_sub_accounts = ids_array;
              sItem.service_sub_accounts.forEach(
                (serItem: any, sysIndex: any) => {
                  serItem.radio = Math.round(parseFloat(serItem.radio) * 100)  + "";
                }
              );
            });
            setSub_rules(sub_rulesCopy);
          }
        }
      })
      .catch((err: any) => {});
  };

  const systemItemName = (id: string) => {
    let name = "";
    merchantList.forEach((element: any) => {
      if (element.id === id) {
        name = element.merchant_name;
      }
    });
    return name;
  };
  const distributionName = (ids: string[]) => {
    let name = "";
    for (let index = 0; index < ids.length; index++) {
      const element = ids[index];
      for (let j = 0; j < dsMerchantList.length; j++) {
        const item = dsMerchantList[j];
        if (item.id === element) {
          if (name === "") {
            name += item.merchant_name;
          } else {
            name += "," + item.merchant_name;
          }
        }
      }
    }
    return name;
  };

  const getMerchantListFetch = async (merchant_type: string) => {
    await getMerchantList({
      page_num: 1,
      page_size: 1000,
      merchant_type: merchant_type,
    })
      .then((response) => {
        if (response.code === 200) {
          if (merchant_type === "3") {
            setDSMerchantList(response.data.items);
          } else {
            setMerchantList(response.data.items);
          }
        } else {
          toastErrorMessage(response.msg);
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getMerchantListFetch("0");
    getMerchantListFetch("3");
    // eslint-disable-next-line array-callback-return
    if (location && location.state && location.state.id) {
      ShopDetail(location.state.id);
    }
  }, []);

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <div style={{ backgroundColor: "#FFF", width: "100%", height: 900 }}>
        <div
          style={{
            backgroundColor: "#FFF",
            display: "flex",
            flexDirection: "row",
            paddingTop: 20,
          }}
        >
          {tabMenu.map((item) => {
            return (
              <div
                key={item.key}
                style={{
                  marginLeft: item.key === "1" ? 5 : 20,
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setCurrent(item.key);
                }}
              >
                <div
                  style={{
                    width: 70,
                    fontSize: 14,
                    color: current === item.key ? "#FB186A" : "#000000",
                    textAlign: "center",
                  }}
                >
                  {" "}
                  {item.label}
                </div>
                <div
                  style={{
                    marginTop: 5,
                    height: 1,
                    marginLeft: 10,
                    width: 50,
                    backgroundColor: current === item.key ? "#FB186A" : "#FFF",
                  }}
                />
              </div>
            );
          })}
        </div>
        <div
          style={{ width: "100%", height: 0.5, backgroundColor: "#F3F4F5" }}
        />
        {current === "1" && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              height: 400,
              alignItems: "center",
            }}
          >
            <div
              className="inputTitle"
              style={{
                fontSize: 16,
                marginLeft: -380,
                width: 100,
                marginTop: 20,
                fontWeight: "bold",
              }}
            >
              门店信息
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: 470,
                marginTop: 20,
                alignItems: "center",
                borderWidth: 2,
                borderColor: "#F3F4F5",
                borderStyle: "dashed",
              }}
            >
              <div className="inputStyle" style={{ marginTop: 20 }}>
                <div
                  className="inputTitle"
                  style={{
                    fontSize: 14,
                    marginRight: -5,
                    width: 3,
                    color: "red",
                  }}
                >
                  *
                </div>
                <div
                  className="inputTitle"
                  style={{
                    fontSize: 14,
                    marginRight: -5,
                    width: 100,
                    color: "#666666",
                  }}
                >
                  门店名称：
                </div>
                <Input
                  className="textInputStr"
                  placeholder="请输入"
                  disabled
                  style={{ width: 336 }}
                  showCount
                  value={name}
                  maxLength={20}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                />
              </div>
              <div className="inputStyle" style={{ marginTop: 20 }}>
                <div
                  className="inputTitle"
                  style={{
                    fontSize: 14,
                    marginRight: -5,
                    width: 3,
                    color: "red",
                  }}
                >
                  *
                </div>

                <div
                  className="inputTitle"
                  style={{
                    fontSize: 14,
                    marginRight: -5,
                    width: 100,
                    color: "#666666",
                  }}
                >
                  门店地址：
                </div>
                <Input
                  className="textInputStr"
                  placeholder="请输入"
                  maxLength={50}
                  value={adress}
                  disabled
                  style={{ width: 336 }}
                  onChange={(e) => {
                    setAdress(e.target.value);
                  }}
                />
              </div>
              <div className="inputStyle" style={{ marginTop: 20 }}>
                <div
                  className="inputTitle"
                  style={{
                    fontSize: 14,
                    marginRight: -5,
                    width: 3,
                    color: "red",
                  }}
                >
                  *
                </div>
                <div
                  className="inputTitle"
                  style={{
                    fontSize: 14,
                    marginRight: -5,
                    width: 100,
                    color: "#666666",
                  }}
                >
                  营业时间：
                </div>
                <TimePicker.RangePicker
                  order={false}
                  disabled
                  format={"HH:mm"}
                  value={[
                    moment(start_time, "HH:mm"),
                    moment(end_time, "HH:mm"),
                  ]}
                  onChange={(values: any, formatString: [string, string]) => {
                    setStart_time(formatString[0]);
                    setEnd_time(formatString[1]);
                  }}
                  style={{ marginTop: 5, width: 336 }}
                />
              </div>
              <div className="inputStyle" style={{ marginTop: 20 }}>
                <div
                  className="inputTitle"
                  style={{
                    fontSize: 14,
                    marginRight: -5,
                    width: 3,
                    color: "red",
                  }}
                >
                  *
                </div>
                <div
                  className="inputTitle"
                  style={{
                    fontSize: 14,
                    marginRight: -5,
                    width: 100,
                    color: "#666666",
                  }}
                >
                  客服电话：
                </div>
                <Input
                  className="textInputStr"
                  placeholder="请输入"
                  style={{ width: 336 }}
                  type="number"
                  value={iphone}
                  disabled
                  onChange={(e) => {
                    setIphone(e.target.value);
                  }}
                />
              </div>
              <div
                className="inputStyle"
                style={{ marginTop: 20, marginBottom: 20 }}
              >
                <div
                  className="inputTitle"
                  style={{
                    fontSize: 14,
                    marginLeft: -210,
                    width: 130,
                    color: "#666666",
                    marginRight: -5,
                  }}
                >
                  (选填)是否启用：
                </div>
                <Switch
                  checked={is_use === 1}
                  disabled
                  onChange={(e) => {
                    const num = e ? 1 : 0;
                    setIs_use(num);
                  }}
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                height: 400,
                alignItems: "center",
              }}
            >
              <div
                className="inputTitle"
                style={{
                  fontSize: 16,
                  marginLeft: -380,
                  width: 100,
                  marginTop: 20,
                  fontWeight: "bold",
                }}
              >
                门票信息
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: 470,
                  marginTop: 20,
                  alignItems: "center",
                  borderWidth: 2,
                  borderColor: "#F3F4F5",
                  borderStyle: "dashed",
                }}
              >
                <div className="inputStyle" style={{ marginTop: 20 }}>
                  <div
                    className="inputTitle"
                    style={{
                      fontSize: 14,
                      marginRight: -5,
                      width: 3,
                      color: "red",
                    }}
                  >
                    *
                  </div>
                  <div
                    className="inputTitle"
                    style={{
                      fontSize: 14,
                      marginRight: -5,
                      width: 100,
                      color: "#666666",
                    }}
                  >
                    门票名称：
                  </div>
                  <Input
                    className="textInputStr"
                    placeholder="请输入"
                    disabled
                    style={{ width: 336 }}
                    showCount
                    value={ticket_name}
                    maxLength={10}
                    onChange={(e) => {
                      console.log("eeeee", e);
                      setTicket_name(e.target.value);
                    }}
                  />
                </div>
                <div className="inputStyle" style={{ marginTop: 20 }}>
                  <div
                    className="inputTitle"
                    style={{
                      fontSize: 14,
                      marginRight: -5,
                      width: 3,
                      color: "red",
                    }}
                  >
                    *
                  </div>
                  <div
                    className="inputTitle"
                    style={{
                      fontSize: 14,
                      marginRight: -5,
                      width: 100,
                      color: "#666666",
                    }}
                  >
                    拍摄时长：
                  </div>
                  <InputNumber
                    disabled
                    className="textInputStr"
                    placeholder="请输入"
                    addonAfter="分钟"
                    value={take_duration}
                    step={0.1}
                    min={0}
                    precision={1}
                    style={{ width: 336 }}
                    onChange={(e) => {
                      setTake_duration(e);
                    }}
                  />
                </div>
                <div className="inputStyle" style={{ marginTop: 20 }}>
                  <div
                    className="inputTitle"
                    style={{
                      fontSize: 14,
                      marginRight: -5,
                      width: 3,
                      color: "red",
                    }}
                  >
                    *
                  </div>
                  <div
                    className="inputTitle"
                    style={{
                      fontSize: 14,
                      marginRight: -5,
                      width: 100,
                      color: "#666666",
                    }}
                  >
                    剪辑条数：
                  </div>
                  <InputNumber
                    disabled
                    className="textInputStr"
                    addonAfter="条"
                    placeholder="请输入"
                    value={cut_number}
                    min={1}
                    style={{ width: 336 }}
                    onChange={(e) => {
                      setCut_number(e);
                    }}
                  />
                </div>
                <div
                  className="inputStyle"
                  style={{ marginTop: 20, marginBottom: 0 }}
                >
                  <div
                    className="inputTitle"
                    style={{
                      fontSize: 14,
                      marginLeft: 30,
                      marginRight: -5,
                      width: 3,
                      color: "red",
                    }}
                  >
                    *
                  </div>
                  <div
                    className="inputTitle"
                    style={{
                      fontSize: 14,
                      marginRight: -5,
                      width: 70,
                      color: "#666666",
                    }}
                  >
                    票价：
                  </div>
                  <InputNumber
                    disabled
                    className="textInputStr"
                    placeholder="请输入"
                    addonAfter="元"
                    value={ticket_price}
                    step={1}
                    min={0}
                    precision={2}
                    style={{ width: 336 }}
                    onChange={(e) => {
                      setTicket_price(e);
                    }}
                  />
                </div>
                <div
                  className="inputStyle"
                  style={{ marginTop: 20, marginBottom: 20 }}
                >
                  <div
                    className="inputTitle"
                    style={{
                      fontSize: 14,
                      marginLeft: -15,
                      marginRight: -7,
                      width: 3,
                      color: "red",
                    }}
                  >
                    *
                  </div>
                  <div
                    className="inputTitle"
                    style={{
                      fontSize: 14,
                      marginRight: -5,
                      width: 120,
                      color: "#666666",
                    }}
                  >
                    拍摄模板数量：
                  </div>
                  <InputNumber
                    disabled
                    className="textInputStr"
                    placeholder="请输入"
                    addonAfter="条"
                    value={template_number}
                    step={1}
                    precision={0}
                    min={0}
                    style={{ width: 336 }}
                    onChange={(e) => {
                      setTemplate_number(e);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        {current === "2" && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              height: 400,
              alignItems: "center",
            }}
          >
            <div className="inputStyle" style={{ marginTop: 20 }}>
              <div
                className="inputTitle"
                style={{
                  fontSize: 14,
                  marginLeft: -210,
                  width: 130,
                  color: "#666666",
                  marginRight: -5,
                }}
              >
                启用增值服务：
              </div>
              <Switch
                checked={is_use_append === 1}
                disabled
                onChange={(e) => {
                  const num = e ? 1 : 0;
                  if (num === 0) {
                    setAppend_price("");
                    setAppend_rules_price1({
                      count: undefined,
                      price: undefined,
                    });
                    setAppend_rules_priceLast(undefined);
                    setAppend_rules_priceMiddle([
                      { count: undefined, price: undefined },
                    ]);
                  }
                  setIs_use_append(num);
                }}
              />
            </div>
            <div className="inputStyle" style={{ marginTop: 20 }}>
              <div
                className="inputTitle"
                style={{
                  fontSize: 14,
                  marginLeft: -85,
                  width: 90,
                  color: "#666666",
                  marginRight: -5,
                }}
              >
                计费方式：
              </div>
              <img
                style={{
                  marginLeft: 0,
                  width: 16,
                  height: 16,
                  zIndex: 20,
                  cursor: "pointer",
                }}
                src={
                  free_type === 0
                    ? require("../../../assets/icon_selectIcon.png")
                    : require("../../../assets/icon_unSelect.png")
                }
                alt=""
              />
              <div
                className="inputTitle"
                style={{
                  fontSize: 14,
                  marginLeft: -10,
                  width: 90,
                  color: "#666666",
                  marginRight: -5,
                }}
              >
                统一计费
              </div>
              <img
                style={{
                  marginLeft: 0,
                  width: 16,
                  height: 16,
                  zIndex: 20,
                  cursor: "pointer",
                }}
                src={
                  free_type === 1
                    ? require("../../../assets/icon_selectIcon.png")
                    : require("../../../assets/icon_unSelect.png")
                }
                alt=""
                onClick={() => {
                  setFree_type(1);
                  setAppend_price("");
                }}
              />
              <div
                className="inputTitle"
                style={{
                  fontSize: 14,
                  marginLeft: -10,
                  width: 90,
                  color: "#666666",
                  marginRight: -5,
                }}
              >
                阶梯计费
              </div>
            </div>
            {free_type === 0 && (
              <div>
                <div className="inputStyle" style={{ marginTop: 20 }}>
                  <div
                    className="inputTitle"
                    style={{
                      fontSize: 14,
                      marginLeft: -78,
                      width: 90,
                      color: "#666666",
                      marginRight: -25,
                    }}
                  >
                    价格：
                  </div>
                  <InputNumber
                    disabled
                    className="textInputStr"
                    placeholder="请输入"
                    addonAfter="元/条"
                    style={{ width: 180 }}
                    min={0}
                    value={parseFloat(append_price)}
                    onChange={(e) => {
                      let str = e + "";
                      setAppend_price(str);
                    }}
                  />
                </div>
              </div>
            )}
            {free_type === 1 && (
              <div style={{ marginTop: 20, paddingLeft: 50 }}>
                <div className="inputStyle">
                  <div
                    className="inputTitle"
                    style={{
                      fontSize: 14,
                      marginLeft: 220,
                      width: 90,
                      color: "#000000",
                      marginRight: -25,
                    }}
                  >
                    阶段1：
                  </div>
                  <div
                    style={{
                      borderStyle: "solid",
                      borderWidth: 1,
                      borderColor: "#D9D9D9",
                    }}
                  >
                    <div
                      className="inputStyle"
                      style={{
                        marginTop: 20,
                        marginLeft: 20,
                        marginRight: 300,
                      }}
                    >
                      <div
                        className="inputTitle"
                        style={{
                          fontSize: 14,
                          marginLeft: 0,
                          width: 70,
                          color: "#666666",
                          marginRight: -25,
                        }}
                      >
                        购买视频：
                      </div>
                      <div
                        className="inputTitle"
                        style={{
                          fontSize: 14,
                          marginLeft: 0,
                          width: 90,
                          color: "#000000",
                          marginRight: -25,
                        }}
                      >
                        小于
                      </div>
                      <InputNumber
                        disabled
                        className="textInputStr"
                        placeholder="请输入"
                        addonAfter="条"
                        style={{ width: 150 }}
                        min={0}
                        value={
                          append_rules_price1.count &&
                          parseFloat(append_rules_price1.count)
                        }
                        onChange={(e) => {
                          setAppend_rules_price1({
                            count: e,
                            price: append_rules_price1.price,
                          });
                        }}
                      />
                    </div>
                    <div
                      className="inputStyle"
                      style={{
                        marginTop: 10,
                        marginLeft: 20,
                        marginBottom: 20,
                      }}
                    >
                      <div
                        className="inputTitle"
                        style={{
                          fontSize: 14,
                          marginLeft: 15,
                          width: 70,
                          color: "#666666",
                          marginRight: -10,
                        }}
                      >
                        价格：
                      </div>
                      <InputNumber
                        disabled
                        className="textInputStr"
                        placeholder="请输入"
                        addonAfter="元/条"
                        style={{ width: 150 }}
                        min={0}
                        value={
                          append_rules_price1.price &&
                          parseFloat(append_rules_price1.price)
                        }
                        onChange={(e) => {
                          setAppend_rules_price1({
                            count: append_rules_price1.count,
                            price: e,
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className="inputStyle"
                  style={{
                    marginTop: 20,
                    marginLeft: 200,
                    width: 720,
                    backgroundColor: "#FBFBFB",
                    flexDirection: "column",
                  }}
                >
                  {append_rules_priceMiddle.map((item: any, index: any) => (
                    <div
                      key={"a" + index}
                      className="inputStyle"
                      style={{ marginTop: 20, marginLeft: -30 }}
                    >
                      <div
                        className="inputTitle"
                        style={{
                          fontSize: 14,
                          marginLeft: 0,
                          width: 90,
                          color: "#000000",
                          marginRight: -25,
                        }}
                      >{`阶段${index + 2}：`}</div>
                      <div
                        style={{
                          borderStyle: "solid",
                          borderWidth: 1,
                          borderColor: "#D9D9D9",
                          width: 585,
                        }}
                      >
                        <div
                          className="inputStyle"
                          style={{ marginTop: 20, marginLeft: 20 }}
                        >
                          <div
                            className="inputTitle"
                            style={{
                              fontSize: 14,
                              marginLeft: 0,
                              width: 70,
                              color: "#666666",
                              marginRight: -25,
                            }}
                          >
                            购买视频：
                          </div>
                          <div
                            className="inputTitle"
                            style={{
                              fontSize: 14,
                              marginLeft: 20,
                              width: 90,
                              color: "#000000",
                              marginRight: -10,
                            }}
                          >
                            {index === 0
                              ? append_rules_price1.count
                                ? `大于等于${append_rules_price1.count}条`
                                : "大于等于"
                              : append_rules_priceMiddle[index - 1].count
                              ? `大于等于${
                                  append_rules_priceMiddle[index - 1].count
                                }条`
                              : "大于等于"}
                          </div>
                          <div
                            className="inputTitle"
                            style={{
                              fontSize: 14,
                              marginLeft: 10,
                              width: 90,
                              color: "#000000",
                              marginRight: -10,
                            }}
                          >
                            且小于
                          </div>
                          <InputNumber
                            disabled
                            className="textInputStr"
                            placeholder="请输入"
                            addonAfter="条"
                            style={{ width: 150, marginRight: 40 }}
                            min={0}
                            value={
                              append_rules_priceMiddle[index].count &&
                              parseFloat(append_rules_priceMiddle[index].count)
                            }
                            onChange={(e) => {
                              const list = JSON.parse(
                                JSON.stringify(append_rules_priceMiddle)
                              );
                              item = list[index];
                              item.count = e;
                              list[index] = item;
                              setAppend_rules_priceMiddle(list);
                            }}
                          />
                        </div>
                        <div
                          className="inputStyle"
                          style={{
                            marginTop: 10,
                            marginLeft: 20,
                            marginBottom: 20,
                          }}
                        >
                          <div
                            className="inputTitle"
                            style={{
                              fontSize: 14,
                              marginLeft: 15,
                              width: 70,
                              color: "#666666",
                              marginRight: -10,
                            }}
                          >
                            价格：
                          </div>
                          <InputNumber
                            disabled
                            className="textInputStr"
                            placeholder="请输入"
                            addonAfter="元/条"
                            style={{ width: 150 }}
                            value={
                              append_rules_priceMiddle[index].price &&
                              parseFloat(append_rules_priceMiddle[index].price)
                            }
                            min={0}
                            onChange={(e) => {
                              const list = JSON.parse(
                                JSON.stringify(append_rules_priceMiddle)
                              );
                              item = list[index];
                              item.price = e;
                              list[index] = item;
                              setAppend_rules_priceMiddle(list);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="inputStyle" style={{ marginTop: 20 }}>
                  <div
                    className="inputTitle"
                    style={{
                      fontSize: 14,
                      marginLeft: 220,
                      width: 90,
                      color: "#000000",
                      marginRight: -25,
                    }}
                  >{`阶段${append_rules_priceMiddle.length + 2}：`}</div>
                  <div
                    style={{
                      borderStyle: "solid",
                      borderWidth: 1,
                      borderColor: "#D9D9D9",
                      width: 585,
                    }}
                  >
                    <div
                      className="inputStyle"
                      style={{
                        marginTop: 20,
                        marginLeft: 20,
                        marginRight: 275,
                      }}
                    >
                      <div
                        className="inputTitle"
                        style={{
                          fontSize: 14,
                          marginLeft: 0,
                          width: 70,
                          color: "#666666",
                          marginRight: -25,
                        }}
                      >
                        购买视频：
                      </div>
                      <div
                        className="inputTitle"
                        style={{
                          fontSize: 14,
                          marginLeft: 15,
                          width: 120,
                          color: "#000000",
                        }}
                      >
                        {append_rules_priceMiddle.length > 0 &&
                        append_rules_priceMiddle[
                          append_rules_priceMiddle.length - 1
                        ].count
                          ? `大于等于${
                              append_rules_priceMiddle[
                                append_rules_priceMiddle.length - 1
                              ].count
                            }条`
                          : append_rules_price1.count
                          ? `大于等于${append_rules_price1.count}条`
                          : "大于等于"}
                      </div>
                    </div>
                    <div
                      className="inputStyle"
                      style={{
                        marginTop: 10,
                        marginLeft: 20,
                        marginBottom: 20,
                      }}
                    >
                      <div
                        className="inputTitle"
                        style={{
                          fontSize: 14,
                          marginLeft: 15,
                          width: 70,
                          color: "#666666",
                          marginRight: -10,
                        }}
                      >
                        价格：
                      </div>

                      <InputNumber
                        disabled
                        className="textInputStr"
                        placeholder="请输入"
                        addonAfter="元/条"
                        style={{ width: 150 }}
                        value={append_rules_priceLast}
                        min={0}
                        onChange={(e: any) => {
                          setAppend_rules_priceLast(e);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}

        {current === "3" && (
          <div>
            <div className="inputStyle" style={{ marginTop: 20 }}>
              <div style={{ fontSize: 14, marginLeft: 62, marginRight: 3 }}>
                分账功能：
              </div>
              <Switch disabled checked={use_sub_account === 1} />
            </div>
            {use_sub_account === 1 && (
              <div className="rowCss" style={{ marginTop: 20 }}>
                <div style={{ fontSize: 14, marginRight: 10, marginLeft: 28 }}>
                  *分账到账周期:
                </div>
                <Select
                  disabled
                  style={{ width: 160 }}
                  value={sub_account_day}
                  options={newOptions(1, 29)}
                />
                <div style={{ fontSize: 14, marginRight: 10, marginLeft: 5 }}>
                  天
                </div>
              </div>
            )}

            {use_sub_account === 1 &&
              sub_rules.map((rule, index) => {
                return (
                  <div
                    style={{ marginLeft: 30, marginRight: 30, marginTop: 20 }}
                  >
                    <div style={{ textAlign: "center", display: "flex" }}>
                      <div style={{ fontSize: 16 }}>{rule.rule_name}</div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        padding: 20,
                        borderStyle: "dashed",
                        borderWidth: 1,
                        borderColor: "#F3F4F5",
                      }}
                    >
                      {(rule.rule_type === 1 || rule.rule_type === 3) && (
                        <div
                          style={{ flex: 1, marginRight: 10, marginLeft: 10 }}
                        >
                          <div> 系统记账：</div>
                          <div
                            style={{
                              height: 34,
                              display: "flex",
                              flexDirection: "row",
                              backgroundColor: "#F5F6F8",
                            }}
                          >
                            <div className="subCell"> 商户名称</div>
                            <div className="subCell">分账比例</div>
                          </div>
                          {rule.system_accounts.map((systemItem, index) => {
                            return (
                              <div
                                key={`aa${index}`}
                                style={{
                                  height: 34,
                                  display: "flex",
                                  flexDirection: "row",
                                  borderBottomColor: "#F5F6F8",
                                  borderBottomWidth: 1,
                                  borderBottomStyle: "solid",
                                }}
                              >
                                <div className="subCell">
                                  {" "}
                                  {systemItemName(systemItem.id)}
                                </div>
                                <div className="subCell">
                                  {systemItem.radio + "%"}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      )}

                      {(rule.rule_type === 2 || rule.rule_type === 3) && (
                        <div
                          style={{ flex: 1, marginRight: 10, marginLeft: 10 }}
                        >
                          <div> 微信分账：</div>
                          <div
                            style={{
                              height: 34,
                              display: "flex",
                              flexDirection: "row",
                              backgroundColor: "#F5F6F8",
                            }}
                          >
                            <div className="subCell"> </div>
                            <div className="subCell"> 商户名称</div>
                            <div className="subCell">分账比例</div>
                          </div>
                          <div
                            style={{
                              height: 34,
                              display: "flex",
                              flexDirection: "row",
                              borderBottomColor: "#F5F6F8",
                              borderBottomWidth: 1,
                              borderBottomStyle: "solid",
                            }}
                          >
                            <div className="subCell"> 分销商</div>
                            <Tooltip placement="top" title={distributionName(rule.distribution_sub_accounts)}>
                            <div className="subCell">
                            {distributionName(rule.distribution_sub_accounts).length>9?(distributionName(rule.distribution_sub_accounts).substring(0,9)+'...'):distributionName(rule.distribution_sub_accounts)}
                            </div>
                            </Tooltip>
                            <div className="subCell">
                              {rule.distribution_sub_account_radio + "%"}
                            </div>
                          </div>
                          {rule.service_sub_accounts.map(
                            (subItem, subIndex) => {
                              return (
                                <div
                                  key={`cc${subIndex}`}
                                  style={{
                                    height: 34,
                                    display: "flex",
                                    flexDirection: "row",
                                    borderBottomColor: "#F5F6F8",
                                    borderBottomWidth: 1,
                                    borderBottomStyle: "solid",
                                  }}
                                >
                                  <div className="subCell"> 服务商</div>
                                  <div className="subCell">
                                    {" "}
                                    {systemItemName(subItem.id)}
                                  </div>
                                  <div className="subCell">
                                    {subItem.radio + "%"}
                                  </div>
                                </div>
                              );
                            }
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
          </div>
        )}
      </div>
    </div>
  );
};
export default QueryStorge;
