import React from 'react';
import '../globalLess/global.css'

export default class Button extends React.Component<{
    title?: string,
    onClick: ()=>void,
    buttonStyle?: React.CSSProperties,
    titleStyle?: React.CSSProperties,
}> {
    render() {
        const {
            title,
            onClick,
            buttonStyle,
            titleStyle
        } = this.props
        return (
            <div className='button' style={buttonStyle}
                onClick={() => {
                    onClick()
                }}
            >
                <div className='buttonTitle' style={titleStyle}>
                    {title}
                </div>
            </div>
        )
    }

}


