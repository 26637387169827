import React from "react";
import "./index.less";
import { Select, } from "antd";

export default class OrderMenu extends React.Component<{
  permission: number | undefined;
  onDateChange:(dateType: number) => void;
  valueData :{
    pay_amount: number;
    profit_amount: number;
    profit_surplus:number;
    profitrecord_amount: number;
    profitsharing_amount: number;
    profitsharing_finished_amount: number;
    profitsharing_waited_amount: number;
    refund_amount: number;
  }
}> {
  render() {
    const { permission, valueData,  onDateChange } = this.props;
    return (
      <div className="cardStyle">
        <div className="rowStyle">
          <div style={{fontSize:18,marginLeft:30,marginTop: 10}}>交易统计</div>
          <Select
            style={{ width: 100, marginTop: 8,marginLeft:10 }}
            onChange={(value) => {onDateChange(value)}}
            placeholder="当月"
            defaultValue={2}
            options={[
              {
                value: 1,
                label: "上月",
              },
              {
                value: 2,
                label: "当月",
              },
            ]}
          />
        </div>
        {permission === 1 ? (
          <div>
            <div className="rowStyle">
              <div className="flexCell">
                <div className="subTitle">订单支付总金额</div>
                <div className="moneyText">{'¥'+valueData.pay_amount}</div>
              </div>
              <div className="flexCell">
                <div className="subTitle">退款金额</div>
                <div className="moneyText">{'¥'+valueData.refund_amount}</div>
              </div>
              <div className="flexCell">
                <div className="subTitle">订单结余</div>
                <div className="moneyText">{'¥'+valueData.profit_surplus}</div>
              </div>
              <div className="flexCell"></div>
            </div>
            <div className="rowStyle">
              <div className="flexCell">
                <div className="subTitle">分账总额</div>
                <div className="moneyText">{'¥'+valueData.profit_amount}</div>
              </div>
              <div className="flexCell">
                <div className="subTitle">系统记账总额</div>
                <div className="moneyText">{'¥'+valueData.profitrecord_amount}</div>
              </div>
              <div className="flexCell">
                <div className="subTitle">微信应分账金额</div>
                <div className="moneyText">{'¥'+valueData.profitsharing_amount}</div>
              </div>
              <div className="flexCell">
                <div className="subTitle">微信已分账金额</div>
                <div className="moneyText">{'¥'+valueData.profitsharing_finished_amount}</div>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <div className="rowStyle">
              <div className="flexCell">
                <div className="subTitle">订单支付总金额</div>
                <div className="moneyText">{'¥'+valueData.pay_amount}</div>
              </div>
              <div className="flexCell">
                <div className="subTitle">系统记账总金额</div>
                <div className="moneyText">{'¥'+valueData.profitrecord_amount}</div>
              </div>
              <div className="flexCell">
                <div className="subTitle">微信应分金额</div>
                <div className="moneyText">{'¥'+valueData.profitsharing_amount}</div>
              </div>
              <div className="flexCell">
                <div className="subTitle">微信已分账金额</div>
                <div className="moneyText">{'¥'+valueData.profitsharing_finished_amount}</div>
              </div>
              <div className="flexCell">
                <div className="subTitle">微信待分账金额</div>
                <div className="moneyText">{'¥'+valueData.profitsharing_waited_amount}</div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
