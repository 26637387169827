import React, {useState, useEffect} from 'react';
import {Button, Form, Input} from 'antd';
import './index.less'
import { login} from "../../service";
import { showError} from "../../utils";
import { LockOutlined, UserOutlined } from '@ant-design/icons';

interface LoginForm {
    account: string,
    password: string,
}

interface LoginProps {
    getUserInfo: () => void
}

const Login = (props: LoginProps): React.ReactElement => {
    const {getUserInfo} = props
    const [loginForm] = Form.useForm();

    const onFinish = () => {
        loginForm.validateFields().then((values: LoginForm) => {
            login(values).then((response) => {
                if (response.code === 200) {
                    window.sessionStorage.setItem('token', response.data.token)
                    getUserInfo()
                }else{
                    showError(response.msg)
                }
            })
        })
    }

    useEffect(() => {
   
    }, [])

    return (
        <div className={'mes-login'}>
            <div className={'mes-login-content'} style={{backgroundColor:'#FFF',borderRadius:5}}>
                <Form
                    form={loginForm}
                    onFinish={() => onFinish()}
                    name="login"
                    requiredMark={true}
                    style={{margin: '90px 60px'}}
                >
                        <Form.Item
                    >
                        <div style={{fontSize:28, fontWeight:'bolder'}}>
                        用户登录
                        </div>
                    </Form.Item>
                    <Form.Item
                        style={{marginTop:60 }}
                        name="account"
                        rules={[{required: true, message: '请输入用户名!'}]}
                    >
                        <Input prefix={<UserOutlined className="site-form-item-icon" />} style={{borderColor:'#FB186A'}} placeholder={'请输入用户名'} className={'mes-login-content-input'}/>
                    </Form.Item>
                    <Form.Item
                        required
                        style={{marginTop:42 }}
                        name="password"
                        rules={[{required: true, message: '请输入密码!'}]}
                    >
                        <Input.Password   prefix={<LockOutlined className="site-form-item-icon" />}  style={{borderColor:'#FB186A'}} placeholder={'请输入密码'} className={'mes-login-content-input'} visibilityToggle={false}/>
                    </Form.Item>
                    <Form.Item style={{textAlign: 'center',marginTop:59}}>
                        <Button type="primary" htmlType="submit" className={'mes-login-content-submit'} style={{backgroundColor:'#FB186A',borderColor:'#FB186A' }}>
                            登录
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    )
}

export default Login;
