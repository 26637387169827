import { Breadcrumb } from 'antd';
import screenfull from "screenfull";
import { useLocation } from "react-router-dom";
import React, { useContext, useState } from "react";
import { UserContext } from "../../contexts/userContext";
import { menus } from '../../router/index'

const BreadcrumbBar = () => {
    const location = useLocation();
    const { useInfo } = useContext(UserContext);
    const [isFull, setIsFull] = useState(false);
    const openKeysItem = menus?.find(e => e.key === location.pathname.split('/')[1]);
    const screenFull = () => {
        if (screenfull.isEnabled) {
            screenfull.toggle();
            setIsFull(value => !value);
        }
    };
    const refresh = () => {
        window.location.reload();
    }
    return (
        <div className={'mes-bread'}>

            <img
                src={require('../../assets/icon_bread.png')}
                alt=""
                style={{
                    width: 7,
                    height: 20,
                    marginLeft: 32,
                    marginTop: 30,
                    marginRight: 5
                }} />
            <div style={{ cursor: 'pointer', marginTop: 22, marginLeft: 5 }} onClick={(e) => {
                if (openKeysItem) {
                    if ((openKeysItem.title === '门店管理') || (openKeysItem.title === '模板中心') || (openKeysItem.title === '订单管理') || (openKeysItem.title === '兑换码管理')||(openKeysItem.title === '商户管理') ) {


                    } else {
                        window.history.go(-1)
                    }
                }
            }}>
                {location.pathname === '/' && (
                    <Breadcrumb className={'lineHeight35'} style={{ lineHeight: '35px', color: '#666666', fontSize: 24 }}>
                        <Breadcrumb.Item>
                            <span> {useInfo.permission===1? '门店管理':'订单管理'}</span>
                        </Breadcrumb.Item>
                    </Breadcrumb>
                )}
                {
                    openKeysItem && (
                        <Breadcrumb style={{ lineHeight: '35px', color: '#666666', fontSize: 24 }}>
                            <Breadcrumb.Item >
                                {openKeysItem.title}
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    )
                }
            </div>
            <div style={{ flex: 1 }} />
            {
                openKeysItem && ((openKeysItem.title !== '门店管理') && (openKeysItem.title !== '模板中心') && (openKeysItem.title !== '订单管理') && (openKeysItem.title !== '兑换码管理')&& (openKeysItem.title !== '商户管理')) &&
                <div className='button' style={{ marginRight: 20, zIndex: 20, borderStyle: 'solid', borderWidth: 1, borderColor: '#FB186A', marginTop: 25 }} onClick={() => {
                    window.history.go(-1)
                }}>
                    <div className='buttonTitle' style={{ color: '#FB186A' }}>
                        返回
                    </div>
                </div>
            }
        </div>
    )
}

export default React.memo(BreadcrumbBar);
