import React, { useEffect, useState, useContext } from "react";
import Input from "../../../components/input";
import { Table, Menu, Dropdown, Button, Select, DatePicker, Modal } from "antd";
import type { ColumnsType } from "antd/es/table";
import "../../../globalLess/global.css";
import "../../../components/Layout/index.less";
import {
  toastErrorMessage,
  OrderStatus,
  OrderStatusColor,
  OrderManagerStatus, 
  toastSuccessMessage,
} from "../../../utils";
import {
  getOrderList,
  CancelReserve,
  getShopList,
  statistics,
} from "../../../service";
import moment from "moment";
import UploadVideo from "../UploadVideo";
import ChangeVideo from "../ChangeVideo";
import DownloadVideo from "../DownloadVideo";
import UploadEditVideo from "../UploadEditVideo";
import ChangeEditVideo from "../ChangeEditVideo";
import { useNavigate } from "react-router-dom";
import OrderMenu from "./orderMenu";
import { UserContext } from "../../../contexts/userContext";
const { Column, ColumnGroup } = Table;
const { confirm } = Modal;
const { RangePicker } = DatePicker;
const OrderManager = (): React.ReactElement => {
  const navigate = useNavigate();
  const { useInfo } = useContext(UserContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [total, setTotal] = useState<number>(0);
  const [dataSource, setDataSource] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showUploadVideo, setShowUploadVideo] = useState(false);
  const [showUploadEditVideo, setShowUploadEditVideo] = useState(false);
  const [showChangeVideo, setShowChangeVideo] = useState(false);
  const [showChangeEditVideo, setShowChangeEditVideo] = useState(false);
  const [showDownloadVideo, setShowDownloadVideo] = useState(false);

  const [menuData, setMenuData] = useState<any>([]);
  const [shopId, setShopId] = useState(undefined);
  const statusMenuData = [0, 1, 2, 3, 4, 5, 6, 7];
  const [selectMenuItem, setSelectMenuItem] = useState<number>(7);
  const [RangePickerString, setRangePickerString] = useState<any>([null, null]);
  const [start_time, setStart_time] = useState<any>(null);
  const [end_time, setEnd_time] = useState<any>(null);
  const [orderCode, setOrderCode] = useState("");
  const [order_id, setOrder_id] = useState("");
  const [profit_type, setProfit_type] = useState(undefined);
  const [profitsharing_status, setProfitsharing_status] = useState(undefined);
  const [nativeProfitsharing_status, setNativeProfitsharing_status] = useState(undefined);
  const [staticValue, setStaticValue] = useState({
    pay_amount: 0,
    profit_amount: 0,
    profit_surplus: 0,
    profitrecord_amount: 0,
    profitsharing_amount: 0,
    profitsharing_finished_amount: 0,
    profitsharing_waited_amount: 0,
    refund_amount: 0,
  });
  const StatusHandleMenuClick = (e: any) => {
    const num: number = parseInt(e.key);
    setSelectMenuItem(num);
  };

  const StatusMenu = (
    <Menu onClick={StatusHandleMenuClick}>
      {statusMenuData.map((item: any, index: any) => {
        return (
          <Menu.Item key={`${item}`}>{OrderManagerStatus[item]}</Menu.Item>
        );
      })}
    </Menu>
  );

  const onCancelReserve = (id: string) => {
    const dict = { order_id: id };
    CancelReserve(dict)
      .then((res) => {
        if (res.code === 200) {
          toastSuccessMessage("订单已取消");
          onFinish(currentPage, pageSize);
        }
      })
      .catch((err) => {});
  };
  const handleAmount = (profitsharings: any) => {
    let amount = "¥0";
    let number = 0;
    if(profitsharings.length>0){
      for (let index = 0; index < profitsharings.length; index++) {
        const profitsharingItem = profitsharings[index];
        if(profitsharingItem.receivers){
          for (let j = 0; j < profitsharingItem.receivers.length; j++) {
            const receiver = profitsharingItem.receivers[j];
            if (receiver.wechat_order && receiver.wechat_order.result === "SUCCESS") {
              if (receiver.wechat_order.amount) {
                number += receiver.wechat_order.amount;
              }
            }
          }
        }
      }
    }
    if (number > 0) {
      amount = "¥" + number/100;
    }

    return amount;
  };

  const statisticsFetch = async (dateType: number) => {
    let current = moment().format("YYYY-MM");
    if (dateType === 1) {
      const today = moment();
      const lastMonth = today.subtract(1, "months");
      current = lastMonth.format("YYYY-MM");
    }
    await statistics({ date: current })
      .then((res) => {
        if (res.code === 200) {
            setStaticValue(res.data)
        }
      })
      .catch((err) => {});
  };

  const onFinish = async (
    page_num: number,
    page_size: number,
    clear?: boolean
  ) => {
    let dict: any = { page_num, page_size };

    if (!clear) {
      if (shopId) {
        dict.shop_id = shopId;
      }
      if (selectMenuItem !== 7) {
        dict.status = selectMenuItem;
      }
      if (start_time) {
        dict.start_date = start_time;
      }
      if (end_time) {
        dict.end_date = end_time;
      }
      if (orderCode) {
        dict.order_code = orderCode;
      }
      if (profit_type) {
        dict.profit_type = profit_type;
      }
      if (profitsharing_status) {
        dict.pay_profitsharing_status = profitsharing_status;
      }
      if (nativeProfitsharing_status) {
        dict.nativepay_profitsharing_status = nativeProfitsharing_status;
      }
      
    }

    setCurrentPage(page_num);
    setPageSize(page_size);
    setIsLoading(true);
    await getOrderList(dict)
      .then((response) => {
        if (response.code === 200) {
          setTotal(response.data.totalItems);
          setDataSource(response.data.items);
        } else {
          toastErrorMessage(response.msg);
        }
        if (clear) {
          isClear();
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const getShopListData = () => {
    getShopList({ page_num: 1, page_size: 1000 })
      .then((response) => {
        if (response.code === 200) {
          if (response.data.items.length > 0) {
            let List: any = [];
            for (let index = 0; index < response.data.items.length; index++) {
              const item = response.data.items[index];
              List.push({
                id: item.id,
                label: item.name,
                key: index + "",
              });
            }
            setMenuData(List);
          }
        }
      })
      .catch((err) => {});
  };
  const OrderPayStatus = (status: string) => {
    let value = "-";
    switch (status) {
      case "WAIT":
        value = "待分账";
        break;
      case "PROCESSING":
        value = "分账中";
        break;
      case "FINISHED":
        value = "分账完成";
        break;
      case "FAILED":
        value = "分账失败";
        break;
      case "REFUND":
        value = "已退款";
        break;
      default:
        break;
    }
    return value;
  };

  const isClear = () => {
    setSelectMenuItem(7);
    setOrderCode("");
    setShopId(undefined);
    setStart_time(null);
    setEnd_time(null);
    setProfit_type(undefined);
    setProfitsharing_status(undefined);
    setNativeProfitsharing_status(undefined)
    setRangePickerString([null, null]);
  };

  useEffect(() => {
    statisticsFetch(2);
    getShopListData();
    onFinish(1, 10);
  }, []);

  const handleShowTotal = (total: number, range: number[]) => {
    const totalPage = Math.ceil(total / pageSize);
    return (
      <div style={{ flex: 1, display: "flex", color: "#999999" }}>
        {`共${total}条记录，第${currentPage}/${totalPage}页`}
      </div>
    );
  };

  return (
    <div style={{ width: "100%" }}>
      <OrderMenu
      valueData ={staticValue}
        permission={useInfo.permission}
        onDateChange={(dateType: number) => {
          statisticsFetch(dateType)
        }}
      />
      <div className="ProductionTableStyle">
        <div
          style={{
            paddingTop: 10,
            flex: 1,
            flexDirection: "row",
            display: "flex",
            backgroundColor: "#FFF",
          }}
        >
          <div style={{ width: 20, height: 1 }} />
          <div style={{ fontSize: 14, marginTop: 18 }}>门店：</div>
          <Select
            style={{ width: 120, marginTop: 13 }}
            onChange={(value) => {
              setShopId(value);
            }}
            value={shopId}
            placeholder="全部"
            options={menuData.map((e: any) => ({
              value: e.id,
              label: e.label,
            }))}
          />

          <div style={{ fontSize: 14, marginTop: 18, marginLeft: 20 }}>
            订单类型：
          </div>
          <Select
            style={{ width: 100, marginTop: 13 }}
            onChange={(value) => {
              setProfit_type(value);
            }}
            value={profit_type}
            placeholder="全部"
            options={[
              {
                value: "Normal",
                label: "普通订单",
              },
              {
                value: "Sharing",
                label: "分账订单",
              },
            ]}
          />
          <div style={{ fontSize: 14, marginTop: 18, marginLeft: 20 }}>
            订单状态：
          </div>
          <Dropdown overlay={StatusMenu}>
            <Button
              style={{
                width: 90,
                marginTop: 12,
                flexDirection: "row",
                display: "flex",
              }}
            >
              {OrderManagerStatus[selectMenuItem]}
              <div style={{ flex: 1, display: "flex" }} />
              <img
                style={{ marginRight: -8, width: 24, height: 24, marginTop: 0 }}
                src={require("../../../assets/icon_down.png")}
                alt=""
              />
            </Button>
          </Dropdown>
          <div style={{ width: 20 }}></div>
          <div
            style={{
              fontSize: 14,
              marginTop: 18,
              marginLeft: 0,
              marginRight: 0,
            }}
          >
            日期：
          </div>
          <RangePicker
            style={{ height: 32, marginTop: 12 }}
            value={RangePickerString}
            onChange={(e) => {
              if (e) {
                setStart_time(moment(e[0]).format("YYYY-MM-DD"));
                setEnd_time(moment(e[1]).format("YYYY-MM-DD"));
                setRangePickerString([moment(e[0]), moment(e[1])]);
              } else {
                setRangePickerString([null, null]);
                setStart_time(null);
                setEnd_time(null);
              }
            }}
          />
          <div style={{ fontSize: 14, marginTop: 18, marginLeft: 20 }}>
            门票分账状态：
          </div>
          <Select
            style={{ width: 100, marginTop: 13 }}
            value={profitsharing_status}
            onChange={(value) => {
              setProfitsharing_status(value);
            }}
            placeholder="全部"
            options={[
              {
                value: "WAIT",
                label: "待分账",
              },
              {
                value: "PROCESSING",
                label: "分账中",
              },
              {
                value: "FINISHED",
                label: "分账完成",
              },
              {
                value: "FAILED",
                label: "分账失败",
              },
              {
                value: "REFUND",
                label: "已退款",
              },
            ]}
          />
            <div style={{ fontSize: 14, marginTop: 18, marginLeft: 20 }}>
            增值服务分账状态：
          </div>
          <Select
            style={{ width: 100, marginTop: 13 }}
            value={nativeProfitsharing_status}
            onChange={(value) => {
              setNativeProfitsharing_status(value);
            }}
            placeholder="全部"
            options={[
              {
                value: "WAIT",
                label: "待分账",
              },
              {
                value: "PROCESSING",
                label: "分账中",
              },
              {
                value: "FINISHED",
                label: "分账完成",
              },
              {
                value: "FAILED",
                label: "分账失败",
              },
              {
                value: "REFUND",
                label: "已退款",
              },
            ]}
          />
          <Input
            title=""
            style={{
              marginLeft: -60,
              height: 32,
              width: 150,
              marginRight: 20,
              marginTop: 8,
            }}
            value={orderCode}
            placeholder="请输入订单号"
            onChange={(e) => {
              setOrderCode(e);
            }}
          />
          <div
            className="button"
            style={{ backgroundColor: "#FB186A", marginTop: 12 }}
            onClick={(e) => {
              onFinish(1, pageSize);
            }}
          >
            <div className="buttonTitle" style={{ color: "#FFF" }}>
              查询
            </div>
          </div>
          <div
            className="button"
            style={{
              borderStyle: "solid",
              borderWidth: 1,
              borderColor: "#FB186A",
              marginLeft: 20,
              marginTop: 12,
              marginRight: 10,
            }}
            onClick={() => {
              onFinish(1, pageSize, true);
            }}
          >
            <div className="buttonTitle">重置</div>
          </div>
        </div>
      </div>

      <div style={{ backgroundColor: "#FFF" }}>
        <div style={{ padding: 20 }}>
          <Table
            className={"commonTable"}
            // columns={columns}
            dataSource={dataSource}
            loading={isLoading}
            scroll={{ x: 1000 }}
            pagination={{
              position: ["bottomRight"],
              defaultCurrent: 1,
              defaultPageSize: 10,
              current: currentPage,
              pageSize: pageSize,
              showSizeChanger: true,
              total: total,
              showQuickJumper: true,
              onChange: onFinish,
              showTotal: handleShowTotal,
            }}
          >
            <Column
              title="订单号"
              dataIndex="order_code"
              key="order_code"
              align="center"
              width={120}
            />
            <Column
              title="订单类型"
              dataIndex="profit_type"
              key="profit_type"
              align="center"
              width={100}
              render={(profit_type: string) => (
                <div>{profit_type === "Sharing" ? "分账订单" : "普通订单"}</div>
              )}
            />
              <Column
                title="下单时间"
                dataIndex="create_time"
                key="create_time"
                align="center"
                width={150}
                render={(create_time: string) => (
                  <div>{moment(create_time).format("YYYY-MM-DD HH:mm:ss")}</div>
                )}
              />
            <Column
              title="订单状态"
              dataIndex="status"
              key="status"
              align="center"
              width={100}
              render={(status: number) => (
                <div
                  style={{ color: OrderStatusColor[status] }}
                >{`●${OrderStatus[status]}`}</div>
              )}
            />
            {useInfo.permission === 1 && (
              <ColumnGroup title="订单实付金额">
                <Column
                  title="门票"
                  dataIndex="pay"
                  key="pay"
                  align="center"
                  width={100}
                  render={(pay: any) => (
                    <div>
                      {pay && pay.payer_total
                        ? `¥${pay.payer_total / 100}`
                        : "-"}
                    </div>
                  )}
                />
                <Column
                  title="增值服务"
                  dataIndex="nativePay"
                  key="nativePay"
                  align="center"
                  width={100}
                  render={(nativePay: any) => (
                    <div>
                      {nativePay && nativePay.payer_total
                        ? `¥${nativePay.payer_total/100}`
                        : "-"}
                    </div>
                  )}
                />
                <Column
                  title="订单总额"
                  dataIndex="order_amount"
                  key="order_amount"
                  align="center"
                  width={100}
                  render={(order_amount: number,record:any) => {
                   
                    let number = 0 
                     if(record.pay&&  record.pay.total){
                      number= record.pay.total
                    }
                    if(record.nativePay){
                      number+=record.nativePay.payer_total
                    }
                    if(number >0){
                      number = number
                      /100
                    }
                    
                     return <div>{`¥${number}`}</div>
                   
                  }}
                />
              </ColumnGroup>
            )}
            {useInfo.permission === 1 && (
              <Column
                title="分账总额"
                dataIndex="profit_amount"
                key="profit_amount"
                align="center"
                width={100}
                render={(profit_amount: number) => (
                  <div>{`¥${profit_amount / 100}`}</div>
                )}
              />
            )}
            {useInfo.permission === 1 && (
              <Column
                title="系统记账总额"
                dataIndex="profitrecord_amount"
                key="profitrecord_amount"
                align="center"
                width={100}
                render={(profitrecord_amount: number) => (
                  <div>{`¥${profitrecord_amount / 100}`}</div>
                )}
              />
            )}
            {useInfo.permission === 1 && (
              <Column
                title="微信分账金额"
                dataIndex="profitsharing_amount"
                key="profitsharing_amount"
                align="center"
                width={100}
                render={(profitsharing_amount: number) => (
                  <div>{`¥${profitsharing_amount / 100}`}</div>
                )}
              />
            )}
            {useInfo.permission === 1 && (
              <Column
                title="订单结余"
                dataIndex="profit_surplus"
                key="profit_surplus"
                align="center"
                width={100}
                render={(profit_surplus: number) => (
                  <div>{`¥${profit_surplus / 100}`}</div>
                )}
              />
            )}
            {useInfo.permission === 1 && (
              <Column
                title="门票分账状态"
                dataIndex="profitsharings"
                key="profitsharings1"
                align="center"
                width={100}
                render={(profitsharings: any) => {
                  let typeStatus = ''
                  
                  for (let index = 0; index < profitsharings.length; index++) {
                    const element = profitsharings[index];
                    if(element.pay_type ==='PAY'){
                      typeStatus = element.status
                      break
                    }
                  }
                  const status = OrderPayStatus(typeStatus)
               return   (<div>
                {status}
              </div>)
                }
                }
              />
            )}
            {useInfo.permission === 1 && (
              <Column
                title="增值服务分账状态"
                dataIndex="profitsharings"
                key="profitsharings2"
                align="center"
                width={100}
                render={(profitsharings: any) => 
                {
                
                    let typeStatus = ''
                    for (let index = 0; index < profitsharings.length; index++) {
                      const element = profitsharings[index];
                      if(element.pay_type ==='NATIVEPAY'){
                        typeStatus = element.status
                        break
                      }
                    }
                    const status = OrderPayStatus(typeStatus)
                 return   (<div>
                  {status}
                </div>)
                  }
                
               }
              />
            )}
            {useInfo.permission === 1 && (
              <Column
                title="操作"
                dataIndex="status"
                key="status"
                align="center"
                width={150}
                render={(status: number, data: any) => {
                  return (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        alignItems: "center",
                      }}
                    >
                      <div style={{ display: "flex", flex: 1 }} />
                      <div
                        style={{
                          color: "#1890FF ",
                          cursor: "pointer",
                          marginLeft: 10,
                          marginRight: 10,
                        }}
                        onClick={() => {
                          navigate("/orderDetail", {
                            state: { id: data.order_id },
                          });
                        }}
                      >
                        详情
                      </div>

                      {status === 4 && (
                        <div
                          style={{
                            color: "#1890FF ",
                            cursor: "pointer",
                            marginLeft: 10,
                            marginRight: 10,
                          }}
                          onClick={() => {
                            setOrder_id(data.order_id);
                            // setShowChangeVideo(true)
                            setShowChangeEditVideo(true);
                          }}
                        >
                          更换视频
                        </div>
                      )}
                      {status === 3 && (
                        <div
                          style={{
                            color: "#1890FF ",
                            cursor: "pointer",
                            marginLeft: 10,
                            marginRight: 10,
                          }}
                          onClick={() => {
                            setOrder_id(data.order_id);
                            setShowUploadEditVideo(true);
                            // setShowUploadVideo(true)
                          }}
                        >
                          上传视频
                        </div>
                      )}
                      {status === 4 && (
                        <div
                          style={{
                            color: "#1890FF ",
                            cursor: "pointer",
                            marginLeft: 10,
                            marginRight: 10,
                          }}
                          onClick={() => {
                            setOrder_id(data.order_id);
                            setShowDownloadVideo(true);
                          }}
                        >
                          下载视频
                        </div>
                      )}
                      {status === 0 && (
                        <div
                          style={{
                            color: "#1890FF ",
                            marginLeft: 10,
                            marginRight: 10,
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            confirm({
                              title: "提示",
                              content: "是否取消该订单?",
                              onOk() {
                                onCancelReserve(data.order_id);
                              },
                              onCancel() {},
                            });
                          }}
                        >
                          取消
                        </div>
                      )}
                      {(status === 1 ||
                        status === 2 ||
                        status === 3 ||
                        status === 4) && (
                        <div
                          style={{
                            color: "#1890FF ",
                            marginLeft: 10,
                            marginRight: 10,
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            navigate("/refund", {
                              state: { id: data.order_id },
                            });
                          }}
                        >
                          退款
                        </div>
                      )}
                      <div style={{ display: "flex", flex: 1 }} />
                    </div>
                  );
                }}
              />
            )}
            {useInfo.permission !== 1 && (
              <Column
                title="订单实付金额"
                dataIndex="payment_amount"
                key="payment_amount"
                align="center"
                width={100}
                render={(payment_amount: number) => (
                  <div>{payment_amount ? `¥${payment_amount}` : "-"}</div>
                )}
              />
            )}
            {useInfo.permission !== 1 && (
              <Column
                title="我的记账金额"
                dataIndex="profitrecords"
                key="profitrecords22"
                align="center"
                width={100}
                render={(profitrecords: any) => {
                  let amount= 0
                  for (let index = 0; index < profitrecords.length; index++) {
                    const element = profitrecords[index];
                    const receivers = element.receivers
                    if(receivers &&receivers.length){
                      for (let j = 0; j < receivers.length; j++) {
                        const item = receivers[j];
                        if(item.merchant_id === useInfo.id){
                          amount+=item.amount
                        }
                      }
                    }
                  }
                  if(amount>0){
                    amount = amount/100
                  }
                  return <div>{'¥'+amount}</div>
                } }
              />
            )}
            {useInfo.permission !== 1 && (
              <ColumnGroup title="微信分账">
                <Column
                  title="门票"
                  dataIndex="pay"
                  key="pay2"
                  align="center"
                  width={100}
                  render={(pay: any) => (
                    <div>
                      {pay && pay.payer_total ? `¥${pay.payer_total/100}` : "-"}
                    </div>
                  )}
                />
                <Column
                  title="增值服务"
                  dataIndex="nativePay"
                  key="nativePay2"
                  align="center"
                  width={100}
                  render={(nativePay: any) => (
                    <div>
                      {nativePay && nativePay.payer_total
                        ? `¥${nativePay.payer_total/100}`
                        : "-"}
                    </div>
                  )}
                />
                <Column
                  title="应分金额"
                  dataIndex="profitsharing_amount"
                  key="profitsharing_amount2"
                  align="center"
                  width={100}
                  render={(profitsharing_amount: number) => (
                    <div>
                      {profitsharing_amount ? `¥${profitsharing_amount/100}` : "-"}
                    </div>
                  )}
                />
                <Column
                  title="已分金额"
                  dataIndex="profitsharings"
                  key="profitsharings6"
                  align="center"
                  width={100}
                  render={(profitsharings: any, record: any) => (
                    <div>
                      {record.status === 5
                        ? "¥0"
                        : handleAmount(profitsharings)}
                    </div>
                  )}
                />
              </ColumnGroup>
            )}
            {useInfo.permission !== 1 && (
              <Column
                title="门票分账状态"
                dataIndex="profitsharings"
                key="profitsharings3"
                align="center"
                width={100}
                render={(profitsharings: any) =>
                  {
                
                    let typeStatus = ''
                    for (let index = 0; index < profitsharings.length; index++) {
                      const element = profitsharings[index];
                      if(element.pay_type ==='PAY'){
                        typeStatus = element.status
                        break
                      }
                    }
                    const status = OrderPayStatus(typeStatus)
                 return   (<div>
                  {status}
                </div>)
                  }
                  
            }
              />
            )}
            {useInfo.permission !== 1 && (
              <Column
                title="增值服务分账状态"
                dataIndex="profitsharings"
                key="profitsharing4"
                align="center"
                width={100}
                render={(profitsharings: any) => 
                  
                  {
                
                    let typeStatus = ''
                    for (let index = 0; index < profitsharings.length; index++) {
                      const element = profitsharings[index];
                      if(element.pay_type ==='NATIVEPAY'){
                        typeStatus = element.status
                        break
                      }
                    }
                    const status = OrderPayStatus(typeStatus)
                 return   (<div>
                  {status}
                </div>)
                  }
                  }
              />
            )}
          </Table>
        </div>
      </div>
      {showUploadVideo && (
        <UploadVideo
          closeClick={() => {
            setShowUploadVideo(false);
          }}
          saveClick={() => {
            setShowUploadVideo(false);
            onFinish(currentPage, pageSize);
          }}
          order_id={order_id}
        />
      )}
      {showUploadEditVideo && (
        <UploadEditVideo
          closeClick={() => {
            setShowUploadEditVideo(false);
          }}
          saveClick={() => {
            setShowUploadEditVideo(false);
            onFinish(currentPage, pageSize);
          }}
          order_id={order_id}
        />
      )}

      {showChangeVideo && (
        <ChangeVideo
          closeClick={() => {
            setShowChangeVideo(false);
          }}
          saveClick={() => {
            setShowChangeVideo(false);
            onFinish(currentPage, pageSize);
          }}
          order_id={order_id}
        />
      )}
      {showChangeEditVideo && (
        <ChangeEditVideo
          closeClick={() => {
            setShowChangeEditVideo(false);
          }}
          saveClick={() => {
            setShowChangeEditVideo(false);
            onFinish(currentPage, pageSize);
          }}
          order_id={order_id}
        />
      )}
      {showDownloadVideo && (
        <DownloadVideo
          closeClick={() => {
            setShowDownloadVideo(false);
          }}
          order_id={order_id}
        />
      )}
    </div>
  );
};
export default OrderManager;
