import React, { useState, useContext } from 'react';
import { Layout } from 'antd';
import HeaderBar from "./headerBar";
import SiderBar from './siderBar';
import BreadcrumbBar from "./breadcrumbBar";
import Router from '../../router';
import './index.less';
import { useNavigate, Route, Routes } from "react-router-dom";
import { UserContext } from "../../contexts/userContext";
import OrderManager from '../../pages/Order/OrderManager';

const LayoutContainer = () => {
    const { Header, Sider, Content } = Layout;
    const { useInfo } = useContext(UserContext);
    const navigate = useNavigate();
    return (
        <Layout className={'mes-layout'}>
            <Sider className={'mes-sider'}  width={260} collapsible
                trigger={null}>
                <div className="logo" onClick={() => {
                    if(useInfo.permission === 1){
                        navigate('/storgeManager')
                    } 
                }}>
                    <img src={require('../../assets/sider-logo.jpg')}
                        alt="" />
                </div>
                <SiderBar />
            </Sider>
            <Layout>
                <Header style={{height:50}}>
                    <HeaderBar />
                </Header>
                <BreadcrumbBar />
                <Content className={'mes-content'}>
                 {   useInfo.permission===1? <Router />: <Routes><Route path="/" element={<OrderManager/>}/> </Routes> }
                </Content>
            </Layout>
        </Layout>
    )
}

export default React.memo(LayoutContainer);
