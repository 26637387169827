import React, { useContext, useEffect, useState } from "react";
import { Table, Switch, Modal, Input } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import '../../globalLess/global.css'
import '../../components/Layout/index.less';
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../contexts/userContext";
import { toastErrorMessage, toastMessage, } from "../../utils";
import {deleteShop,updateInfo, getShopList,editModelShop } from "../../service";
const { confirm } = Modal;

type ModelListType = {
    id?: string,
    name?: string,
    adress?: string,
    start_time?: string,
    end_time?: string,
    iphone?: number,
    is_use?: number,
    ticket_name?: string,
    take_duration?: number,
    cut_number?: number,
    ticket_price?: string,
}

type menuSelect = {
    status?: number,//0 未选择  1选择   2不可点
    label?: String
}

const defaultMenu = [
    {
        status: 2,
        label: '序号',
    },
    {
        status: 1,
        label: '门店名称',
    }, {
        status: 1,
        label: '门店地址',
    },
    {
        status: 1,
        label: '营业时间',
    },
    {
        status: 0,
        label: '是否启用',
    },
    {
        status: 0,
        label: '客服电话',
    },
    {
        status: 0,
        label: '门票名称',
    },
    {
        status: 0,
        label: '拍摄时长',
    },{
        status: 0,
        label: '拍摄模板数量',
    }, {
        status: 0,
        label: '剪辑条数',
    },
    {
        status: 0,
        label: '票价',
    },
    {
        status: 2,
        label: '操作（必选）',
    },
]
const compareMenu = [
    {
        status: 2,
        label: '序号',
    },
    {
        status: 0,
        label: '门店名称',
    }, {
        status: 0,
        label: '门店地址',
    },
    {
        status: 0,
        label: '营业时间',
    },
    {
        status: 0,
        label: '是否启用',
    },
    {
        status: 0,
        label: '客服电话',
    },
    {
        status: 0,
        label: '门票名称',
    },
    {
        status: 0,
        label: '拍摄时长',
    }, {
        status: 0,
        label: '拍摄模板数量',
    },{
        status: 0,
        label: '剪辑条数',
    },
    {
        status: 0,
        label: '票价',
    },
    {
        status: 2,
        label: '操作（必选）',
    },
]
const StorgeManager = (): React.ReactElement => {
    let [columns, setColumns] = useState<ColumnsType<ModelListType>>(
        [
            {
                title: '序号',
                dataIndex: 'key',
                width: 80,
        align:'center',
                ellipsis: true,
                render: (key: number) => <div>{key + 1}</div>
            },
            {
                title: '门店名称',
                dataIndex: 'name',
                width: 80,
                align:'center',
                ellipsis: true,
            },
            {
                title: '门店地址',
                dataIndex: 'adress',
                width: 100,
                align:'center',
                ellipsis: true,
            },
            {
                title: '营业时间',
                dataIndex: 'id',
                width: 100,
                align:'center',
                ellipsis: true,
                render: (id: string, data: ModelListType) => <div>111</div>
            },
        
        ]

    )

    const [menuData, setMenuData] = useState<menuSelect[]>(defaultMenu)
    const { useInfo, setUserInfo } = useContext(UserContext)
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [total, setTotal] = useState<number>(0);
    const [isLoading, setIsLoading] = useState(false);
    const [dataSource, setDataSource] = useState<ModelListType[]>([]);
    const [keyword, setKeyword] = useState('');
    const navigate: any = useNavigate();
    const [settingList, setSettingList] = useState(false);
    const [selectAll, setSelectAll] = useState(false);

    const settingColumns = (data: string[]) => {
        const dataSource:any = JSON.parse(JSON.stringify(compareMenu))
        const newArray = dataSource.map((item:any)=> {
            const dataItem = data.find(elem => elem === item.label)
            if (dataItem) {
                item.status = 1
            }
            return item
        })
        
        setMenuData(newArray)

        let newlist: any =   [
            {
                title: '序号',
                dataIndex: 'key',
                width: 80,
                align:'center',
                ellipsis: true,
                render: (key: number) => <div>{key + 1}</div>
            },
        ]

        const item11 = data.find(item => item === '门店名称')
        if (item11) {
            newlist.push({
                title: '门店名称',
                dataIndex: 'name',
                width: 80,
                align:'center',
                ellipsis: true,
            })
        }
        const item12 = data.find(item => item === '门店地址')
        if (item12) {
            newlist.push(  {
                title: '门店地址',
                dataIndex: 'adress',
                width: 100,
                align:'center',
                ellipsis: true,
            },)
        }
        const item13 = data.find(item => item === '营业时间')
        if (item13) {
            newlist.push( {
                title: '营业时间',
                dataIndex: 'start_time',
                width: 100,
                align:'center',
                ellipsis: true,
                render: (start_time: string, data: ModelListType) => <div>{`${start_time}-${data.end_time}`}</div>
            },)
        }

        const item14 = data.find(item => item === '是否启用')
        if (item14) {
            newlist.push(
                {
                    title: '是否启用',
                    dataIndex: 'is_use',
                    width: 80,
                    align:'center',
                    ellipsis: true,
                    render: (is_use: number, data: ModelListType) => <Switch  checked={is_use === 1 ? true : false} onChange={() => {
                        let enableNum = is_use
                        if (is_use === 1) {
                            confirm({
                                title: '提示',
                                content: '是否禁用该门店?',
                                onOk() {
                                    enableNum = 0
                                   clickModelEnable(data.id, enableNum)
                                },
                                onCancel() {
    
                                },
                            });
                        } else {
                            confirm({
                                title: '提示',
                                content: '是否启用该门店?',
                                onOk() {
                                    enableNum = 1
                                    clickModelEnable(data.id, enableNum)
                                },
                                onCancel() {
    
                                },
                            });
                        }
                    }} />
                })
        }


        const item = data.find(item => item === '客服电话')
        if (item) {
            newlist.push({
                title: '客服电话',
                dataIndex: 'iphone',
                width: 100,
                align:'center',
                ellipsis: true,
            })
        }
   
        const item2 = data.find(item => item === '门票名称')
        if (item2) {
            newlist.push({
                title: '门票名称',
                dataIndex: 'ticket_name',
                width: 100,
                align:'center',
                ellipsis: true,
            },)
        }
        const item3 = data.find(item => item === '拍摄时长')
        if (item3) {
            newlist.push({
                title: '拍摄时长',
                dataIndex: 'take_duration',
                width: 80,
                align:'center',
                ellipsis: true,
                render: (time: number) => <div>{time/60+'分钟'}</div>
            },)
        }

        const item41 = data.find(item => item === '拍摄模板数量')
        if (item41) {
            newlist.push({
                title: '拍摄模板数量',
                dataIndex: 'template_number',
                width: 80,
                align:'center',
                ellipsis: true,
            },)
        }

        const item4 = data.find(item => item === '剪辑条数')
        if (item4) {
            newlist.push({
                title: '剪辑条数',
                dataIndex: 'cut_number',
                width: 80,
                align:'center',
                ellipsis: true,
            },)
        }
        const item5 = data.find(item => item === '票价')
        if (item5) {
            newlist.push({
                title: '票价',
                dataIndex: 'ticket_price',
                width: 80,
                align:'center',
                ellipsis: true,
            },)
        }

        newlist.push({
            title: '操作',
            width: 120,
            align:'center',
            dataIndex: 'id',
            render: (id: string, data: ModelListType) =>
                <div style={{ display: 'flex', flexDirection: 'row',alignItems:'center' }}>
                   <div style={{display:'flex',flex:1}}/>
                    <div
                        style={{
                            color: '#1890FF',
                            cursor: "pointer",
                            marginLeft:10,
                            marginRight: 10
                        }}
                        onClick={() => {
                            navigate('/queryStorge',{ state: { id } })
                        }}
                    >
                        详情
                    </div>
                    <div
                        style={{
                            color: '#1890FF',
                            cursor: "pointer",
                            marginLeft:10,
                            marginRight: 10
                        }}
                        onClick={() => {
                            navigate('/editStorge',{ state: { id } })
                        }}
                    >
                        编辑
                    </div>
                    <div
                        style={{
                            color: '#FB186A',
                            cursor: "pointer",
                            marginLeft:10,
                            marginRight: 10
                        }}
                        onClick={() => {
                            confirm({
                                title: '提示',
                                content: '删除后不可恢复，是否确认删除？',
                                onOk() {
                                    deleteModel(id)
                                },
                                onCancel() {

                                },
                            });
                        }}
                    >
                        删除
                    </div>
                    <div style={{display:'flex',flex:1}}/>
                </div>
        })

        setColumns(newlist)


    }

    const clickModelEnable = (id?: string, enable?: number) => {
        let dict: any = { id, is_use: enable }
        editModelShop(dict).then(response => {
            if (response.code === 200) {
                onFinish(currentPage, pageSize,true)
            }
        }).catch(err => {
        })
    }
    const deleteModel = (id?: string) => {
        let dict: any = { id }
        deleteShop(dict).then(response => {
            if (response.code === 200) {
                onFinish(currentPage, pageSize,true)
            }
        }).catch(err => {
        })
    }

    const onFinish = (page_num: number, page_size: number,isClear?:boolean) => {
        let dict: any = { page_num, page_size }
        
        if (!isClear && keyword) {
            dict.keyword = keyword
        }
        setCurrentPage(page_num);
        setPageSize(page_size)
        setIsLoading(true)
        getShopList(dict).then(response => {
            if (response.code === 200) {
                setTotal(response.data.totalItems)
                let dataCopy = []
                for (let index = 0; index < response.data.items.length; index++) {
                    let item = response.data.items[index]
                    item.key = index + (page_num - 1) * page_size
                    dataCopy.push(item)
                }
                setDataSource(dataCopy)
            } else {
                toastErrorMessage(response.msg)
            }
            if(isClear){
                setKeyword('') 
            }
            setIsLoading(false)
        }).catch(err => {
            setIsLoading(false)
        })

    }

    useEffect(() => {
        console.log('useInfo.shop_preference',useInfo.shop_preference);
        if (useInfo.shop_preference) {
            settingColumns(JSON.parse(useInfo.shop_preference))
        }else{
            settingColumns([])
        }
        onFinish(1, 10)
    }, [useInfo])

    const handleShowTotal = (total: number, range: number[]) => {
        const totalPage = Math.ceil(total / pageSize);
        return (

            <div style={{ flex: 1, display: "flex", color: '#999999', }}>
                {`共${total}条记录，第${currentPage}/${totalPage}页`}
            </div>


        )
    }

   const saveUserSetting = (data: string[])=>{
    updateInfo({shop_preference: JSON.stringify(data)}).then(response => {
            if (response.code === 200) {
                console.log('saveUserSetting',data);
                settingColumns(data)
            }
        }).catch(err => {
        })
   }
    return (
        <div style={{ height: '100%', width: '100%' }}>
            <div className="ProductionTableStyle">
                <div style={{ paddingTop:10,flex: 1, flexDirection: 'row', display: 'flex', backgroundColor: '#FFF' }}>
                    <div style={{ width: 6, height: 1 }} />
                    <Input style={{ width: 200, height: 32, marginRight: 20, marginLeft: 20, marginTop: 12 }} value={keyword} placeholder='请输入门店名称' onChange={(e) => {
                        setKeyword(e.target.value)
                    }}
                    />
                    <div className='button' style={{ backgroundColor: '#FB186A', marginTop: 12 }} onClick={(e) => {
                        onFinish(1, pageSize)
                    }}>
                        <div className='buttonTitle' style={{ color: '#FFF' }}>
                            查询
                        </div>
                    </div>
                    <div className='button' style={{ borderStyle: 'solid', borderWidth: 1, borderColor: '#FB186A', marginLeft: 20, marginTop: 12 }} onClick={() => {
              
                        onFinish(1, pageSize,true)
                    }}>
                        <div className='buttonTitle'>
                            重置
                        </div>
                    </div>
                    <div style={{ flex: 1 }} />
                    <div className='button' style={{ width: 96, backgroundColor: '#FB186A', marginLeft: 20, marginTop: 12, marginRight: 20 }} onClick={(e) => {
                        navigate('/addStorge')
                    }}>
                        <img
                            alt=''
                            style={{ width: 13, height: 13, marginRight: 5 }}
                            src={require('../../assets/icon_add.png')}
                        />
                        <div className='buttonTitle' style={{ color: '#FFF', fontSize: 14, paddingTop: 2, }}>
                            添加门店
                        </div>
                    </div>

                    <div className='button' style={{ width: 102, borderStyle: 'solid', borderWidth: 1, borderColor: '#D9D9D9', marginLeft: 0, marginTop: 12, marginRight: 24 }} onClick={() => {
                        setSettingList(true)
                    }}>
                        <div className='buttonTitle' style={{ color: '#333' }}>
                            自定义列表
                        </div>
                    </div>
                </div>
            </div>

            <div style={{ backgroundColor: '#FFF' }}>
                <div style={{ padding: 20 }}>
                    <Table
                        className={'commonTable'}
                        columns={columns}
                        dataSource={dataSource}
                        loading={isLoading}
                        scroll={{ x: menuData.length*100 }}
                        pagination={{
                            position: ['bottomRight'],
                            defaultCurrent: 1,
                            defaultPageSize: 10,
                            current: currentPage,
                            pageSize: pageSize,
                            showSizeChanger: true,
                            total: total,
                            showQuickJumper: true,
                            onChange: onFinish,
                            showTotal: handleShowTotal
                        }}
                    />

                </div>

            </div>

            <Modal
                width={400}
                style={{ marginTop: 20 }}
                title={
                    <div className="alert-title-bkg">
                        <div className="alert-title">
                            <div className="alert-title-icon" />
                            <div className="alert-title-text">自定义列表</div>
                        </div>
                        <div className="alert-line"></div>
                    </div>
                }
                open={settingList}
                onOk={() => {
                    setSettingList(false)
                }}
                onCancel={() => {
                    setSettingList(false)
                }}
                footer={null}
            >
                <div style={{ height: 620 }}>
                    <div className='inputStyle' style={{ marginTop: -24, backgroundColor: '#F5F6F8', height: 40, marginLeft: 16, marginRight: 16, cursor: 'pointer' }}
                        onClick={() => {
                            if (!selectAll) {
                                setMenuData([
                                    {
                                        status: 2,
                                        label: '序号',
                                    },
                                    {
                                        status: 1,
                                        label: '门店名称',
                                    }, {
                                        status: 1,
                                        label: '门店地址',
                                    },
                                    {
                                        status: 1,
                                        label: '营业时间',
                                    },
                                    {
                                        status: 1,
                                        label: '客服电话',
                                    }, {
                                        status: 1,
                                        label: '是否启用',
                                    },
                                    {
                                        status: 1,
                                        label: '门票名称',
                                    },

                                    {
                                        status: 1,
                                        label: '拍摄时长',
                                    }, {
                                        status: 1,
                                        label: '拍摄模板数量',
                                    }, {
                                        status: 1,
                                        label: '剪辑条数',
                                    },
                                    {
                                        status: 1,
                                        label: '票价',
                                    },
                                    {
                                        status: 2,
                                        label: '操作（必选）',
                                    },

                                ])
                            }
                            setSelectAll(!selectAll)
                        }}
                    >
                        <img style={{ marginLeft: 10, width: 20, height: 20 }} src={selectAll ? require('../../assets/icon_click.png') : require('../../assets/icon_unClick.png')} />
                        <div style={{ flex: 1, display: 'flex' }} />
                        <div style={{ marginLeft: 30, textAlign: 'center', width: 360, fontSize: 14 }}>全选</div>
                    </div>
                    {
                        menuData.map((item, index) => {
                            return <div className='inputStyle' key={'c'+index}  style={{ marginTop: 0, backgroundColor: '#FFF', height: 40, marginLeft: 16, marginRight: 16, cursor: 'pointer', borderBottomWidth: 0.5, borderBottomColor: '#F5F6F8', borderBottomStyle: 'solid' }}
                                onClick={() => {
                                    if (item.status !== 2) {
                                        if (item.status === 0) {
                                            const newlist = JSON.parse(JSON.stringify(menuData))    
                                            item = newlist[index]
                                            item.status = 1
                                            setMenuData(newlist)
                                        } else {
                                            const newlist = JSON.parse(JSON.stringify(menuData))    
                                            item = newlist[index]
                                            item.status = 0
                                            setMenuData(newlist)
                                        }
                                    }
                                }}
                            >
                                <img style={{ marginLeft: 10, width: 20, height: 20 }} src={item.status === 0 ? require('../../assets/icon_unClick.png') : item.status === 1 ? require('../../assets/icon_click.png') : require('../../assets/icon_noClick.png')} />
                                <div style={{ flex: 1, display: 'flex' }} />
                                <div style={{ marginLeft: 30, textAlign: 'center', width: 360, fontSize: 14 }}>{item.label}</div>
                            </div>
                        })
                    }
                    <div className='inputStyle' style={{ marginTop: 0, backgroundColor: '#FFF', height: 40, marginLeft: 16, marginRight: 16, cursor: 'pointer', }}
                        onClick={() => {
                            setSelectAll(false)
                            const newList = JSON.parse(JSON.stringify(defaultMenu))
                            setMenuData(newList)
                        }}
                    >
                        <img style={{ marginLeft: 10, width: 16, height: 16 }} src={require('../../assets/icon_resert.png')} />

                        <div style={{ marginLeft: 30, fontSize: 14 }}>恢复默认设置</div>
                    </div>

                    <div style={{ flexDirection: 'row', display: 'flex', marginTop: 25 }}>
                        <div className='btn' style={{ marginLeft: 100, backgroundColor: '#FFF', borderColor: '#D9D9D9', borderWidth: 0.5, borderStyle: 'solid' }} onClick={(e) => {
                            setSettingList(false)
                        }}>
                            <div className='buttonTitle' style={{
                                color: '#4E5969',
                            }}>
                                取消
                            </div>
                        </div>
                        <div className='btn' style={{ marginLeft: 10, }} onClick={(e) => {

                            let strList: any = []
                            for (let num = 0; num < menuData.length; num++) {
                                const itm = menuData[num]
                                if (itm.status === 1) {
                                    strList.push(itm.label)
                                }
                            }
                            if(strList.length===0){
                                toastMessage('请至少再选择一项设置')
                                return
                            }
                            console.log('setUserInfo',strList);
                            
                            setUserInfo({
                                id: useInfo.id,
                                account: useInfo.account,
                                name: useInfo.name,
                                phone: useInfo.phone,
                                duty: useInfo.duty,
                                password: useInfo.password,
                                permission: useInfo.permission,
                                is_delete: useInfo.is_delete,
                                shop_preference: JSON.stringify(strList)
                            })
                            setSettingList(false)
                            saveUserSetting(strList)
                        }}>
                            <div className='buttonTitle' style={{
                                color: '#FFF',
                            }}>
                                保存
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    )
}
export default StorgeManager
