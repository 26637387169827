import React, {useContext, useEffect, useState} from 'react';
import Login from "./pages/Login";
import LayoutContainer from "./components/Layout";
import {Provider, UserContext, UserInfo} from "./contexts/userContext";
import {getCurrentUser} from "./service";
import { showError} from "./utils";

const App = () => {
    const [useInfo, setUserInfo] = useState<UserInfo>({});
    return (
        <Provider value={{useInfo, setUserInfo}}>
            <JudgeLogin/>
            </Provider>
    )
}

const JudgeLogin = React.memo(() => {
    const {setUserInfo} = useContext(UserContext);
    const isLogin = window.sessionStorage.getItem('isLogin')
    const getUserInfo = () => {
        getCurrentUser().then((res) => {
            if (res.code === 200) {
                // window.sessionStorage.setItem('userInfo',JSON.stringify(res.data))
                window.sessionStorage.setItem('isLogin', 'true')
                setUserInfo({
                    id: res.data.id,
                    account: res.data.account,
                    name: res.data.name,
                    phone: res.data.phone,
                    duty: res.data.duty,
                    password: res.data.password,
                    permission: res.data.permission,
                    is_delete: res.data.is_delete,
                    shop_preference: res.data.shop_preference,
                })
            } else {
                showError(res.message)
            }
        }).catch((err)=>{
        })
    }
    if (isLogin === "true") {
       return  <LoggedIn getUserInfo={getUserInfo}/>
    }
    return <Login getUserInfo={getUserInfo}/>
 
})

interface LoggedInProps {
    getUserInfo: () => void
}

const LoggedIn = React.memo((props: LoggedInProps) => {
    const {getUserInfo} = props
    useEffect(() => {
        getUserInfo()
    }, [])
    return <LayoutContainer/>
})

export default App;

